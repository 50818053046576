import {MarketCodeType} from '../../features/markets/types'

export const getMpProductUrl = (sku: string, mp_code: MarketCodeType) => {
  let url = ''
  if (mp_code == 'wb') {
    url = `https://www.wildberries.ru/catalog/${sku}/detail.aspx?targetUrl=XS`
  } else if (mp_code == 'ym') {
    url = `https://market.yandex.ru/product/${sku}`
  } else if (mp_code == 'oz') {
    url = `https://www.ozon.ru/product/${sku}/`
  } else if (mp_code == 'al') {
    url = `https://aliexpress.ru/item/${sku}.html`
  }

  return url
}