import type {PayloadAction}   from '@reduxjs/toolkit'
import {createSlice}          from '@reduxjs/toolkit'
import type {SnackbarMessage} from 'notistack'
import type {RootState}       from '../../../app/store/types'

type NotificationState = {
  error: SnackbarMessage | null;
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    error: null
  } as NotificationState,
  reducers: {
    setErrorNotification: (state, action: PayloadAction<SnackbarMessage>) => {
      state.error = action.payload
    },
    removeErrorNotification: (state) => {
      state.error = null
    },
  },
})

export const {
  setErrorNotification,
  removeErrorNotification
} = notificationSlice.actions

export default notificationSlice.reducer

export const selectErrorNotification = (state: RootState) => state.notification.error
