import Toolbar                   from '@mui/material/Toolbar'
import Typography                from '@mui/material/Typography'
import Box                       from '@mui/material/Box'
import Alert                     from '@mui/material/Alert'
import {
  useAppDispatch,
  useAppSelector
}                                from '../../../app/store/hooks'
import {
  selectSupplierId,
  setSupplierId
}                                from '../store/reviewsSlice'
import SelectSuppliers           from '../../../app/components/suppliers/SelectSuppliers'
import IconLegalEntity           from '../../../app/components/icons/IconLegalEntity'
import ReviewsList               from '../components/ReviewsList'
import {PERMISSION_REVIEWS_VIEW} from '../../../app/contants/permissions'
import AllowGuard                from '../../auth/guards/AllowGuard'

const Reviews = () => {

  const supplierId = useAppSelector(selectSupplierId)
  const dispatch = useAppDispatch()

  return (
    <AllowGuard permissions={[PERMISSION_REVIEWS_VIEW]}>
      <Box>
        <Toolbar disableGutters>
          <Typography color="text.secondary" variant="h4" noWrap>
            Неотвеченные отзывы
          </Typography>
          <Box flexGrow={1} sx={{ px: 2 }}/>
          <SelectSuppliers
            supplierId={supplierId}
            setSupplierId={(newSupplierId) => dispatch(setSupplierId(newSupplierId))}
          />
        </Toolbar>
        <Box paddingTop={4}>
          {supplierId ? (
            <ReviewsList/>
          ) : (
            <Alert
              variant="filled"
              icon={<IconLegalEntity sx={{ fontSize: 56 }}/>}
              sx={{ backgroundColor: 'primary.main' }}
            >
              <Typography variant="h6" fontWeight={900}>Выберите «Юридическое лицо»</Typography>
              Чтобы отобразить список неотвеченных отзывов выберите <strong>«Юридическое
              лицо»</strong>.
            </Alert>
          )}
        </Box>
      </Box>
    </AllowGuard>
  )
}

export default Reviews