import Drawer                from '@mui/material/Drawer'
import {
  useAppDispatch,
  useAppSelector
}                            from '../../../../app/store/hooks'
import {
  PERMISSION_MANAGER_CREATE,
  PERMISSION_MANAGER_UPDATE
}                            from '../../../../app/contants/permissions'
import AllowGuard            from '../../../auth/guards/AllowGuard'
import {
  onCloseDrawer,
  selectOpenDrawer,
  selectUpdateProductCategory
}                            from '../../store/productCategorySlice'
import UpdateProductCategory from './UpdateProductCategory'
import CreateProductCategory from './CreateProductCategory'

const ProductCategoryDrawer = () => {

  const open = useAppSelector(selectOpenDrawer)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(onCloseDrawer())
  }

  const updateProductCategory = useAppSelector(selectUpdateProductCategory)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
    >
      {updateProductCategory ? (
        <AllowGuard permissions={[PERMISSION_MANAGER_UPDATE]}>
          <UpdateProductCategory productCategory={updateProductCategory} onClose={onClose}/>
        </AllowGuard>
      ) : (
        <AllowGuard permissions={[PERMISSION_MANAGER_CREATE]}>
          <CreateProductCategory onClose={onClose}/>
        </AllowGuard>
      )}
    </Drawer>
  )
}

export default ProductCategoryDrawer