import Toolbar                              from '@mui/material/Toolbar'
import Typography                           from '@mui/material/Typography'
import Box                                  from '@mui/material/Box'
import {PERMISSION_MANUFACTURE_ORDERS_LIST} from '../../../app/contants/permissions'
import AllowGuard                           from '../../auth/guards/AllowGuard'
import ManufactureOrdersTable               from '../components/orders/ManufactureOrdersTable'

const ManufactureOrders = () => {
  return (
    <AllowGuard permissions={[PERMISSION_MANUFACTURE_ORDERS_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Список заказов
        </Typography>
        <Box flexGrow={1}/>
      </Toolbar>
      <ManufactureOrdersTable/>
    </AllowGuard>
  )
}

export default ManufactureOrders