import Stack                    from '@mui/material/Stack'
import Chip                     from '@mui/material/Chip'
import IconButton               from '@mui/material/IconButton'
import Link                     from '@mui/material/Link'
import TableRow                 from '@mui/material/TableRow'
import Typography               from '@mui/material/Typography'
import IconUserPen              from '../../../../app/components/icons/IconUserPen'
import {PERMISSION_USER_UPDATE} from '../../../../app/contants/permissions'
import {useAppDispatch}         from '../../../../app/store/hooks'
import useAllow                 from '../../../auth/hooks/useAllow'
import type {UserType}          from '../../types'
import {setUpdateUser}          from '../../store/userSlice'
import {StyledTableCell}        from './UsersTable'
import DeleteUserButton         from './DeleteUserButton'

const UsersTableRow = ({
  user
}: {
  user: UserType
}) => {

  const allowUpdate = useAllow([PERMISSION_USER_UPDATE])

  const dispatch = useAppDispatch()

  const onUpdate = () => {
    dispatch(setUpdateUser(user))
  }

  return (
    <TableRow hover tabIndex={-1}>
      <StyledTableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          {allowUpdate ? (
            <Link onClick={onUpdate} underline="hover">
              {user.username}
            </Link>
          ) : (
            <Typography variant="inherit">
              {user.username}
            </Typography>
          )}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="left">
        <Stack direction="row" spacing={1}>
          {user.roles.map(role => (
            <Chip key={role} label={role} size="small"/>
          ))}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="right" padding="none">
        {allowUpdate && (
          <IconButton color="primary" onClick={onUpdate}>
            <IconUserPen/>
          </IconButton>
        )}
        <DeleteUserButton id={user.id}/>
      </StyledTableCell>
    </TableRow>
  )
}

export default UsersTableRow