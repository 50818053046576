import TableRow        from '@mui/material/TableRow'
import Typography      from '@mui/material/Typography'
import StyledTableCell from '../StyledTableCell'

const ManufactureOrdersTableNotFound = () => {

  return (
    <TableRow tabIndex={-1}>
      <StyledTableCell colSpan={5}>
        <Typography color="text.secondary" align="center" sx={{ py: 5 }}>
          Заказы на производство не найдены
        </Typography>
      </StyledTableCell>
    </TableRow>
  )
}

export default ManufactureOrdersTableNotFound