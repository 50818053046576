import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

const IconFilters = ({ fontSize = 'inherit', ...rest }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 640 512" fontSize={fontSize} {...rest}>
    <path
      d="M195.4 55.93C201.8 41.39 216.1 32 231.1 32H600C615.9 32 630.2 41.39 636.6 55.93C643 70.46 640.2 87.4 629.4 99.08L480 261.5V416C480 428.1 473.2 439.2 462.3 444.6C451.5 450 438.5 448.9 428.8 441.6L364.8 393.6C356.7 387.6 352 378.1 352 368V261.5L202.6 99.08C191.8 87.4 188.1 70.46 195.4 55.93H195.4z"
    />
    <path
      opacity="0.4"
      d="M39.99 96H164.1C167.3 105 172.3 113.4 179 120.7L320 273.1V290.7L288 325.5V448C288 460.1 281.2 471.2 270.3 476.6C259.5 482 246.5 480.9 236.8 473.6L172.8 425.6C164.7 419.6 160 410.1 160 400V325.5L10.55 163.1C-.197 151.4-3.019 134.5 3.36 119.9C9.74 105.4 24.11 96 39.99 96V96z"
    />
  </SvgIcon>
)

export default IconFilters