import {Link}                                from 'react-router-dom'
import TableRow                              from '@mui/material/TableRow'
import Typography                            from '@mui/material/Typography'
import MuiLink                               from '@mui/material/Link'
import {fDate}                               from '../../../../app/utils/formatTime'
import {fPcs}                                from '../../../../app/utils/formatNumber'
import {PERMISSION_MANUFACTURE_ORDER_UPDATE} from '../../../../app/contants/permissions'
import useAllow                              from '../../../auth/hooks/useAllow'
import {ManufactureOrdersListItem}           from '../../types'
import StyledTableCell                       from '../StyledTableCell'

const ManufactureOrdersTableRow = ({
  order
}: {
  order: ManufactureOrdersListItem
}) => {

  const allowUpdate = useAllow([PERMISSION_MANUFACTURE_ORDER_UPDATE])

  return (
    <TableRow hover tabIndex={-1}>
      <StyledTableCell>
        {allowUpdate ? (
          <MuiLink component={Link} to={`/manufacture/orders/wb/update/${order.id}`} underline="hover">
            № {order.id}
          </MuiLink>
        ) : (
          <Typography variant="inherit">
            № {order.id}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {fPcs(order.quantity)}
      </StyledTableCell>
      <StyledTableCell>
        {order.status_name}
      </StyledTableCell>
      <StyledTableCell>
        {fDate(order.date_time)}
      </StyledTableCell>
      <StyledTableCell>
        {order.comment}
      </StyledTableCell>
    </TableRow>
  )
}

export default ManufactureOrdersTableRow