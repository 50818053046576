import {useState}                            from 'react'
import {useParams}                           from 'react-router-dom'
import Typography                            from '@mui/material/Typography'
import Stack                                 from '@mui/material/Stack'
import Button                                from '@mui/material/Button'
import TextField                             from '@mui/material/TextField'
import {DatePicker}                          from '@mui/x-date-pickers/DatePicker'
import Grid                                  from '@mui/material/Grid'
import Alert                                 from '@mui/material/Alert'
import LoadingBox                            from '../../../app/components/LoadingBox'
import {PERMISSION_REPORTS_REALISATION_VIEW} from '../../../app/contants/permissions'
import IconUpdatedDate                       from '../../../app/components/icons/IconUpdatedDate'
import type {MarketCodeType}                 from '../../markets/types'
import AllowGuard                            from '../../auth/guards/AllowGuard'
import {useGetFeedbacksStatisticsMutation}   from '../services/reportsApi'
import FeedbackStatisticsTable               from '../components/table/FeedbackStatisticsTable'

const FeedbackStatistics = () => {

  const { mpCode } = useParams<{ mpCode: MarketCodeType }>()

  const [dateFrom, setDateFrom] = useState<string | null>(null)
  const [dateTo, setDateTo] = useState<string | null>(null)

  const [getFeedbacksStatistics, {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useGetFeedbacksStatisticsMutation()

  const onGenerateReport = () => {
    if (dateFrom && dateTo) {
      getFeedbacksStatistics({
        date_from: dateFrom,
        date_to: dateTo,
      })
    }
  }

  return (
    <AllowGuard permissions={[PERMISSION_REPORTS_REALISATION_VIEW]}>
      <Grid container spacing={3} alignItems="flex-start" paddingTop={2}>
        <Grid item xs={12} md={4}>
          <Typography color="text.secondary" variant="h4" noWrap>
            Отчет по отзывам
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <Stack direction="row" justifyContent={{ xs: 'flex-start', md: 'flex-end' }} spacing={1}>
            <DatePicker
              label="Дата от"
              inputFormat="dd/MM/yyyy"
              value={dateFrom}
              onChange={(newValue) => setDateFrom(newValue)}
              renderInput={(params) => (
                <TextField {...params} name="date_from" size="small"/>
              )}
            />
            <DatePicker
              label="Дата до"
              inputFormat="dd/MM/yyyy"
              value={dateTo}
              onChange={(newValue) => setDateTo(newValue)}
              renderInput={(params) => (
                <TextField {...params} name="date_to" size="small"/>
              )}
            />
            <Button
              variant="contained"
              onClick={onGenerateReport}
              sx={{ fontSize: 12, height: 40 }}
              disabled={!(mpCode && dateFrom && dateTo)}
            >
              <Typography variant="inherit" noWrap>
                Сформировать отчет
              </Typography>
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Stack direction="column" flexGrow={1} paddingTop={4}>
        <LoadingBox isLoading={isLoading}>
          {data ? (
            <FeedbackStatisticsTable rows={data}/>
          ) : (
            <Alert
              variant="filled"
              icon={<IconUpdatedDate sx={{ fontSize: 56 }}/>}
              sx={{ backgroundColor: 'primary.main' }}
            >
              <Typography variant="h6" fontWeight={900}>
                Выберите «диапазон дат»
              </Typography>
              Чтобы отобразить отчет по отзывам, выберите <strong>«диапазон дат»</strong> и
              нажмите <strong>«Сформировать отчет»</strong>.
            </Alert>
          )}
        </LoadingBox>
      </Stack>
    </AllowGuard>
  )
}

export default FeedbackStatistics