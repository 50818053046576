import Box, {BoxProps} from '@mui/material/Box'

interface TabPanelProps extends BoxProps {
  index: number | string;
  value: number | string;
}

const TabPanel = ({ children, value, index, sx, ...rest }: TabPanelProps) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      sx={{
        py: 1,
        ...sx
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export default TabPanel