import Button           from '@mui/material/Button'
import IconPlus         from '../../../app/components/icons/IconPlus'
import {useAppDispatch} from '../../../app/store/hooks'
import {onCreateMarket} from '../store/marketSlice'

const CreateMarketButton = () => {

  const dispatch = useAppDispatch()

  const onClick = () => {
    dispatch(onCreateMarket())
  }

  return (
    <Button
      size="large"
      variant="contained"
      sx={{ p: 1, minWidth: 'auto' }}
      onClick={onClick}
    >
      <IconPlus sx={{ fontSize: 28 }}/>
    </Button>
  )
}

export default CreateMarketButton