import type {TextFieldProps}     from '@mui/material/TextField/TextField'
import TextField                 from '@mui/material/TextField'
import MenuItem                  from '@mui/material/MenuItem'
import {useGetManagersListQuery} from '../services/managersApi'

const SelectManagers = (props: TextFieldProps) => {

  const { data, isLoading } = useGetManagersListQuery()

  return (
    <TextField
      select
      label={isLoading ? 'Загрузка...' : 'Менеджер'}
      {...props}
    >
      {props.children}
      {data && data.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectManagers