import {
  createSlice,
  PayloadAction
}                       from '@reduxjs/toolkit'
import type {RootState} from '../../../app/store/types'
import {UserType}       from '../types'

type UserState = {
  openDrawer: boolean;
  updateUser: UserType | null;
}

const initialState: UserState = {
  openDrawer: false,
  updateUser: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onCloseDrawer: (state) => {
      state.openDrawer = false
      state.updateUser = null
    },
    onCreateUser: (state) => {
      state.openDrawer = true
      state.updateUser = null
    },
    setUpdateUser: (state, action: PayloadAction<UserType>) => {
      state.openDrawer = true
      state.updateUser = action.payload
    }
  },
})

export const {
  onCloseDrawer,
  onCreateUser,
  setUpdateUser,
} = userSlice.actions

export default userSlice.reducer

export const selectOpenDrawer = (state: RootState) => state.user.openDrawer
export const selectUpdateUser = (state: RootState) => state.user.updateUser
