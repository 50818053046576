import Toolbar                                           from '@mui/material/Toolbar'
import Typography                                        from '@mui/material/Typography'
import Box                                               from '@mui/material/Box'
import {PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST} from '../../../app/contants/permissions'
import AllowGuard                                        from '../../auth/guards/AllowGuard'
import AddSearchPhraseByCategory                         from '../../searchPhrases/components/AddSearchPhraseByCategory'
import DeleteSearchPhraseByCategory
                                                         from '../../searchPhrases/components/DeleteSearchPhraseByCategory'

const ProductCategoriesSearchPhrases = () => {
  return (
    <AllowGuard permissions={[PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap margin={0}>
          Поисковые фразы
        </Typography>
        <Box flexGrow={1}/>
      </Toolbar>
      <AddSearchPhraseByCategory mpCode="wb"/>
      <DeleteSearchPhraseByCategory mpCode="wb"/>
    </AllowGuard>
  )
}

export default ProductCategoriesSearchPhrases