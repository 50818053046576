import Drawer        from '@mui/material/Drawer'
import {
  useAppDispatch,
  useAppSelector
}                    from '../../../../app/store/hooks'
import {
  onCloseDrawer,
  selectOpenDrawer,
  selectUpdateProduct
}                    from '../../store/productSlice'
import UpdateProduct from './UpdateProduct'
import CreateProduct from './CreateProduct'
import AllowGuard    from '../../../auth/guards/AllowGuard'
import {
  PERMISSION_PRODUCTS_CREATE,
  PERMISSION_PRODUCTS_UPDATE
}                    from '../../../../app/contants/permissions'

const ProductDrawer = () => {

  const open = useAppSelector(selectOpenDrawer)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(onCloseDrawer())
  }

  const updateProduct = useAppSelector(selectUpdateProduct)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 880, maxWidth: '100%' } }}
    >
      {updateProduct ? (
        <AllowGuard permissions={[PERMISSION_PRODUCTS_UPDATE]}>
          <UpdateProduct product={updateProduct} onClose={onClose}/>
        </AllowGuard>
      ) : (
        <AllowGuard permissions={[PERMISSION_PRODUCTS_CREATE]}>
          <CreateProduct onClose={onClose}/>
        </AllowGuard>
      )}
    </Drawer>
  )
}

export default ProductDrawer