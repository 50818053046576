import type {ChangeEvent}                    from 'react'
import {
  useEffect,
  useState
}                                            from 'react'
import {useParams}                           from 'react-router-dom'
import Typography                            from '@mui/material/Typography'
import Stack                                 from '@mui/material/Stack'
import Alert                                 from '@mui/material/Alert'
import Button                                from '@mui/material/Button'
import TextField                             from '@mui/material/TextField'
import {DatePicker}                          from '@mui/x-date-pickers/DatePicker'
import Box                                   from '@mui/material/Box'
import {useSnackbar}                         from 'notistack'
import IconFilters                           from '../../../app/components/icons/IconFilters'
import LoadingBox                            from '../../../app/components/LoadingBox'
import {PERMISSION_REPORTS_REALISATION_VIEW} from '../../../app/contants/permissions'
import useLocalStorage                       from '../../../app/hooks/useLocalStorage'
import type {MarketCodeType}                 from '../../markets/types'
import AllowGuard                            from '../../auth/guards/AllowGuard'
import RealisationTable                      from '../components/realisation/table/RealisationTable'
import type {
  RealisationFiltersType,
  RealisationRowType
}                                            from '../types'
import {useGetRealisationMutation}           from '../services/reportsApi'
import RealisationFiltersDrawer              from '../components/realisation/RealisationFiltersDrawer'

const Realisation = () => {

  const { mpCode } = useParams<{ mpCode: MarketCodeType }>()

  const [filters, setFilters] = useLocalStorage<RealisationFiltersType>(
    'REALISATION_TABLE_FILTERS',
    {
      legal_entity_id: 0,
      manager_id: 0,
      categories: [],
      archive: false
    },
    true
  )

  const [dateFrom, setDateFrom, saveDateFrom] = useLocalStorage<string | null>(
    'REALISATION_TABLE_DATE_FROM',
    null
  )
  const [dateTo, setDateTo, saveDateTo] = useLocalStorage<string | null>(
    'REALISATION_TABLE_DATE_TO',
    null
  )
  const [reportId, setReportId, saveReportId] = useLocalStorage<number | null>(
    'REALISATION_TABLE_REPORT_ID',
    null,
  )
  const [showType, setShowType, saveShowType] = useLocalStorage<'report_number' | 'date_range'>(
    'REALISATION_TABLE_SHOW_TYPE',
    'report_number'
  )

  const [getRealisation, {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useGetRealisationMutation()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    // @ts-ignore
    if (isError && error && error.status == 404) {
      enqueueSnackbar(`Отчет ${reportId} не найден.`, { variant: 'error' })
    }
  }, [isError, error])

  const [rows, setRows] = useState<RealisationRowType[]>([])

  useEffect(() => {
    if (data) {
      let dataRows = []
      if (filters.categories.slice(-1)[0] === 0) {
        dataRows = data.rows.filter((row) => (
          row.categories.length == 0 && (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (filters.manager_id == 0) ||
            (filters.manager_id == 'none' && row.manager_id == null) ||
            filters.manager_id == row.manager_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      } else if (filters.categories.length > 0) {
        dataRows = data.rows.filter((row) => {
          if (
            (
              filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
            ) && (
              (filters.manager_id == 0) ||
              (filters.manager_id == 'none' && row.manager_id == null) ||
              filters.manager_id == row.manager_id
            ) && (
              (!filters.archive && !row.archive) || filters.archive
            )
          ) {
            for (let cat of row.categories) {
              if (filters.categories.includes(cat.id)) {
                return true
              }
            }
          }
          return false
        })
      } else {
        dataRows = data.rows.filter((row) => (
          (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (filters.manager_id == 0) ||
            (filters.manager_id == 'none' && row.manager_id == null) ||
            filters.manager_id == row.manager_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      }
      setRows(dataRows)
    }
  }, [isSuccess, data, filters])

  const toggleShowType = () => {
    if (showType === 'report_number') {
      setShowType('date_range')
      setReportId(null)
    } else {
      setShowType('report_number')
      setDateFrom(null)
      setDateTo(null)
    }
  }

  const onChangeReportId = (event: ChangeEvent<HTMLInputElement>) => {
    setReportId(Number(event.target.value))
  }

  const onGenerateReport = () => {
    if (mpCode && ((dateFrom && dateTo) || reportId)) {
      getRealisation({
        mp_code: mpCode,
        date_from: dateFrom,
        date_to: dateTo,
        report_id: reportId
      })
      saveDateFrom()
      saveDateTo()
      saveReportId()
      saveShowType()
    }
  }

  return (
    <AllowGuard permissions={[PERMISSION_REPORTS_REALISATION_VIEW]}>
      <Stack direction="row" spacing={2} alignItems="center" paddingTop={2}>
        <Typography color="text.secondary" variant="h4" noWrap>
          Реализация Wildberries
        </Typography>
        <Box flexGrow={1}/>
        <Stack direction="row" spacing={1}>
          {showType === 'report_number' ? (
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Номер отчета"
              sx={{ maxWidth: { lg: 220 } }}
              name="report_number"
              value={reportId}
              onChange={onChangeReportId}
            />
          ) : (
            <>
              <DatePicker
                label="Дата от"
                inputFormat="dd/MM/yyyy"
                value={dateFrom}
                onChange={(newValue) => setDateFrom(newValue)}
                renderInput={(params) => (
                  <TextField {...params} name="date_from" size="small"/>
                )}
              />
              <DatePicker
                label="Дата до"
                inputFormat="dd/MM/yyyy"
                value={dateTo}
                onChange={(newValue) => setDateTo(newValue)}
                renderInput={(params) => (
                  <TextField {...params} name="date_to" size="small"/>
                )}
              />
            </>
          )}
          <Button
            variant="contained"
            onClick={toggleShowType}
            startIcon={<IconFilters/>}
            sx={{ fontSize: 12, height: 40 }}
            disabled={isLoading}
          >
            <Typography variant="inherit" noWrap>
              По {showType === 'report_number' ? 'диапазону дат' : 'номеру отчета'}
            </Typography>
          </Button>
          <Button
            variant="contained"
            onClick={onGenerateReport}
            sx={{ fontSize: 12, height: 40 }}
            disabled={!(mpCode && ((dateFrom && dateTo) || reportId)) || isLoading}
          >
            <Typography variant="inherit" noWrap>
              Сформировать отчет
            </Typography>
          </Button>
        </Stack>
        <RealisationFiltersDrawer
          filters={filters}
          setFilters={setFilters}
          disabled={isLoading}
        />
      </Stack>
      <Stack direction="column" flexGrow={1} paddingTop={4}>
        <LoadingBox isLoading={isLoading}>
          {rows.length > 0 ? (
            <RealisationTable rows={rows}/>
          ) : (
            <Alert
              variant="filled"
              icon={<IconFilters sx={{ fontSize: 56 }}/>}
              sx={{ backgroundColor: 'primary.main' }}
            >
              <Typography variant="h6" fontWeight={900}>
                Выберите «диапазон дат» или «номер отчета»
              </Typography>
              Чтобы отобразить список отчетов о реализации, выберите
              <strong>«диапазон дат»</strong> или <strong>«номер отчета»</strong>
              и нажмите <strong>«Сформировать отчет»</strong>.
            </Alert>
          )}
        </LoadingBox>
      </Stack>
    </AllowGuard>
  )
}

export default Realisation