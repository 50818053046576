import {
  format,
  formatDistanceToNow,
  getTime
}           from 'date-fns'
import {ru} from 'date-fns/locale'

export function fApiDate (date: number | string | Date) {
  return format(new Date(date), 'yyyy-MM-dd')
}

export function fDate (date: number | string | Date) {
  return format(new Date(date), 'dd.MM.yyyy', { locale: ru })
}

export function fDateTime (date: number | string | Date) {
  return format(new Date(date), 'dd MMM yyyy p')
}

export function fTime (date: number | string | Date) {
  return format(new Date(date), 'p')
}

export function fTimestamp (date: number | string | Date) {
  return getTime(new Date(date))
}

export function fDateTimeSuffix (date: number | string | Date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow (date: number | string | Date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  })
}