import type {ChangeEvent}                 from 'react'
import {
  useEffect,
  useState
}                                         from 'react'
import Paper                              from '@mui/material/Paper'
import Stack                              from '@mui/material/Stack'
import Typography                         from '@mui/material/Typography'
import Autocomplete                       from '@mui/material/Autocomplete'
import TextField                          from '@mui/material/TextField'
import LoadingButton                      from '@mui/lab/LoadingButton'
import MenuItem                           from '@mui/material/MenuItem'
import {useSnackbar}                      from 'notistack'
import {MarketCodeType}                   from '../../markets/types'
import {useGetProductCategoriesListQuery} from '../../products/services/productsApi'
import {
  useCreateSearchPhraseMutation,
  useGetSearchPhrasesQuery
}                                         from '../services/searchPhrasesApi'

const AddSearchPhraseByCategory = ({
  mpCode
}: {
  mpCode: MarketCodeType;
}) => {

  const { data: categories } = useGetProductCategoriesListQuery()

  const { data: phrases } = useGetSearchPhrasesQuery()

  const [createSearchPhrase, {
    data: count,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useCreateSearchPhraseMutation()

  const [categoryId, setCategoryId] = useState<number | null>(null)

  const onChangeCategoryId = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryId(Number(event.target.value))
  }

  const [phrase, setPhrase] = useState<string | null>(null)

  const onAdd = () => {
    if (categoryId && phrase) {
      createSearchPhrase({ mp_code: mpCode, category_id: categoryId, search_phrase: phrase })
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isSuccess) {
      if (count) {
        enqueueSnackbar(`Фраза успешно добавлена к ${count} продуктам.`, { variant: 'success' })
      } else {
        enqueueSnackbar(`Фразы уже добавлены.`, { variant: 'info' })
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('Фразы не добавляются.', { variant: 'error' })
    }
  }, [isError, error])

  return (
    <Paper sx={{ width: '100%', mb: 4, p: 2 }}>
      <Typography color="text.secondary" variant="h6" noWrap marginBottom={2}>
        Добавить поисковую фразу
      </Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          select
          size="small"
          label="Выберите категорию"
          value={categoryId}
          onChange={onChangeCategoryId}
          sx={{ flexGrow: 1, maxWidth: 340 }}
          disabled={isLoading}
        >
          {categories && categories.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <Autocomplete
          freeSolo
          options={phrases ?? []}
          onChange={(event, newValue) => {
            setPhrase(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            setPhrase(newInputValue)
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label="Поисковая фраза"
              disabled={isLoading}
            />
          )}
          sx={{ flexGrow: 1 }}
        />
        <LoadingButton
          size="small"
          color="success"
          variant="contained"
          loading={isLoading}
          onClick={onAdd}
          sx={{ width: 120 }}
        >
          Добавлять
        </LoadingButton>
      </Stack>
    </Paper>
  )
}

export default AddSearchPhraseByCategory