import type {PropsWithChildren} from 'react'
import {
  Navigate,
  useLocation
}                               from 'react-router-dom'
import {useAppSelector}         from '../../../app/store/hooks'
import {selectToken}            from '../store/authSlice'

const AuthGuard = ({ children }: PropsWithChildren<{}>) => {

  const token = useAppSelector(selectToken)
  const location = useLocation()

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace/>
  }

  return (
    <>{children}</>
  )
}

export default AuthGuard