import type {ChangeEvent}                    from 'react'
import {
  useEffect,
  useState
}                                            from 'react'
import TableContainer                        from '@mui/material/TableContainer'
import Table                                 from '@mui/material/Table'
import TableHead                             from '@mui/material/TableHead'
import TableBody                             from '@mui/material/TableBody'
import TableRow                              from '@mui/material/TableRow'
import Typography                            from '@mui/material/Typography'
import TableFooter                           from '@mui/material/TableFooter'
import {
  InputAdornment,
  InputBase
}                                            from '@mui/material'
import {fPcs}                                from '../../../../../app/utils/formatNumber'
import type {MarketCodeType}                 from '../../../../markets/types'
import type {ManufactureOrderItemsStateType} from '../../../types'
import StyledTableCell                       from '../../StyledTableCell'
import StyledTableRow                        from '../../StyledTableRow'
import MatrixAddWarehouseDrawer              from '../MatrixAddWarehouseDrawer'
import MatrixAddProductDrawer                from '../MatrixAddProductDrawer'

const MatrixTable = ({
  mpCode,
  legalEntityId,
  legalEntityName,
  items,
  changeItem,
  addProduct,
  addWarehouse
}: {
  mpCode: MarketCodeType;
  legalEntityId: number;
  legalEntityName: string;
  items: ManufactureOrderItemsStateType;
  changeItem: (props: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
    wh_name: string;
    quantity: number;
  }) => void;
  addProduct: (props: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
  }) => void;
  addWarehouse: (props: {
    legal_entity_id: number;
    wh_name: string;
  }) => void;
}) => {

  const [totalByWh, setTotalByWh] = useState<Record<string, number>>({})
  const [totalByProduct, setTotalByProduct] = useState<Record<number, number>>({})

  useEffect(() => {
    let whTotal: Record<string, number> = {}
    let productTotal: Record<number, number> = {}
    if (items[legalEntityId] !== undefined) {
      for (const productId in items[legalEntityId]) {
        const quantity = items[legalEntityId][productId]['quantity']
        for (const whName in quantity) {
          whTotal = {
            ...whTotal,
            [whName]: (whTotal[whName] || 0) + quantity[whName]
          }
          productTotal = {
            ...productTotal,
            [productId]: (productTotal[productId] || 0) + quantity[whName]
          }
        }
      }
      setTotalByWh(whTotal)
      setTotalByProduct(productTotal)
    }
  }, [items])

  return (
    <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
      <Table stickyHeader aria-labelledby="tableTitle" size="small">
        <TableHead
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 2
          }}
        >
          <TableRow>
            <StyledTableCell
              sx={{
                position: 'sticky',
                left: 0,
                borderRight: '1px solid #e0e0e0',
                zIndex: 3
              }}
            >
              <Typography color="text.secondary" fontSize={14}>
                Товар
              </Typography>
            </StyledTableCell>
            {Object.keys(totalByWh).map(wh => (
              <StyledTableCell key={wh} align="right" sx={{ borderRight: '1px solid #e0e0e0' }}>
                <Typography color="text.secondary" fontSize={14} noWrap sx={{ width: 80, maxWidth: '100%' }}>
                  {wh}
                </Typography>
              </StyledTableCell>
            ))}
            <StyledTableCell>
              <Typography color="text.secondary" fontSize={14}>
                Итого
              </Typography>
            </StyledTableCell>
            <StyledTableCell
              padding="none"
              align="right"
              sx={{
                position: 'sticky',
                right: 0,
                maxWidth: 40,
                borderLeft: '1px solid #e0e0e0',
                zIndex: 3
              }}
            >
              <MatrixAddWarehouseDrawer
                mpCode={mpCode}
                legalEntityId={legalEntityId}
                items={items}
                addWarehouse={addWarehouse}
                disabled={items[legalEntityId] == undefined}
              />
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items[legalEntityId] ? Object.values(items[legalEntityId]).map((item) => (
            <StyledTableRow
              key={item.product_id}
              tabIndex={-1}
              hover
            >
              <StyledTableCell
                sx={{
                  position: 'sticky',
                  left: 0,
                  borderRight: '1px solid #e0e0e0',
                  zIndex: 1
                }}
              >
                <Typography color="text.secondary" fontSize={14} noWrap width={400}>
                  {item.product_name}
                </Typography>
              </StyledTableCell>
              {Object.keys(totalByWh).map(wh => (
                <StyledTableCell key={wh} align="right" sx={{ borderRight: '1px solid #e0e0e0' }}>
                  <InputBase
                    placeholder="0"
                    endAdornment={(
                      <InputAdornment
                        position="end"
                        disableTypography={true}
                        sx={{ m: 0 }}
                      >
                        шт
                      </InputAdornment>
                    )}
                    sx={{ fontSize: 12, width: 50 }}
                    inputProps={{
                      sx: { textAlign: 'center' }
                    }}
                    value={item.quantity[wh] || ''}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => changeItem({
                      legal_entity_id: legalEntityId,
                      product_id: item.product_id,
                      product_name: item.product_name,
                      wh_name: wh,
                      quantity: Number(event.target.value)
                    })}
                  />
                </StyledTableCell>
              ))}
              <StyledTableCell>
                <Typography color="text.secondary" fontWeight={900} fontSize={14} noWrap>
                  {fPcs(totalByProduct[item.product_id] || 0)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell
                sx={{
                  position: 'sticky',
                  right: 0,
                  borderLeft: '1px solid #e0e0e0',
                  zIndex: 1
                }}
              />
            </StyledTableRow>
          )) : (
            <StyledTableRow tabIndex={-1}>
              <StyledTableCell colSpan={3} sx={{ height: 100 }}>
                <Typography color="error" align="center">
                  Матрица для "{legalEntityName}" пуста.
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
        <TableFooter
          sx={{
            position: 'sticky',
            bottom: 0,
            zIndex: 2
          }}
        >
          <TableRow>
            <StyledTableCell
              padding="none"
              sx={{
                position: 'sticky',
                left: 0,
                borderRight: '1px solid #e0e0e0',
                zIndex: 3
              }}
            >
              <MatrixAddProductDrawer
                legalEntityId={legalEntityId}
                items={items}
                addProduct={addProduct}
              />
            </StyledTableCell>
            {Object.values(totalByWh).map((value, key) => (
              <StyledTableCell key={key} align="right" sx={{ borderRight: '1px solid #e0e0e0' }}>
                <Typography color="text.secondary" fontWeight={900} fontSize={14} noWrap>
                  {fPcs(value)}
                </Typography>
              </StyledTableCell>
            ))}
            <StyledTableCell>
              <Typography color="text.secondary" fontWeight={900} fontSize={14} noWrap>
                {fPcs(Object.values(totalByWh).reduce((sum, a) => sum + a, 0))}
              </Typography>
            </StyledTableCell>
            <StyledTableCell
              sx={{
                position: 'sticky',
                right: 0,
                borderLeft: '1px solid #e0e0e0',
                zIndex: 3
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

export default MatrixTable