import InputLabel                  from '@mui/material/InputLabel'
import MenuItem                    from '@mui/material/MenuItem'
import FormControl                 from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import {useGetSuppliersQuery}      from '../../../features/wildberries/services/suppliersApi'

const SelectSuppliers = ({
  supplierId,
  setSupplierId
}: {
  supplierId: string;
  setSupplierId: (newSupplierId: string) => void;
}) => {

  const { data, isLoading } = useGetSuppliersQuery()

  const onChange = (e: SelectChangeEvent) => setSupplierId(e.target.value)

  return (
    <FormControl sx={{ width: 240 }} size="small">
      <InputLabel id="select-suppliers-label">
        {isLoading ? 'Загрузка...' : 'Юридическое лицо'}
      </InputLabel>
      <Select
        labelId="select-suppliers-label"
        id="select-suppliers"
        value={supplierId}
        label="Юридическое лицо"
        onChange={onChange}
      >
        {data && data.suppliers.map(({ id, name }) => (
          <MenuItem key={id} value={id}>{name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SelectSuppliers