import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

const IconPlus = ({ fontSize = 'inherit', ...rest }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 448 512" fontSize={fontSize} {...rest}>
    <path
      d="M432 256C432 269.3 421.3 280 408 280h-160v160c0 13.25-10.75 24.01-24 24.01S200 453.3 200 440v-160h-160c-13.25 0-24-10.74-24-23.99C16 242.8 26.75 232 40 232h160v-160c0-13.25 10.75-23.99 24-23.99S248 58.75 248 72v160h160C421.3 232 432 242.8 432 256z"
    />
  </SvgIcon>
)

export default IconPlus