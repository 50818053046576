import {Outlet} from 'react-router-dom'

const Reports = () => {

  return (
    <>
      <Outlet/>
    </>
  )
}

export default Reports