import Toolbar                       from '@mui/material/Toolbar'
import Typography                    from '@mui/material/Typography'
import Box                           from '@mui/material/Box'
import Stack                         from '@mui/material/Stack'
import {
  PERMISSION_PRODUCTS_CREATE,
  PERMISSION_PRODUCTS_LIST
}                                    from '../../../app/contants/permissions'
import AllowGuard                    from '../../auth/guards/AllowGuard'
import ProductCategoryMultipleSelect from '../components/categories/ProductCategoryMultipleSelect'
import ProductsList                  from '../components/products/ProductsList'
import CheckboxLinked                from '../components/products/CheckboxLinked'
import CreateProductButton           from '../components/products/CreateProductButton'
import ProductDrawer                 from '../components/products/ProductDrawer'
import CheckboxArchived              from '../components/products/CheckboxArchived'

const Products = () => {
  return (
    <AllowGuard permissions={[PERMISSION_PRODUCTS_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Товары
        </Typography>
        <Box flexGrow={1}/>
        <Stack direction="row" spacing={2}>
          <ProductCategoryMultipleSelect/>
          <CheckboxLinked/>
          <CheckboxArchived/>
          <AllowGuard permissions={[PERMISSION_PRODUCTS_CREATE]}>
            <CreateProductButton/>
          </AllowGuard>
        </Stack>
      </Toolbar>
      <ProductsList/>
      <ProductDrawer/>
    </AllowGuard>
  )
}

export default Products