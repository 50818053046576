import {
  useEffect,
  useState
}                      from 'react'
import Paper           from '@mui/material/Paper'
import TableContainer  from '@mui/material/TableContainer'
import Table           from '@mui/material/Table'
import TableHead       from '@mui/material/TableHead'
import TableRow        from '@mui/material/TableRow'
import Typography      from '@mui/material/Typography'
import Rating          from '@mui/material/Rating'
import TableBody       from '@mui/material/TableBody'
import {fDate}         from '../../../../app/utils/formatTime'
import {
  FeedbackActionsType,
  FeedbackRatingType,
  FeedbackStatisticsResponse
}                      from '../../types'
import StyledTableRow  from './styled/StyledTableRow'
import StyledTableCell from './styled/StyledTableCell'

const FeedbackStatisticsTable = ({
  rows
}: {
  rows: FeedbackStatisticsResponse
}) => {

  const [dates, setDates] = useState<string[]>([])

  useEffect(() => {
    setDates(Object.entries(rows).map(([date]) => date))
  }, [dates])

  const ratings: FeedbackRatingType[] = ['5', '4', '3', '2', '1']
  const actions: FeedbackActionsType[] = ['new', 'deleted']

  return (
    <Paper sx={{ width: '100%', mb: 4, p: 2 }}>
      <TableContainer
        sx={{
          maxHeight: 'calc(100vh - 300px)',
          border: '1px solid #e0e0e0',
          borderBottom: 'none'
        }}
      >
        <Table aria-labelledby="tableTitle" size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell
                align="center"
                sx={{
                  position: 'sticky',
                  left: 0,
                  zIndex: 3,
                  borderLeft: '1px solid #e0e0e0'
                }}
              >
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Рейтинг
                </Typography>
              </StyledTableCell>
              {dates.map(date => (
                <StyledTableCell
                  key={date}
                  colSpan={2}
                  align="center"
                >
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    {fDate(date)}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ratings.map((r) => (
              <StyledTableRow key={r}>
                <StyledTableCell
                  align="center"
                  sx={{
                    py: 0,
                    position: 'sticky',
                    left: 0,
                    zIndex: 3,
                    backgroundColor: '#fff'
                  }}
                >
                  <Rating
                    readOnly
                    size="small"
                    value={Number(r)}
                    sx={{ height: 30, alignItems: 'center' }}
                  />
                </StyledTableCell>
                {Object.entries(rows).map(([d, v]) => actions.map((a) => (
                  <StyledTableCell
                    key={`${d}-${a}`}
                    align="center"
                    sx={a == 'new' ? { borderLeft: '1px solid #e0e0e0' } : {}}
                  >
                    <Typography variant="inherit" color={a == 'new' ? 'success' : 'error'}>
                      {v[r][a]}
                    </Typography>
                  </StyledTableCell>
                )))}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default FeedbackStatisticsTable