import {styled}                           from '@mui/material/styles'
import Table                              from '@mui/material/Table'
import TableBody                          from '@mui/material/TableBody'
import TableCell, {tableCellClasses}      from '@mui/material/TableCell'
import TableContainer                     from '@mui/material/TableContainer'
import TableRow                           from '@mui/material/TableRow'
import Paper                              from '@mui/material/Paper'
import Typography                         from '@mui/material/Typography'
import TableHead                          from '@mui/material/TableHead'
import {useGetProductCategoriesListQuery} from '../../../services/productsApi'
import ProductCategoriesTableRow          from './ProductCategoriesTableRow'
import ProductCategoriesTableSkeleton     from './ProductCategoriesTableSkeleton'
import ProductCategoriesTableNotFound     from './ProductCategoriesTableNotFound'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.background.paper,
  }
}))

const ProductCategoriesTable = () => {

  const { data, isLoading, isFetching } = useGetProductCategoriesListQuery()

  return (
    <Paper sx={{ width: '100%', mt: 2, mb: 4, p: 2, pb: 0 }}>
      <TableContainer>
        <Table aria-labelledby="product-categories-list-table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Название категории
                </Typography>
              </StyledTableCell>
              <StyledTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isFetching ? (
              <ProductCategoriesTableSkeleton/>
            ) : data && data.length > 0 ? data.map((productCategory) => (
              <ProductCategoriesTableRow
                key={`category-${productCategory.id}`}
                productCategory={productCategory}
              />
            )) : (
              <ProductCategoriesTableNotFound/>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default ProductCategoriesTable
