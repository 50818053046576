import {
  useEffect,
  useState
}                           from 'react'
import Box                  from '@mui/material/Box'
import Stack                from '@mui/material/Stack'
import LoadingButton        from '@mui/lab/LoadingButton'
import {
  useAppDispatch,
  useAppSelector
}                           from '../../../app/store/hooks'
import {
  nextPage,
  selectList,
  selectPage,
  selectSupplierId,
  setList
}                           from '../store/reviewsSlice'
import {useGetReviewsQuery} from '../services/reviewsApi'
import ReviewCard           from './ReviewCard'
import ReviewsListLoading   from './ReviewsListLoading'

const ReviewsList = () => {

  const supplierId = useAppSelector(selectSupplierId)
  const page = useAppSelector(selectPage)
  const list = useAppSelector(selectList)

  const { data, isLoading, isFetching, isError } = useGetReviewsQuery({ supplierId, page })

  const dispatch = useAppDispatch()

  const [showLoadButton, setShowLoadButton] = useState<boolean>(true)

  useEffect(() => {
    setShowLoadButton(true)
  }, [supplierId])

  useEffect(() => {
    if (data) {
      if (data.feedbacks.length > 0) {
        dispatch(setList(data.feedbacks))
      } else {
        setShowLoadButton(false)
      }
    }
  }, [data])

  const onLoadReviews = () => {
    dispatch(nextPage())
  }

  return (
    <ReviewsListLoading
      isLoading={isLoading}
      isFetching={list.length == 0 && isFetching}
      rowsCount={7}
    >
      <Stack spacing={4}>
        {list.map((review) => (
          <ReviewCard key={review.id} {...review}/>
        ))}
      </Stack>
      <Box sx={{ py: 4, textAlign: 'center' }}>
        <ReviewsListLoading
          isLoading={false}
          isFetching={list.length > 0 && isFetching}
          rowsCount={1}
        >
          {(list.length > 0 && showLoadButton) && (
            <LoadingButton
              size="large"
              variant="contained"
              loading={isFetching}
              onClick={onLoadReviews}
              sx={{ height: 56 }}
            >
              Загрузить еще
            </LoadingButton>
          )}
        </ReviewsListLoading>
      </Box>
    </ReviewsListLoading>
  )
}

export default ReviewsList