import api          from '../../../app/store/api'
import {ReviewType} from '../types'

type ReviewsResponse = {
  feedbacks: ReviewType[]
}

type ReviewsRequest = {
  supplierId: string;
  page?: number;
}

type SendAnswerRequest = {
  answer_text: string;
  supplier_id: string;
  feedback_id: string;
}

type SendAnswerResponse = {
  status: string;
  error: string;
}

export const reviewsApi = api.injectEndpoints({
  endpoints: builder => ({
    getReviews: builder.query<ReviewsResponse, ReviewsRequest>({
      query: ({ supplierId, page = 0 }) => {
        return `/api/wildberries/feedback/${supplierId}?answered=false&skip=${page * 7}&limit=7`
      },
    }),
    generatorReview: builder.mutation<string, { sku: string; name: string; }>({
      query: ({ sku, name }) => ({
        url: `/api/generator/wb/review/${sku}/${name}`,
        method: 'GET',
      })
    }),
    sendAnswer: builder.mutation<SendAnswerResponse, SendAnswerRequest>({
      query: (data) => ({
        url: '/api/wildberries/feedback/send-answer',
        method: 'PUT',
        body: data
      })
    })
  })
})

export const {
  useGetReviewsQuery,
  useGeneratorReviewMutation,
  useSendAnswerMutation
} = reviewsApi