import {styled}           from '@mui/material/styles'
import TableRow           from '@mui/material/TableRow'
import {tableCellClasses} from '@mui/material/TableCell'

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`&:hover > .${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
}))

export default StyledTableRow