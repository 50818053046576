import type {ChangeEvent}             from 'react'
import {useState}                     from 'react'
import AppBar                         from '@mui/material/AppBar'
import Toolbar                        from '@mui/material/Toolbar'
import Typography                     from '@mui/material/Typography'
import Drawer                         from '@mui/material/Drawer'
import Stack                          from '@mui/material/Stack'
import Checkbox                       from '@mui/material/Checkbox'
import FormControlLabel               from '@mui/material/FormControlLabel'
import IconButton                     from '@mui/material/IconButton'
import IconGearDuotone                from '../../../../../app/components/icons/IconGearDuotone'
import {RealisationTableHeadCellType} from '../../../types'

const RealisationTableShowColumns = ({
  columns,
  setColumns
}: {
  columns: RealisationTableHeadCellType[];
  setColumns: (newColumns: RealisationTableHeadCellType[]) => void;
}) => {

  const [open, setOpen] = useState<boolean>(false)

  const [hideColsCount, setHideColsCount] = useState<number>(0)

  const onChangeShowColumns = (key: string, checked: boolean) => {
    const cols = columns.map(column => column.key === key ? ({
      ...column,
      'show': checked
    }) : column)
    setColumns(cols)
    setHideColsCount(checked ? hideColsCount - 1 : hideColsCount + 1)
  }

  const onChangeShowAllColumns = () => {
    const cols = columns.map(column => ({ ...column, 'show': true }))
    setColumns(cols)
    setHideColsCount(0)
  }

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <IconGearDuotone/>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: (theme) => ({
            width: 380,
            maxWidth: '100%',
          })
        }}
      >
        <AppBar position="sticky" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h4" noWrap sx={{ color: 'text.secondary' }}>
              Показать столбцы
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack flexGrow={1} padding={3}>
          {columns.map(({ key, label, show }) => (
            <FormControlLabel
              key={key}
              label={`Показать столбец ${label}`}
              componentsProps={{
                typography: { noWrap: true, fontSize: 14 }
              }}
              control={(
                <Checkbox
                  name={key}
                  checked={show}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeShowColumns(key, event.target.checked)}
                />
              )}
            />
          ))}
          <FormControlLabel
            label={'Показать все столбцы'}
            componentsProps={{
              typography: { noWrap: true, fontSize: 14 }
            }}
            control={(
              <Checkbox
                checked={!hideColsCount}
                disabled={!hideColsCount}
                onChange={onChangeShowAllColumns}
              />
            )}
          />
        </Stack>
      </Drawer>
    </>
  )
}

export default RealisationTableShowColumns