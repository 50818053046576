import numeral from 'numeral'

numeral.register('locale', 'ru', {
  delimiters: {
    thousands: ' ',
    decimal: ','
  },
  abbreviations: {
    thousand: 'тыс',
    million: 'млн',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function (number) {
    return number === 1 ? '' : ''
  },
  currency: {
    symbol: 'руб'
  }
})

// switch between locales
numeral.locale('ru')

export function fCurrency (number: any) {
  return numeral(number).format('0,0 $')
}

export function fPcs (number: any) {
  return numeral(number).format('0,0') + ' шт'
}

export function fPercent (number: any) {
  return numeral(number / 100).format('0.0%')
}

export function fPercentRound (number: any) {
  return numeral(number / 100).format('0%')
}

export function fNumber (number: any) {
  return numeral(number).format()
}

export function fRoundToTenths (number: any) {
  return numeral(number).format('0.0a').replace('.00', '')
}

export function fShortenNumber (number: any) {
  return numeral(number).format('0.00a').replace('.00', '')
}

export function fData (number: any) {
  return numeral(number).format('0.0 b')
}
