import {styled}                      from '@mui/material/styles'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.background.paper,
  },
  [`&.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.grey.A100,
  }
}))

export default StyledTableCell