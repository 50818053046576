import Tabs                                  from '@mui/material/Tabs'
import Tab                                   from '@mui/material/Tab'
import Box                                   from '@mui/material/Box'
import Card                                  from '@mui/material/Card'
import useTabs                               from '../../../../app/hooks/useTabs'
import TabPanel                              from '../../../../app/components/TabPanel'
import LoadingBox                            from '../../../../app/components/LoadingBox'
import {useGetLegalEntitiesListQuery}        from '../../../legalEntities/services/legalEntitiesApi'
import type {MarketCodeType}                 from '../../../markets/types'
import type {ManufactureOrderItemsStateType} from '../../types'
import MatrixTable                           from './table/MatrixTable'

const MatrixTabPanel = ({
  mpCode,
  items,
  changeItem,
  addProduct,
  addWarehouse
}: {
  mpCode: MarketCodeType;
  items: ManufactureOrderItemsStateType;
  changeItem: (props: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
    wh_name: string;
    quantity: number;
  }) => void;
  addProduct: (props: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
  }) => void;
  addWarehouse: (props: {
    legal_entity_id: number;
    wh_name: string;
  }) => void;
}) => {

  const { currentTab, onChangeTab } = useTabs<number>(1)

  const {
    data: legalEntities,
    isLoading: isLoadingLegalEntities
  } = useGetLegalEntitiesListQuery()

  return (
    <LoadingBox isLoading={isLoadingLegalEntities}>
      {legalEntities && (
        <Card sx={{ flexGrow: 1, mb: 4 }}>
          <Box sx={(theme) => ({
            backgroundColor: theme.palette.grey.A100,
            borderBottom: '1px solid #e0e0e0'
          })}>
            <Tabs
              value={currentTab}
              onChange={onChangeTab}
              aria-label="Юридические лица"
              TabIndicatorProps={{ style: { height: '6px' } }}
              allowScrollButtonsMobile
            >
              {legalEntities.map(legalEntity => (
                <Tab
                  key={`legal-entity-${legalEntity.id}-tab`}
                  value={legalEntity.id}
                  label={legalEntity.name}
                />
              ))}
            </Tabs>
          </Box>
          {legalEntities.map(legalEntity => (
            <TabPanel
              key={`legal-entity-${legalEntity.id}-tab-panel`}
              value={currentTab}
              index={legalEntity.id}
              sx={{ p: 0 }}
            >
              <MatrixTable
                mpCode={mpCode}
                legalEntityId={legalEntity.id}
                legalEntityName={legalEntity.name}
                items={items}
                changeItem={changeItem}
                addProduct={addProduct}
                addWarehouse={addWarehouse}
              />
            </TabPanel>
          ))}
        </Card>
      )}
    </LoadingBox>
  )
}

export default MatrixTabPanel