import api from '../../../app/store/api'
import {
  CreateLegalEntityRequest,
  CreateLegalEntityResponse,
  LegalEntitiesListResponse,
  UpdateLegalEntityRequest,
  UpdateLegalEntityResponse
}          from '../types'

export const legalEntitiesApi = api.injectEndpoints({
  endpoints: builder => ({
    getLegalEntitiesList: builder.query<LegalEntitiesListResponse, void>({
      query: () => '/management/legal-entity',
    }),
    createLegalEntity: builder.mutation<CreateLegalEntityResponse, CreateLegalEntityRequest>({
      query: (legalEntity) => ({
        url: `/management/legal-entity`,
        method: 'POST',
        body: legalEntity
      })
    }),
    updateLegalEntity: builder.mutation<UpdateLegalEntityResponse, UpdateLegalEntityRequest>({
      query: (legalEntity) => ({
        url: `/management/legal-entity/${legalEntity.id}`,
        method: 'PuT',
        body: legalEntity
      })
    }),
  })
})

export const {
  useGetLegalEntitiesListQuery,
  useCreateLegalEntityMutation,
  useUpdateLegalEntityMutation
} = legalEntitiesApi