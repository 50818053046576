type StorageType = 'session' | 'local';
type UseStorageReturnValue = {
  getItem: <T> (key: string, defaultValue: T, type?: StorageType) => T;
  setItem: <T> (key: string, value: T, type?: StorageType) => boolean;
  removeItem: (key: string, type?: StorageType) => void;
};

const useStorage = (defaultType?: StorageType): UseStorageReturnValue => {

  const storageType = (type?: StorageType): 'localStorage' | 'sessionStorage' => {
    return `${type ?? defaultType ?? 'session'}Storage`
  }

  const isBrowser: boolean = ((): boolean => typeof window !== 'undefined')()

  const getItem = <T> (key: string, defaultValue: T, type?: StorageType): T => {
    if (isBrowser) {
      const item = window[storageType(type)][key]
      if (item) {
        return JSON.parse(item)
      }
    }
    return defaultValue
  }

  const setItem = <T> (key: string, value: T, type?: StorageType): boolean => {
    if (isBrowser) {
      window[storageType(type)].setItem(key, JSON.stringify(value))
      return true
    }
    return false
  }

  const removeItem = (key: string, type?: StorageType): void => {
    window[storageType(type)].removeItem(key)
  }

  return {
    getItem,
    setItem,
    removeItem,
  }
}

export default useStorage