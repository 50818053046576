import type {PropsWithChildren} from 'react'
import {useEffect}              from 'react'
import {useSnackbar}            from 'notistack'
import {
  useAppDispatch,
  useAppSelector
}                               from '../../../app/store/hooks'
import {
  removeErrorNotification,
  selectErrorNotification
}                               from '../store/notificationSlice'

const ShowNotification = ({ children }: PropsWithChildren<{}>) => {

  const error = useAppSelector(selectErrorNotification)
  const dispatch = useAppDispatch()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (error) {
      enqueueSnackbar(error, { variant: 'error' })
      dispatch(removeErrorNotification())
    }
  }, [error])

  return <>{children}</>
}

export default ShowNotification