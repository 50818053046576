import api from '../../../app/store/api'
import {
  CreateManagerRequest,
  CreateManagerResponse,
  ManagersListResponse,
  UpdateManagerRequest,
  UpdateManagerResponse
}          from '../types'

export const managersApi = api.injectEndpoints({
  endpoints: builder => ({
    getManagersList: builder.query<ManagersListResponse, void>({
      query: () => '/management/managers',
    }),
    createManager: builder.mutation<CreateManagerResponse, CreateManagerRequest>({
      query: (manager) => ({
        url: `/management/managers`,
        method: 'POST',
        body: manager
      })
    }),
    updateManager: builder.mutation<UpdateManagerResponse, UpdateManagerRequest>({
      query: (manager) => ({
        url: `/management/managers/${manager.id}`,
        method: 'PUT',
        body: manager
      })
    }),
  })
})

export const {
  useGetManagersListQuery,
  useCreateManagerMutation,
  useUpdateManagerMutation
} = managersApi