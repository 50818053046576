import type {ChangeEvent} from 'react'
import {useState}         from 'react'
import AppBar             from '@mui/material/AppBar'
import Toolbar            from '@mui/material/Toolbar'
import Typography         from '@mui/material/Typography'
import Drawer             from '@mui/material/Drawer'
import Stack              from '@mui/material/Stack'
import Checkbox           from '@mui/material/Checkbox'
import FormControlLabel   from '@mui/material/FormControlLabel'
import IconButton         from '@mui/material/IconButton'
import Box                from '@mui/material/Box'
import Tabs               from '@mui/material/Tabs'
import Tab                from '@mui/material/Tab'
import IconGearDuotone    from '../../../../../app/components/icons/IconGearDuotone'
import useTabs            from '../../../../../app/hooks/useTabs'
import TabPanel           from '../../../../../app/components/TabPanel'
import type {
  SupplyNeedsTableHeadCellType,
  SupplyNeedsTableShowColumnsType
}                         from '../../../../reports/types'

const NeedsTableShowColumns = ({
  columns,
  dates,
  showColumns,
  setShowColumns
}: {
  columns: SupplyNeedsTableHeadCellType[];
  dates: string[];
  showColumns: SupplyNeedsTableShowColumnsType;
  setShowColumns: (newColumns: SupplyNeedsTableShowColumnsType) => void;
}) => {

  const [open, setOpen] = useState<boolean>(false)

  const [showAll, setShowAll] = useState<boolean>(true)
  const [showAllDates, setShowAllDates] = useState<boolean>(true)

  const onChangeShowColumns = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'all') {
      setShowColumns({
        ...showColumns,
        ...columns.reduce((a, v) => ({ ...a, [v.key]: event.target.checked }), {})
      })
      setShowAll(event.target.checked)
    } else {
      if (!event.target.checked) {
        setShowAll(false)
      }
      setShowColumns({ ...showColumns, [event.target.name]: event.target.checked })
    }
  }

  const onChangeShowDatesColumns = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name == 'all') {
      setShowColumns(dates.reduce((a, v) => ({ ...a, [v]: event.target.checked }), showColumns))
      setShowAllDates(event.target.checked)
    } else {
      if (!event.target.checked) {
        setShowAllDates(false)
      }
      setShowColumns({ ...showColumns, [event.target.name]: event.target.checked })
    }
  }

  const { currentTab, onChangeTab } = useTabs<number>(0)

  return (
    <>
      <IconButton onClick={() => setOpen(true)}>
        <IconGearDuotone/>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          sx: (theme) => ({
            width: 380,
            maxWidth: '100%',
          })
        }}
      >
        <AppBar position="sticky" color="default" elevation={0}>
          <Toolbar>
            <Typography variant="h4" noWrap sx={{ color: 'text.secondary' }}>
              Показать столбцы
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}>
          <Tabs
            value={currentTab}
            onChange={onChangeTab}
            variant="fullWidth"
            aria-label="Показать столбцы"
            TabIndicatorProps={{ style: { height: '6px' } }}
            allowScrollButtonsMobile
          >

            <Tab label="Основные"/>
            <Tab label="Даты"/>
          </Tabs>
        </Box>
        <TabPanel value={currentTab} index={0}>
          <Stack flexGrow={1} padding={3}>
            {columns.map(({ key, label }) => (
              <FormControlLabel
                key={key}
                label={`Показать столбец ${label}`}
                componentsProps={{
                  typography: { noWrap: true, fontSize: 14 }
                }}
                control={(
                  <Checkbox
                    name={key}
                    checked={showColumns[key]}
                    onChange={onChangeShowColumns}
                  />
                )}
              />
            ))}
            <FormControlLabel
              label={showAll ? 'Скрыть все столбцы' : 'Показать все столбцы'}
              componentsProps={{
                typography: { noWrap: true, fontSize: 14 }
              }}
              control={(
                <Checkbox
                  name="all"
                  checked={showAll}
                  onChange={onChangeShowColumns}
                />
              )}
            />
          </Stack>
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <Stack flexGrow={1} padding={3}>
            {dates.map(date => (
              <FormControlLabel
                key={date}
                label={`Показать столбец ${date}`}
                componentsProps={{
                  typography: { noWrap: true, fontSize: 14 }
                }}
                control={(
                  <Checkbox
                    name={date}
                    checked={showColumns[date]}
                    onChange={onChangeShowDatesColumns}
                  />
                )}
              />
            ))}
            <FormControlLabel
              label={showAllDates ? 'Скрыть все столбцы дат' : 'Показать все столбцы дат'}
              componentsProps={{
                typography: { noWrap: true, fontSize: 14 }
              }}
              control={(
                <Checkbox
                  name="all"
                  checked={showAllDates}
                  onChange={onChangeShowDatesColumns}
                />
              )}
            />
          </Stack>
        </TabPanel>
      </Drawer>
    </>
  )
}

export default NeedsTableShowColumns