import type {TextFieldProps}          from '@mui/material/TextField/TextField'
import TextField                      from '@mui/material/TextField'
import MenuItem                       from '@mui/material/MenuItem'
import {useGetLegalEntitiesListQuery} from '../services/legalEntitiesApi'

const SelectLegalEntities = (props: TextFieldProps) => {

  const { data, isLoading } = useGetLegalEntitiesListQuery()

  return (
    <TextField
      select
      label={isLoading ? 'Загрузка...' : 'Юр. лицо'}
      {...props}
    >
      {props.children}
      {data && data.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default SelectLegalEntities