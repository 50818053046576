import {
  createSlice,
  PayloadAction
}                        from '@reduxjs/toolkit'
import type {RootState}  from '../../../app/store/types'
import {LegalEntityType} from '../types'

type LegalEntityState = {
  openDrawer: boolean;
  updateLegalEntity: LegalEntityType | null;
}

const initialState: LegalEntityState = {
  openDrawer: false,
  updateLegalEntity: null
}

const legalEntitySlice = createSlice({
  name: 'legalEntity',
  initialState,
  reducers: {
    onCloseDrawer: (state) => {
      state.openDrawer = false
      state.updateLegalEntity = null
    },
    onCreateLegalEntity: (state) => {
      state.openDrawer = true
      state.updateLegalEntity = null
    },
    setUpdateLegalEntity: (state, action: PayloadAction<LegalEntityType>) => {
      state.openDrawer = true
      state.updateLegalEntity = action.payload
    }
  },
})

export const {
  onCloseDrawer,
  onCreateLegalEntity,
  setUpdateLegalEntity,
} = legalEntitySlice.actions

export default legalEntitySlice.reducer

export const selectOpenDrawer = (state: RootState) => state.legalEntity.openDrawer
export const selectUpdateLegalEntity = (state: RootState) => state.legalEntity.updateLegalEntity
