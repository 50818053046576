import api              from '../../../app/store/api'
import {MarketCodeType} from '../../markets/types'
import {
  AddProductCategoryToProductRequest,
  CreateProductCategoryRequest,
  CreateProductCategoryResponse,
  DeleteProductCategoryRequest,
  DeleteProductCategoryToProductRequest,
  GetProductCategoriesListResponse,
  GetProductCategoryRequest,
  GetProductCategoryResponse,
  ProductsListItemType,
  ProductType,
  UpdateProductCategoryRequest,
  UpdateProductCategoryResponse
}                       from '../types'

export type GetProductsListRequest = {
  linked?: boolean;
}

export type GetProductsListResponse = {
  items: ProductsListItemType[]
}

export type GetProductRequest = {
  id: number;
}

export type GetProductResponse = ProductType

export type CreateProductRequest = {
  name: string;
  mp_products: {
    mp_code: string;
    sku?: string;
    size?: string | null;
  }[];
}

export type CreateProductResponse = ProductType

export type UpdateProductRequest = {
  id: number;
  name: string;
  mp_products: {
    mp_code: MarketCodeType;
    sku?: string;
    size?: string | null;
    net_cost?: number;
    manager_id?: number;
    legal_entity_id?: number;
    new_search_query?: string
  }[]
}

export type UpdateProductResponse = ProductType

export type DeleteProductLinkRequest = {
  id: number;
  mp_code: MarketCodeType;
}

export type DeleteProductLinkResponse = ProductType

export const productsApi = api.injectEndpoints({
  endpoints: builder => ({
    getProductsList: builder.query<GetProductsListResponse, GetProductsListRequest>({
      query: ({ linked = true }) => ({
        url: `/management/products?linked=${linked}`,
      })
    }),
    createProduct: builder.mutation<CreateProductResponse, CreateProductRequest>({
      query: (product) => ({
        url: '/management/products',
        method: 'POST',
        body: product
      })
    }),
    getProduct: builder.mutation<GetProductResponse, GetProductRequest>({
      query: ({ id }) => ({
        url: `/management/products/${id}`,
      })
    }),
    updateProduct: builder.mutation<UpdateProductResponse, UpdateProductRequest>({
      query: (product) => ({
        url: `/management/products/${product.id}`,
        method: 'PUT',
        body: product
      })
    }),
    deleteProduct: builder.mutation<DeleteProductLinkResponse, DeleteProductLinkRequest>({
      query: ({ id, mp_code }) => ({
        url: `/management/products/${id}/links/${mp_code}`,
        method: 'DELETE'
      })
    }),
    getProductCategoriesList: builder.query<GetProductCategoriesListResponse, void>({
      query: () => ({
        url: `/management/product/categories`,
      })
    }),
    createProductCategory: builder.mutation<CreateProductCategoryResponse, CreateProductCategoryRequest>({
      query: (category) => ({
        url: '/management/product/categories',
        method: 'POST',
        body: category
      })
    }),
    getProductCategory: builder.mutation<GetProductCategoryResponse, GetProductCategoryRequest>({
      query: ({ id }) => ({
        url: `/management/product/categories/${id}`,
      })
    }),
    updateProductCategory: builder.mutation<UpdateProductCategoryResponse, UpdateProductCategoryRequest>({
      query: (category) => ({
        url: `/management/product/categories/${category.id}`,
        method: 'PUT',
        body: category
      })
    }),
    deleteProductCategory: builder.mutation<void, DeleteProductCategoryRequest>({
      query: ({ id }) => ({
        url: `/management/product/categories/${id}`,
        method: 'DELETE'
      })
    }),
    addProductCategoryToProduct: builder.mutation<void, AddProductCategoryToProductRequest>({
      query: ({ category_id, product_id }) => ({
        url: `/management/product/categories/${category_id}/${product_id}`,
        method: 'POST'
      })
    }),
    deleteProductCategoryToProduct: builder.mutation<void, DeleteProductCategoryToProductRequest>({
      query: ({ category_id, product_id }) => ({
        url: `/management/product/categories/${category_id}/${product_id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetProductsListQuery,
  useGetProductMutation,
  useUpdateProductMutation,
  useCreateProductMutation,
  useDeleteProductMutation,
  useGetProductCategoriesListQuery,
  useCreateProductCategoryMutation,
  useGetProductCategoryMutation,
  useUpdateProductCategoryMutation,
  useDeleteProductCategoryMutation,
  useAddProductCategoryToProductMutation,
  useDeleteProductCategoryToProductMutation
} = productsApi