import {
  useEffect,
  useState
}                                       from 'react'
import Drawer                           from '@mui/material/Drawer'
import AppBar                           from '@mui/material/AppBar'
import Toolbar                          from '@mui/material/Toolbar'
import Typography                       from '@mui/material/Typography'
import IconButton                       from '@mui/material/IconButton'
import List                             from '@mui/material/List'
import {
  ListItem,
  ListItemButton,
  ListItemText
}                                       from '@mui/material'
import IconPlus                         from '../../../../app/components/icons/IconPlus'
import LoadingBox                       from '../../../../app/components/LoadingBox'
import type {MarketCodeType}            from '../../../markets/types'
import {useGetWarehousesListQuery}      from '../../../warehouses/services/warehousesApi'
import {ManufactureOrderItemsStateType} from '../../types'

const MatrixAddWarehouseDrawer = ({
  mpCode,
  items,
  legalEntityId,
  addWarehouse,
  disabled
}: {
  mpCode: MarketCodeType;
  legalEntityId: number;
  items: ManufactureOrderItemsStateType;
  addWarehouse: (props: {
    legal_entity_id: number;
    wh_name: string;
  }) => void;
  disabled: boolean;
}) => {

  const [open, setOpen] = useState<boolean>(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { data, isLoading, isFetching } = useGetWarehousesListQuery({ mp_code: mpCode })

  const [warehouses, setWarehouses] = useState<string[]>([])

  useEffect(() => {
    let whs = []
    if (items[legalEntityId] !== undefined) {
      for (const productId in items[legalEntityId]) {
        const quantity = items[legalEntityId][productId]['quantity']
        for (const whName in quantity) {
          whs.push(whName)
        }
      }
      setWarehouses(whs)
    }
  }, [items])

  return (
    <>
      <IconButton
        onClick={onOpen}
        disabled={disabled}
      >
        <IconPlus/>
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
      >
        <AppBar
          position="sticky"
          elevation={0}
          sx={{ py: 1 }}
        >
          <Toolbar>
            <Typography variant="h5">
              Добавить склад
            </Typography>
          </Toolbar>
        </AppBar>
        <LoadingBox isLoading={isLoading || isFetching}>
          <List>
            {data && data.map(wh => !warehouses.includes(wh.name) && (
              <ListItem key={wh.id} disablePadding>
                <ListItemButton
                  onClick={() => addWarehouse({
                    legal_entity_id: legalEntityId,
                    wh_name: wh.name
                  })}
                >
                  <ListItemText primary={wh.name}/>
                  <IconPlus/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </LoadingBox>
      </Drawer>
    </>
  )
}

export default MatrixAddWarehouseDrawer