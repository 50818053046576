import {
  PERMISSION_LEGAL_ENTITIES_LIST,
  PERMISSION_LEGAL_ENTITY_CREATE,
  PERMISSION_LEGAL_ENTITY_UPDATE,
  PERMISSION_MANAGER_CREATE,
  PERMISSION_MANAGER_UPDATE,
  PERMISSION_MANAGERS_LIST,
  PERMISSION_MANUFACTURE_ORDER_CREATE,
  PERMISSION_MANUFACTURE_ORDER_UPDATE,
  PERMISSION_MANUFACTURE_ORDERS_LIST,
  PERMISSION_MANUFACTURE_STATUS_CREATE,
  PERMISSION_MANUFACTURE_STATUS_DELETE,
  PERMISSION_MANUFACTURE_STATUS_UPDATE,
  PERMISSION_MANUFACTURE_STATUSES_LIST,
  PERMISSION_MARKET_CREATE,
  PERMISSION_MARKET_UPDATE,
  PERMISSION_MARKETS_LIST,
  PERMISSION_PRODUCT_CATEGORIES_LIST,
  PERMISSION_PRODUCT_CATEGORY_CREATE,
  PERMISSION_PRODUCT_CATEGORY_DELETE,
  PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_CREATE,
  PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_DELETE,
  PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_UPDATE,
  PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST,
  PERMISSION_PRODUCT_CATEGORY_UPDATE,
  PERMISSION_PRODUCTS_CREATE,
  PERMISSION_PRODUCTS_LIST,
  PERMISSION_PRODUCTS_UPDATE,
  PERMISSION_REPORTS_ABC_ANALYSIS_VIEW,
  PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
  PERMISSION_REPORTS_REALISATION_VIEW,
  PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW,
  PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW,
  PERMISSION_REVIEWS_VIEW,
  PERMISSION_USER_CREATE,
  PERMISSION_USER_DELETE,
  PERMISSION_USER_UPDATE,
  PERMISSION_USERS_LIST,
  PERMISSION_WAREHOUSE_CREATE,
  PERMISSION_WAREHOUSE_DELETE,
  PERMISSION_WAREHOUSE_UPDATE,
  PERMISSION_WAREHOUSES_LIST
} from './permissions'

const roles = {
  feedbacker: [
    PERMISSION_REVIEWS_VIEW,
  ],
  viewer: [
    PERMISSION_PRODUCTS_LIST,
    PERMISSION_PRODUCT_CATEGORIES_LIST,
    PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
    PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW,
    PERMISSION_REPORTS_ABC_ANALYSIS_VIEW
  ],
  manager: [
    PERMISSION_REVIEWS_VIEW,
    PERMISSION_PRODUCTS_LIST,
    PERMISSION_PRODUCTS_UPDATE,
    PERMISSION_PRODUCTS_CREATE,
    PERMISSION_PRODUCT_CATEGORIES_LIST,
    PERMISSION_PRODUCT_CATEGORY_UPDATE,
    PERMISSION_PRODUCT_CATEGORY_CREATE,
    PERMISSION_PRODUCT_CATEGORY_DELETE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_UPDATE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_CREATE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_DELETE,
    PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW,
    PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
    PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW,
    PERMISSION_REPORTS_ABC_ANALYSIS_VIEW,
    PERMISSION_WAREHOUSES_LIST,
    PERMISSION_WAREHOUSE_CREATE,
    PERMISSION_WAREHOUSE_UPDATE,
    PERMISSION_WAREHOUSE_DELETE,
    PERMISSION_MANUFACTURE_STATUSES_LIST,
    PERMISSION_MANUFACTURE_STATUS_CREATE,
    PERMISSION_MANUFACTURE_STATUS_UPDATE,
    PERMISSION_MANUFACTURE_STATUS_DELETE,
    PERMISSION_MANUFACTURE_ORDERS_LIST,
    PERMISSION_MANUFACTURE_ORDER_CREATE,
    PERMISSION_MANUFACTURE_ORDER_UPDATE,
    PERMISSION_MANUFACTURE_STATUS_DELETE
  ],
  owner: [
    PERMISSION_REVIEWS_VIEW,
    PERMISSION_PRODUCTS_LIST,
    PERMISSION_PRODUCTS_UPDATE,
    PERMISSION_PRODUCTS_CREATE,
    PERMISSION_PRODUCT_CATEGORIES_LIST,
    PERMISSION_PRODUCT_CATEGORY_UPDATE,
    PERMISSION_PRODUCT_CATEGORY_CREATE,
    PERMISSION_PRODUCT_CATEGORY_DELETE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_UPDATE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_CREATE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_DELETE,
    PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW,
    PERMISSION_REPORTS_REALISATION_VIEW,
    PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
    PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW,
    PERMISSION_REPORTS_ABC_ANALYSIS_VIEW,
    PERMISSION_WAREHOUSES_LIST,
    PERMISSION_WAREHOUSE_CREATE,
    PERMISSION_WAREHOUSE_UPDATE,
    PERMISSION_WAREHOUSE_DELETE,
    PERMISSION_MANUFACTURE_STATUSES_LIST,
    PERMISSION_MANUFACTURE_STATUS_CREATE,
    PERMISSION_MANUFACTURE_STATUS_UPDATE,
    PERMISSION_MANUFACTURE_STATUS_DELETE,
    PERMISSION_MANUFACTURE_ORDERS_LIST,
    PERMISSION_MANUFACTURE_ORDER_CREATE,
    PERMISSION_MANUFACTURE_ORDER_UPDATE,
    PERMISSION_MANUFACTURE_STATUS_DELETE
  ],
  admin: [
    PERMISSION_REVIEWS_VIEW,
    PERMISSION_PRODUCTS_LIST,
    PERMISSION_PRODUCTS_UPDATE,
    PERMISSION_PRODUCTS_CREATE,
    PERMISSION_PRODUCT_CATEGORIES_LIST,
    PERMISSION_PRODUCT_CATEGORY_UPDATE,
    PERMISSION_PRODUCT_CATEGORY_CREATE,
    PERMISSION_PRODUCT_CATEGORY_DELETE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_UPDATE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_CREATE,
    PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_DELETE,
    PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW,
    PERMISSION_REPORTS_REALISATION_VIEW,
    PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
    PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW,
    PERMISSION_REPORTS_ABC_ANALYSIS_VIEW,
    PERMISSION_LEGAL_ENTITIES_LIST,
    PERMISSION_LEGAL_ENTITY_CREATE,
    PERMISSION_LEGAL_ENTITY_UPDATE,
    PERMISSION_MARKETS_LIST,
    PERMISSION_MARKET_CREATE,
    PERMISSION_MARKET_UPDATE,
    PERMISSION_MANAGERS_LIST,
    PERMISSION_MANAGER_CREATE,
    PERMISSION_MANAGER_UPDATE,
    PERMISSION_USERS_LIST,
    PERMISSION_USER_CREATE,
    PERMISSION_USER_UPDATE,
    PERMISSION_USER_DELETE,
    PERMISSION_WAREHOUSES_LIST,
    PERMISSION_WAREHOUSE_CREATE,
    PERMISSION_WAREHOUSE_UPDATE,
    PERMISSION_WAREHOUSE_DELETE,
    PERMISSION_MANUFACTURE_STATUSES_LIST,
    PERMISSION_MANUFACTURE_STATUS_CREATE,
    PERMISSION_MANUFACTURE_STATUS_UPDATE,
    PERMISSION_MANUFACTURE_STATUS_DELETE,
    PERMISSION_MANUFACTURE_ORDERS_LIST,
    PERMISSION_MANUFACTURE_ORDER_CREATE,
    PERMISSION_MANUFACTURE_ORDER_UPDATE,
    PERMISSION_MANUFACTURE_STATUS_DELETE
  ]
}

export default roles