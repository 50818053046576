import {Navigate}         from 'react-router-dom'
import useIsFeedbacksUser from '../hooks/useIsFeedbacksUser'

const Home = () => {

  const isFeedbacks = useIsFeedbacksUser()

  return <Navigate to={isFeedbacks ? 'reviews' : 'products'}/>
}

export default Home