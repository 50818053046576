import {
  useEffect,
  useState
}                               from 'react'
import IconButton               from '@mui/material/IconButton'
import ConfirmDialog            from '../../../../app/components/dialog/ConfirmDialog'
import {PERMISSION_USER_DELETE} from '../../../../app/contants/permissions'
import IconDelete               from '../../../../app/components/icons/IconDelete'
import useAllow                 from '../../../auth/hooks/useAllow'
import {
  useDeleteUserMutation,
  useGetUsersListQuery
}                               from '../../services/usersApi'

const DeleteUserButton = ({ id }: { id: number }) => {

  const allowDelete = useAllow([PERMISSION_USER_DELETE])

  const [deleteUser, {
    isLoading,
    isSuccess,
    isError
  }] = useDeleteUserMutation()

  const { refetch } = useGetUsersListQuery()

  useEffect(() => {
    if (isSuccess) {
      refetch()
    }
  }, [isSuccess])

  const [open, setOpen] = useState<boolean>(false)

  const onConfirmDelete = () => {
    deleteUser({ id })
  }

  return allowDelete ? (
    <>
      <IconButton color="error" onClick={() => setOpen(true)}>
        <IconDelete/>
      </IconButton>
      <ConfirmDialog
        title="Вы уверены, что хотите удалить?"
        Icon={IconDelete}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  ) : <></>
}

export default DeleteUserButton