import {
  useEffect,
  useState
}                                             from 'react'
import {useParams}                            from 'react-router-dom'
import Box                                    from '@mui/material/Box'
import Typography                             from '@mui/material/Typography'
import Stack                                  from '@mui/material/Stack'
import LoadingBox                             from '../../../app/components/LoadingBox'
import {PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW} from '../../../app/contants/permissions'
import useLocalStorage                        from '../../../app/hooks/useLocalStorage'
import type {MarketCodeType}                  from '../../markets/types'
import AllowGuard                             from '../../auth/guards/AllowGuard'
import {useGetSupplyNeedsQuery}               from '../services/reportsApi'
import type {
  SupplyNeedsRowType,
  SupplyNeedsTableFiltersType,
  WhsType
}                                             from '../types'
import SupplyNeedsTable                       from '../components/supply-needs/table/SupplyNeedsTable'
import SupplyNeedsFiltersDrawer               from '../components/supply-needs/SupplyNeedsFiltersDrawer'

const SupplyNeeds = () => {

  const { mpCode } = useParams<{ mpCode: MarketCodeType }>()

  const {
    data,
    isLoading,
    isFetching,
    isSuccess
  } = useGetSupplyNeedsQuery({ mp_code: mpCode ?? 'wb' })

  const [whsOptions, setWhsOptions] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (data) {
      setLoading(true)
      let newWhsOptions: string[] = []
      data.rows.forEach(({ whs }) => {
        newWhsOptions = [...newWhsOptions, ...Object.keys(whs)]
      })
      setWhsOptions(Array.from(new Set(newWhsOptions)))
    }
  }, [data])

  const [filters, setFilters] = useLocalStorage<SupplyNeedsTableFiltersType>(
    'SUPPLY_NEEDS_TABLE_FILTERS',
    {
      legal_entity_id: 0,
      manager_id: 0,
      categories: [],
      archive: false,
      whs: [],
      days: 60,
      showNeeds: 'all'
    },
    true
  )

  useEffect(() => {
    if (data) {
      let dataRows = []
      if (filters.categories.slice(-1)[0] === 0) {
        dataRows = data.rows.filter((row) => (
          row.categories.length == 0 && (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (filters.manager_id == 0 && row.manager_id !== null) ||
            (filters.manager_id == 'none' && row.manager_id == null) ||
            filters.manager_id == row.manager_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      } else if (filters.categories.length > 0) {
        dataRows = data.rows.filter((row) => {
          if (
            (
              filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
            ) && (
              (filters.manager_id == 0 && row.manager_id !== null) ||
              (filters.manager_id == 'none' && row.manager_id == null) ||
              filters.manager_id == row.manager_id
            ) && (
              (!filters.archive && !row.archive) || filters.archive
            )
          ) {
            for (let cat of row.categories) {
              if (filters.categories.includes(cat.id)) {
                return true
              }
            }
          }
          return false
        })
      } else {
        dataRows = data.rows.filter((row) => (
          (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (filters.manager_id == 0 && row.manager_id !== null) ||
            (filters.manager_id == 'none' && row.manager_id == null) ||
            filters.manager_id == row.manager_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      }
      if (filters.whs.length > 0) {
        dataRows = dataRows.map((row) => {
          let newWhs: WhsType = {}
          Object.entries(row.whs).forEach(([key, value]) => {
            if (filters.whs.includes(key)) {
              newWhs[key] = value
            }
          })
          return { ...row, whs: newWhs }
        })
      }
      setRows(dataRows)
      setLoading(false)
    }
  }, [whsOptions, filters])

  const [rows, setRows] = useState<SupplyNeedsRowType[]>([])



  return (
    <AllowGuard permissions={[PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW]}>
      <Stack direction="row" spacing={2} alignItems="center" paddingTop={2}>
        <Typography color="text.secondary" variant="h4" noWrap>
          Потребности {mpCode == 'wb' ? 'Wildberries' : mpCode == 'oz' ? 'Ozon' : ''}
        </Typography>
        <Box flexGrow={1}/>
        <SupplyNeedsFiltersDrawer
          filters={filters}
          setFilters={setFilters}
          whsOptions={whsOptions}
          disabled={isLoading}
        />
      </Stack>
      <Box paddingTop={4}>
        <LoadingBox
          isLoading={isLoading || isFetching || loading}
          text={!data ? 'Загрузка данных...' : 'Форматирование данных...'}
        >
          {data && rows.length > 0 && (
            <SupplyNeedsTable
              rows={rows}
              dateFrom={data.date_from}
              dateTo={data.date_to}
              days={filters.days}
              showNeeds={filters.showNeeds}
            />
          )}
        </LoadingBox>
      </Box>
    </AllowGuard>
  )
}

export default SupplyNeeds