import {
  useEffect,
  useState
}                                  from 'react'
import Box                         from '@mui/material/Box'
import Stack                       from '@mui/material/Stack'
import {useAppSelector}            from '../../../../app/store/hooks'
import {useGetProductsListQuery}   from '../../services/productsApi'
import {
  selectArchived,
  selectCategories,
  selectLinked,
  selectNoCategory
}                                  from '../../store/productsSlice'
import type {ProductsListItemType} from '../../types'
import ProductCard                 from './ProductCard'
import ProductsListLoading         from './ProductsListLoading'

const ProductsList = () => {

  const linked = useAppSelector(selectLinked)
  const archived = useAppSelector(selectArchived)

  const { data, isLoading, isFetching } = useGetProductsListQuery({ linked })

  const categories = useAppSelector(selectCategories)
  const noCategory = useAppSelector(selectNoCategory)

  const [items, setItems] = useState<ProductsListItemType[]>([])

  useEffect(() => {
    if (data) {
      let productItems: ProductsListItemType[] = []
      if (noCategory) {
        productItems = data.items.filter((item) => item.categories.length == 0)
      } else if (categories.length > 0) {
        productItems = data.items.filter((item) => {
          for (let cat of item.categories) {
            if (categories.includes(cat.id)) {
              return true
            }
          }
          return false
        })
      } else {
        productItems = data.items
      }
      if (archived) {
        productItems = productItems.map(item => {
          const mpProducts = item.mp_products.filter(p => p.archive)
          return { ...item, mp_products: mpProducts }
        }).filter(item => item.mp_products.length > 0)
      }
      setItems(productItems)
    }
  }, [categories, data, archived])

  return (
    <Box paddingTop={4}>
      <ProductsListLoading
        isLoading={isLoading}
        isFetching={isFetching}
        rowsCount={items.length > 0 ? items.length : 4}
      >
        <Stack spacing={4}>
          {items.map((item, key) => (
            <ProductCard key={key} {...item}/>
          ))}
        </Stack>
      </ProductsListLoading>
    </Box>
  )
}

export default ProductsList