import {
  createSlice,
  PayloadAction
}                       from '@reduxjs/toolkit'
import type {RootState} from '../../../app/store/types'
import {MarketType}     from '../types'

type MarketState = {
  openDrawer: boolean;
  updateMarket: MarketType | null;
}

const initialState: MarketState = {
  openDrawer: false,
  updateMarket: null
}

const marketSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
    onCloseDrawer: (state) => {
      state.openDrawer = false
      state.updateMarket = null
    },
    onCreateMarket: (state) => {
      state.openDrawer = true
      state.updateMarket = null
    },
    setUpdateMarket: (state, action: PayloadAction<MarketType>) => {
      state.openDrawer = true
      state.updateMarket = action.payload
    }
  },
})

export const {
  onCloseDrawer,
  onCreateMarket,
  setUpdateMarket,
} = marketSlice.actions

export default marketSlice.reducer

export const selectOpenDrawer = (state: RootState) => state.market.openDrawer
export const selectUpdateMarket = (state: RootState) => state.market.updateMarket
