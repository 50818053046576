import {
  useEffect,
  useState
}                                            from 'react'
import {useParams}                           from 'react-router-dom'
import Typography                            from '@mui/material/Typography'
import Stack                                 from '@mui/material/Stack'
import Alert                                 from '@mui/material/Alert'
import Button                                from '@mui/material/Button'
import TextField                             from '@mui/material/TextField'
import {DatePicker}                          from '@mui/x-date-pickers/DatePicker'
import Box                                   from '@mui/material/Box'
import IconFilters                           from '../../../app/components/icons/IconFilters'
import LoadingBox                            from '../../../app/components/LoadingBox'
import {PERMISSION_REPORTS_REALISATION_VIEW} from '../../../app/contants/permissions'
import useLocalStorage                       from '../../../app/hooks/useLocalStorage'
import type {MarketCodeType}                 from '../../markets/types'
import AllowGuard                            from '../../auth/guards/AllowGuard'
import type {
  AbcAnalysisTableFiltersType,
  RealisationRowForAbcType
}                                            from '../types'
import {useGetRealisationForAbcMutation}     from '../services/reportsApi'
import AbcAnalysisTable                      from '../components/abc-analysis/table/AbcAnalysisTable'
import AbcAnalysisFiltersDrawer              from '../components/abc-analysis/AbcAnalysisFiltersDrawer'

const AbcAnalysis = () => {

  const { mpCode } = useParams<{ mpCode: MarketCodeType }>()

  const [filters, setFilters] = useLocalStorage<AbcAnalysisTableFiltersType>(
    'ABC_ANALYSIS_',
    {
      legal_entity_id: 0,
      categories: [],
      archive: false
    },
    true
  )

  const [dateFrom, setDateFrom, saveDateFrom] = useLocalStorage<string | null>(
    'ABC_ANALYSIS_DATE_FROM',
    null
  )

  const [dateTo, setDateTo, saveDateTo] = useLocalStorage<string | null>(
    'ABC_ANALYSIS_DATE_TO',
    null
  )

  const [getRealisation, {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useGetRealisationForAbcMutation()

  const [rows, setRows] = useState<RealisationRowForAbcType[]>([])

  useEffect(() => {
    if (data) {
      let dataRows = []
      if (filters.categories.slice(-1)[0] == 0) {
        dataRows = data.filter((row) => (
          row.categories.length == 0 && (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      } else if (filters.categories.length > 0) {
        dataRows = data.filter((row) => {
          if (
            (
              filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
            ) && (
              (!filters.archive && !row.archive) || filters.archive
            )
          ) {
            for (let cat of row.categories) {
              if (filters.categories.includes(cat.id)) {
                return true
              }
            }
          }
          return false
        })
      } else {
        dataRows = filters.legal_entity_id == 0 ? data.map(i => i) : data.filter(row => (
          (
            row.legal_entity_id == filters.legal_entity_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      }
      setRows(dataRows)
    }
  }, [data, filters])

  const onGenerateReport = () => {
    if (mpCode && dateFrom && dateTo) {
      getRealisation({
        mp_code: mpCode,
        date_from: dateFrom,
        date_to: dateTo
      })
      saveDateFrom()
      saveDateTo()
    }
  }

  return (
    <AllowGuard permissions={[PERMISSION_REPORTS_REALISATION_VIEW]}>
      <Stack direction="row" spacing={2} alignItems="center" paddingTop={2}>
        <Typography color="text.secondary" variant="h4" noWrap>
          ABC анализ Wildberries
        </Typography>
        <Box flexGrow={1}/>
        <Stack direction="row" spacing={1}>
          <DatePicker
            label="Дата от"
            inputFormat="dd/MM/yyyy"
            value={dateFrom}
            onChange={(newValue) => setDateFrom(newValue)}
            renderInput={(params) => (
              <TextField {...params} name="date_from" size="small"/>
            )}
          />
          <DatePicker
            label="Дата до"
            inputFormat="dd/MM/yyyy"
            value={dateTo}
            onChange={(newValue) => setDateTo(newValue)}
            renderInput={(params) => (
              <TextField {...params} name="date_to" size="small"/>
            )}
          />
          <Button
            variant="contained"
            onClick={onGenerateReport}
            disabled={(!mpCode || !dateFrom || !dateTo)}
          >
            <Typography variant="inherit" noWrap>
              Сформировать отчет
            </Typography>
          </Button>
        </Stack>
        <AbcAnalysisFiltersDrawer
          filters={filters}
          setFilters={setFilters}
          disabled={isLoading}
        />
      </Stack>
      <Stack direction="column" flexGrow={1} paddingTop={4}>
        <LoadingBox isLoading={isLoading}>
          {rows.length > 0 ? (
            <AbcAnalysisTable rows={rows}/>
          ) : (
            <Alert
              variant="filled"
              icon={<IconFilters sx={{ fontSize: 56 }}/>}
              sx={{ backgroundColor: 'primary.main' }}
            >
              <Typography variant="h6" fontWeight={900}>Выберите «диапазон дат»</Typography>
              Чтобы отобразить ABC анализ, выберите <strong>«диапазон дат»</strong>
              и нажмите <strong>«Сформировать отчет»</strong>.
            </Alert>
          )}
        </LoadingBox>
      </Stack>
    </AllowGuard>
  )
}

export default AbcAnalysis