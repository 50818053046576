import type {ChangeEvent} from 'react'
import FormControlLabel   from '@mui/material/FormControlLabel'
import Checkbox           from '@mui/material/Checkbox'
import Typography         from '@mui/material/Typography'
import {
  useAppDispatch,
  useAppSelector
}                         from '../../../../app/store/hooks'
import {
  selectLinked,
  setLinked
}                         from '../../store/productsSlice'

const CheckboxLinked = () => {

  const linked = useAppSelector(selectLinked)
  const dispatch = useAppDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setLinked(!e.target.checked))
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={!linked} onChange={onChange}/>}
      label={<Typography noWrap>Товары без связей</Typography>}
    />
  )
}

export default CheckboxLinked