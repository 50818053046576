import {
  ChangeEvent,
  useEffect,
  useState
}                               from 'react'
import {useSelector}            from 'react-redux'
import AppBar                   from '@mui/material/AppBar'
import Toolbar                  from '@mui/material/Toolbar'
import Typography               from '@mui/material/Typography'
import Stack                    from '@mui/material/Stack'
import Button                   from '@mui/material/Button'
import LoadingButton            from '@mui/lab/LoadingButton'
import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField
}                               from '@mui/material'
import Box                      from '@mui/material/Box'
import Tabs                     from '@mui/material/Tabs'
import Tab                      from '@mui/material/Tab'
import Avatar                   from '@mui/material/Avatar'
import LoadingBox               from '../../../../app/components/LoadingBox'
import {useAppDispatch}         from '../../../../app/store/hooks'
import useTabs                  from '../../../../app/hooks/useTabs'
import TabPanel                 from '../../../../app/components/TabPanel'
import type {
  MarketCodeType,
  MarketType
}                               from '../../../markets/types'
import {useGetMarketsListQuery} from '../../../markets/services/marketsApi'
import SelectLegalEntities      from '../../../legalEntities/components/SelectLegalEntities'
import SelectManagers           from '../../../managers/components/SelectManagers'
import AddSearchPhrase          from '../../../searchPhrases/components/AddSearchPhrase'
import {setUpdateProduct}       from '../../store/productSlice'
import {selectLinked}           from '../../store/productsSlice'
import {
  UpdateProductRequest,
  useGetProductsListQuery,
  useUpdateProductMutation
}                               from '../../services/productsApi'
import type {ProductType}       from '../../types'
import AddProductCategory       from '../categories/AddProductCategory'
import SearchQueriesTable       from './SearchQueriesTable'
import OrdersAndSalesTable      from './OrdersAndSalesTable'
import DeleteProductLinkButton  from './DeleteProductLinkButton'

const allProps = (index: MarketCodeType, label: string) => ({
  'id': `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
  'icon': <Avatar src={`/img/mp/${index}.png`} variant="square" sx={{ height: 24, width: 24 }}/>,
  'label': <Typography noWrap>{label}</Typography>,
  'value': index
})

const UpdateProduct = ({
  product,
  onClose,
}: {
  product: ProductType;
  onClose: () => void;
}) => {

  const [updateProduct, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useUpdateProductMutation()

  const linked = useSelector(selectLinked)

  const { refetch } = useGetProductsListQuery({ linked })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateProduct(data))
      refetch()
    }
  }, [isSuccess, data])

  const { data: markets, isLoading: isLoadingMarkets } = useGetMarketsListQuery()

  const [emptyMarkets, setEmptyMarkets] = useState<MarketType[]>([])

  useEffect(() => {
    if (product && markets) {
      const mp_codes = product.mp_products.map(({ mp_code }) => mp_code)
      setEmptyMarkets(markets.filter(({ code }) => !mp_codes.includes(code)))
    }
  }, [markets, product])

  const [formData, setFormData] = useState<UpdateProductRequest>({
    id: product.id,
    name: product.name,
    mp_products: product.mp_products.map((item) => ({
      mp_code: item.mp_code,
      sku: item.sku,
      size: item.size,
      net_cost: item.net_cost,
      manager_id: Number(item.manager_id),
      legal_entity_id: item.legal_entity_id
    }))
  })

  useEffect(() => {
    setFormData({
      id: product.id,
      name: product.name,
      mp_products: product.mp_products.map((item) => ({
        mp_code: item.mp_code,
        sku: item.sku,
        size: item.size,
        net_cost: item.net_cost,
        manager_id: Number(item.manager_id),
        legal_entity_id: item.legal_entity_id
      }))
    })
  }, [product])

  const { currentTab, onChangeTab } = useTabs<MarketCodeType>('wb')

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onChangeMpProducts = (code: MarketCodeType, name: string, value: string | number | boolean) => {
    // @ts-ignore
    let mpProduct: any = formData.mp_products.filter(({ mp_code }) => mp_code == code)[0]
    if (mpProduct) {
      const other = formData.mp_products.filter(({ mp_code }) => mp_code != code)
      setFormData({
        ...formData, mp_products: [...other, {
          ...mpProduct,
          [name]: value
        }]
      })
    } else {
      setFormData({
        ...formData, mp_products: [...formData.mp_products, {
          mp_code: code,
          [name]: value
        }]
      })
    }
  }

  const onSave = () => {
    updateProduct(formData)
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">Посмотреть и обновить товар</Typography>
        </Toolbar>
      </AppBar>
      <LoadingBox isLoading={isLoadingMarkets}>
        <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            label="Название товара"
            onChange={onChange}
            defaultValue={product.name}
            disabled={isLoading}
          />
          <AddProductCategory product={product} categories={product.categories}/>
          <Card sx={{ flexGrow: 1 }}>
            <Box sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}>
              <Tabs
                value={currentTab}
                onChange={onChangeTab}
                variant="fullWidth"
                aria-label="Вкладки маркетплейсов"
                TabIndicatorProps={{ style: { height: '6px' } }}
                allowScrollButtonsMobile
              >
                {markets && markets.map(({ id, code, name }) => (
                  <Tab key={id} {...allProps(code, name)} />
                ))}
              </Tabs>
            </Box>
            <CardContent>
              {product.mp_products.map((item) => (
                <TabPanel key={item.mp_code} value={currentTab} index={item.mp_code}>
                  <Stack spacing={4}>
                    <Stack direction="row" spacing={3}>
                      <Stack spacing={3}>
                        <Stack direction="row" spacing={2}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            name="sku"
                            label="SKU"
                            sx={{ width: 240 }}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(item.mp_code, e.target.name, e.target.value)}
                            defaultValue={item.sku}
                            disabled={isLoading}
                          />
                          {item.mp_code == 'wb' && (
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              name="size"
                              label="Размер"
                              sx={{ width: 140 }}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(item.mp_code, e.target.name, e.target.value)}
                              defaultValue={item.size}
                              disabled={isLoading}
                            />
                          )}
                        </Stack>
                        <Typography color="text.secondary">
                          Остаток: {item.stock}
                        </Typography>
                        <OrdersAndSalesTable
                          title="Заказов:"
                          {...item.orders}
                        />
                        <OrdersAndSalesTable
                          title="Оплат:"
                          {...item.sales}
                        />
                      </Stack>
                      <Box flexGrow={1}/>
                      <Stack spacing={3}>
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="net_cost"
                          label="Себестоимость"
                          InputProps={{
                            type: 'number',
                            endAdornment: <InputAdornment position="end">руб</InputAdornment>
                          }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(item.mp_code, e.target.name, Number(e.target.value))}
                          defaultValue={item.net_cost}
                          disabled={isLoading}
                        />
                        <SelectManagers
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="manager_id"
                          type="number"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(item.mp_code, e.target.name, Number(e.target.value))}
                          defaultValue={item.manager_id}
                          disabled={isLoading}
                        />
                        <SelectLegalEntities
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="legal_entity_id"
                          type="number"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(item.mp_code, e.target.name, Number(e.target.value))}
                          defaultValue={item.legal_entity_id}
                          disabled={isLoading}
                        />
                        <FormControlLabel
                          control={(
                            <Checkbox
                              name="archive"
                              defaultChecked={item.archive}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(item.mp_code, e.target.name, e.target.checked)}
                            />
                          )}
                          label="Архивный товар"
                        />
                        <DeleteProductLinkButton id={product.id} mp_code={item.mp_code}/>
                      </Stack>
                    </Stack>
                    <SearchQueriesTable
                      dates={item.search_queries?.dates}
                      queries={item.search_queries?.queries}
                      mp_code={item.mp_code}
                      sku={item.sku}
                      product={product}
                    />
                    <AddSearchPhrase mp_code={item.mp_code} sku={item.sku} product={product}/>
                  </Stack>
                </TabPanel>
              ))}
              {emptyMarkets.map(({ code }) => (
                <TabPanel key={code} value={currentTab} index={code}>
                  <Stack direction="row" spacing={3}>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="sku"
                        label="SKU"
                        sx={{ width: 240 }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(code, e.target.name, e.target.value)}
                        disabled={isLoading}
                      />
                      {code == 'wb' && (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="size"
                          label="Размер"
                          sx={{ width: 140 }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(code, e.target.name, e.target.value)}
                          disabled={isLoading}
                        />
                      )}
                    </Stack>
                    <Box flexGrow={1}/>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          name="archive"
                          defaultChecked={false}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(code, e.target.name, e.target.checked)}
                        />
                      )}
                      label="Отметить как архивные"
                    />
                  </Stack>
                </TabPanel>
              ))}
            </CardContent>
          </Card>
        </Stack>
      </LoadingBox>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default UpdateProduct