import IconButton                 from '@mui/material/IconButton'
import Link                       from '@mui/material/Link'
import TableRow                   from '@mui/material/TableRow'
import Typography                 from '@mui/material/Typography'
import Chip                       from '@mui/material/Chip'
import IconPenToSquare            from '../../../../app/components/icons/IconPenToSquare'
import {PERMISSION_MARKET_UPDATE} from '../../../../app/contants/permissions'
import {useAppDispatch}           from '../../../../app/store/hooks'
import useAllow                   from '../../../auth/hooks/useAllow'
import type {LegalEntityType}     from '../../types'
import {setUpdateLegalEntity}     from '../../store/legalEntitySlice'
import {StyledTableCell}          from './LegalEntitiesTable'

const LegalEntitiesTableRow = ({
  legalEntity
}: {
  legalEntity: LegalEntityType
}) => {

  const allowUpdate = useAllow([PERMISSION_MARKET_UPDATE])

  const dispatch = useAppDispatch()

  const onUpdate = () => {
    dispatch(setUpdateLegalEntity(legalEntity))
  }

  return (
    <TableRow hover tabIndex={-1}>
      <StyledTableCell>
        {allowUpdate ? (
          <Link onClick={onUpdate} underline="hover">
            {legalEntity.name}
          </Link>
        ) : (
          <Typography variant="inherit">
            {legalEntity.name}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell>
        {legalEntity.active ? (
          <Chip color="success" label="активен" size="small"/>
        ) : (
          <Chip color="error" label="неактивен" size="small"/>
        )}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="inherit" noWrap minWidth="100%" width={100}>
          {legalEntity.wb_standard_token}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="inherit" noWrap minWidth="100%" width={100}>
          {legalEntity.wb_advertising_token}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="inherit" noWrap minWidth="100%" width={100}>
          {legalEntity.wb_statistics_token}
        </Typography>
      </StyledTableCell>
      <StyledTableCell>
        {legalEntity.ozon_client_id}
      </StyledTableCell>
      <StyledTableCell>
        {legalEntity.ym_business_id}
      </StyledTableCell>
      <StyledTableCell>
        {legalEntity.ym_campaign_id}
      </StyledTableCell>
      <StyledTableCell>
        {legalEntity.ym_oauth_client_id}
      </StyledTableCell>
      <StyledTableCell>
        {legalEntity.ym_oauth_token}
      </StyledTableCell>
      <StyledTableCell>
        <Typography variant="inherit" noWrap minWidth="100%" width={100}>
          {legalEntity.ozon_api_key}
        </Typography>
      </StyledTableCell>
      <StyledTableCell align="right" padding="none">
        {allowUpdate && (
          <IconButton color="primary" onClick={onUpdate}>
            <IconPenToSquare/>
          </IconButton>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

export default LegalEntitiesTableRow