import api from '../../../app/store/api'

type AuthResponse = {
  access_token: string;
  token_type: string;
}

export type AuthRequest = {
  username: string;
  password: string;
}

export const authApi = api.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation<AuthResponse, AuthRequest>({
      query: ({ username, password }) => ({
        url: '/token',
        method: 'POST',
        body: `username=${username}&password=${password}`,
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      })
    })
  })
})

export const { useLoginMutation } = authApi
