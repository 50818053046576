import {ReactNode}  from 'react'
import CardHeader   from '@mui/material/CardHeader'
import Checkbox     from '@mui/material/Checkbox'
import Divider      from '@mui/material/Divider'
import List         from '@mui/material/List'
import ListItem     from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Card         from '@mui/material/Card'
import {RoleType}   from '../../types'

const RolesList = ({
  title,
  roles,
  checked,
  numberOfChecked,
  handleToggleAll,
  handleToggle
}: {
  title: ReactNode;
  roles: RoleType[];
  checked: RoleType[];
  numberOfChecked: (roles: RoleType[]) => number;
  handleToggleAll: (roles: RoleType[]) => void;
  handleToggle: (roles: RoleType) => void;
}) => {
  const numberOf = numberOfChecked(roles)
  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={() => handleToggleAll(roles)}
            checked={numberOf === roles.length && roles.length !== 0}
            indeterminate={
              numberOf !== roles.length && numberOf !== 0
            }
            disabled={roles.length === 0}
            inputProps={{
              'aria-label': 'all roles selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOf}/${roles.length} selected`}
      />
      <Divider/>
      <List
        sx={{
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {roles.map((role) => {
          const labelId = `roles-list-item-${role}-label`

          return (
            <ListItem
              key={role}
              role="listitem"
              button
              onClick={() => handleToggle(role)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(role) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={role}/>
            </ListItem>
          )
        })}
        <ListItem/>
      </List>
    </Card>
  )
}

export default RolesList