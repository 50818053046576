import {useEffect}                           from 'react'
import {
  useNavigate,
  useParams
}                                            from 'react-router-dom'
import {useSnackbar}                         from 'notistack'
import {PERMISSION_MANUFACTURE_ORDER_CREATE} from '../../../app/contants/permissions'
import {fDateTime}                           from '../../../app/utils/formatTime'
import AllowGuard                            from '../../auth/guards/AllowGuard'
import type {MarketCodeType}                 from '../../markets/types'
import type {CreateManufactureOrderRequest}  from '../types'
import {
  useCreateManufactureOrderMutation,
  useGetManufactureOrdersListQuery
}                                            from '../services/manufacturesApi'
import ManufactureOrderForm                  from '../components/ManufactureOrderForm'
import useLocalStorage                       from '../../../app/hooks/useLocalStorage'
import {GetManufactureOrdersListRequest}     from '../types'

const ManufactureOrderCreate = () => {

  const { mpCode } = useParams<{ mpCode: MarketCodeType }>()

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const [createManufactureOrder, {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useCreateManufactureOrderMutation()

  const [params, setParams] = useLocalStorage<GetManufactureOrdersListRequest>(
    'MANUFACTURE_ORDERS_TABLE_PARAMS',
    {
      order: 'desc',
      limit: 5,
      offset: 0
    },
    true
  )
  const { refetch: refetchOrderList } = useGetManufactureOrdersListQuery(params)

  useEffect(() => {
    if (isSuccess && data) {
      enqueueSnackbar(
        `Заказ на производство № ${data} от ${fDateTime(new Date())} создан.`,
        { variant: 'success' }
      )
      navigate(`/manufacture/orders/${mpCode}/update/${data}`)
      refetchOrderList()
    }
  }, [isSuccess, data])

  return (
    <AllowGuard permissions={[PERMISSION_MANUFACTURE_ORDER_CREATE]}>
      <ManufactureOrderForm
        mpCode={mpCode ?? 'wb'}
        create={(props: CreateManufactureOrderRequest) => createManufactureOrder(props)}
        isLoading={isLoading}
      />
    </AllowGuard>
  )
}

export default ManufactureOrderCreate