import {
  createApi,
  FetchArgs,
  fetchBaseQuery
}                             from '@reduxjs/toolkit/query/react'
import {BaseQueryApi}         from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import {RootState}            from './types'
import {setErrorNotification} from '../../features/notification/store/notificationSlice'
import {API_HOST}             from '../contants/configs'

const baseQuery = fetchBaseQuery({
  baseUrl: API_HOST,
  prepareHeaders: (headers, { getState }) => {

    const token = (getState() as RootState).auth.token

    if (token) {
      headers.set('authorization', `Bearer ${token}`)
    }

    return headers
  }
})

const baseQueryWithRefresh = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
  let result = await baseQuery(args, api, extraOptions)
  if (result?.error?.status === 403) {
    api.dispatch(setErrorNotification('Доступ запрещен'))
  }
  return result
}

const api = createApi({
  baseQuery: baseQueryWithRefresh,
  endpoints: builder => ({})
})

export default api