import Table              from '@mui/material/Table'
import TableBody          from '@mui/material/TableBody'
import TableCell          from '@mui/material/TableCell'
import TableContainer     from '@mui/material/TableContainer'
import TableHead          from '@mui/material/TableHead'
import TableRow           from '@mui/material/TableRow'
import Paper              from '@mui/material/Paper'
import Stack              from '@mui/material/Stack'
import Typography         from '@mui/material/Typography'
import DeleteSearchPhrase from '../../../searchPhrases/components/DeleteSearchPhrase'
import {MarketCodeType}   from '../../../markets/types'
import {ProductType}      from '../../types'

const SearchQueriesTable = ({
  dates = [],
  queries = [],
  mp_code,
  sku,
  product
}: {
  dates?: string[];
  queries?: [
    {
      query: string;
      positions: number[];
    }
  ] | [];
  mp_code: MarketCodeType;
  sku: string;
  product: ProductType;
}) => (
  <TableContainer component={Paper} variant="outlined">
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Запрос</TableCell>
          {dates.map((date, key) => (
            <TableCell key={key} align="right">{date}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {queries.map((query, key) => (
          <TableRow
            key={key}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="inherit" flexGrow={1} noWrap>
                  {query.query}
                </Typography>
                <DeleteSearchPhrase mp_code={mp_code} sku={sku} phrase={query.query}
                                    product={product}/>
              </Stack>
            </TableCell>
            {query.positions.map((pos, key) => (
              <TableCell key={key} align="right">{pos}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

export default SearchQueriesTable
