import Toolbar             from '@mui/material/Toolbar'
import Typography          from '@mui/material/Typography'
import Box                 from '@mui/material/Box'
import {
  PERMISSION_MANAGER_CREATE,
  PERMISSION_MANAGERS_LIST
}                          from '../../../app/contants/permissions'
import AllowGuard          from '../../auth/guards/AllowGuard'
import CreateManagerButton from '../components/CreateManagerButton'
import ManagersTable       from '../components/table/ManagersTable'
import ManagerDrawer       from '../components/ManagerDrawer'

const Managers = () => {
  return (
    <AllowGuard permissions={[PERMISSION_MANAGERS_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Менеджеры
        </Typography>
        <Box flexGrow={1}/>
        <AllowGuard permissions={[PERMISSION_MANAGER_CREATE]}>
          <CreateManagerButton/>
        </AllowGuard>
      </Toolbar>
      <ManagersTable/>
      <ManagerDrawer/>
    </AllowGuard>
  )
}

export default Managers