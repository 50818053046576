import {
  createSlice,
  PayloadAction
}                       from '@reduxjs/toolkit'
import type {RootState} from '../../../app/store/types'
import {
  MpProductType,
  ProductCategoryType,
  ProductType
}                       from '../types'
import {MarketCodeType} from '../../markets/types'

type ProductState = {
  openDrawer: boolean;
  updateProduct: ProductType | null;
}

const productSlice = createSlice({
  name: 'product',
  initialState: {
    openDrawer: false,
    updateProduct: null
  } as ProductState,
  reducers: {
    onCloseDrawer: (state) => {
      state.openDrawer = false
      state.updateProduct = null
    },
    onCreateProduct: (state) => {
      state.openDrawer = true
      state.updateProduct = null
    },
    setUpdateProduct: (state, action: PayloadAction<ProductType>) => {
      state.openDrawer = true
      state.updateProduct = action.payload
    },
    addSearchPhraseInUpdateProduct: (state, action: PayloadAction<{
      mp_code: MarketCodeType;
      phrase: string;
      product: ProductType;
    }>) => {
      let product = action.payload.product
      let mp_products = (product.mp_products.map(item => {
        if (item.mp_code == action.payload.mp_code) {
          return {
            ...item,
            search_queries: {
              ...item.search_queries,
              queries: [
                ...(item.search_queries?.queries ?? []),
                {
                  query: action.payload.phrase,
                  positions: [...Array(item.search_queries?.dates.length)].map(() => 0)
                }
              ]
            }
          }
        }
        return item
      })) as MpProductType[]
      state.updateProduct = { ...product, mp_products }
    },
    deleteSearchPhraseFromUpdateProduct: (state, action: PayloadAction<{
      mp_code: MarketCodeType;
      phrase: string;
      product: ProductType;
    }>) => {
      let product = action.payload.product
      let mp_products = (product.mp_products.map(item => {
        if (item.mp_code == action.payload.mp_code) {
          return {
            ...item,
            search_queries: {
              ...item.search_queries,
              queries: (item.search_queries?.queries ?? []).filter(q => q.query != action.payload.phrase)
            }
          }
        }
        return item
      })) as MpProductType[]
      state.updateProduct = { ...product, mp_products }
    },
    addCategoryInUpdateProduct: (state, action: PayloadAction<{
      categories: ProductCategoryType[];
      product: ProductType;
    }>) => {
      let product = action.payload.product
      let categories = action.payload.categories
      state.updateProduct = { ...product, categories: [...product.categories, ...categories] }
    },
    deleteCategoryInUpdateProduct: (state, action: PayloadAction<{
      categoryId: number;
      product: ProductType;
    }>) => {
      let product = action.payload.product
      let categories = product.categories.filter(({ id }) => action.payload.categoryId != id)
      state.updateProduct = { ...product, categories }
    },
  },
})

export const {
  onCloseDrawer,
  onCreateProduct,
  setUpdateProduct,
  addSearchPhraseInUpdateProduct,
  deleteSearchPhraseFromUpdateProduct,
  addCategoryInUpdateProduct,
  deleteCategoryInUpdateProduct
} = productSlice.actions

export default productSlice.reducer

export const selectOpenDrawer = (state: RootState) => state.product.openDrawer
export const selectUpdateProduct = (state: RootState) => state.product.updateProduct
