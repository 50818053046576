import Toolbar                 from '@mui/material/Toolbar'
import Typography              from '@mui/material/Typography'
import Box                     from '@mui/material/Box'
import {
  PERMISSION_LEGAL_ENTITIES_LIST,
  PERMISSION_LEGAL_ENTITY_CREATE
}                              from '../../../app/contants/permissions'
import AllowGuard              from '../../auth/guards/AllowGuard'
import CreateLegalEntityButton from '../components/CreateLegalEntityButton'
import LegalEntitiesTable      from '../components/table/LegalEntitiesTable'
import LegalEntityDrawer       from '../components/LegalEntityDrawer'

const LegalEntities = () => {
  return (
    <AllowGuard permissions={[PERMISSION_LEGAL_ENTITIES_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Юридические лица
        </Typography>
        <Box flexGrow={1}/>
        <AllowGuard permissions={[PERMISSION_LEGAL_ENTITY_CREATE]}>
          <CreateLegalEntityButton/>
        </AllowGuard>
      </Toolbar>
      <LegalEntitiesTable/>
      <LegalEntityDrawer/>
    </AllowGuard>
  )
}

export default LegalEntities