import {
  ChangeEvent,
  useEffect,
  useState
}                        from 'react'
import Paper             from '@mui/material/Paper'
import Avatar            from '@mui/material/Avatar'
import Typography        from '@mui/material/Typography'
import Stack             from '@mui/material/Stack'
import Box               from '@mui/material/Box'
import Rating            from '@mui/material/Rating'
import TextField         from '@mui/material/TextField'
import LoadingButton     from '@mui/lab/LoadingButton'
import Link              from '@mui/material/Link'
import {useSnackbar}     from 'notistack'
import IconUpdatedDate   from '../../../app/components/icons/IconUpdatedDate'
import {fDate}           from '../../../app/utils/formatTime'
import type {ReviewType} from '../types'
import {
  useGeneratorReviewMutation,
  useSendAnswerMutation
}                        from '../services/reviewsApi'
import {
  selectList,
  selectSupplierId,
  setList
}                        from '../store/reviewsSlice'
import {
  useAppDispatch,
  useAppSelector
}                        from '../../../app/store/hooks'
import {getMpProductUrl} from '../../../app/utils/mpProducts'

const ReviewCard = ({
  id,
  imtId,
  nmId,
  size,
  productValuation,
  text,
  can_auto_generate_reply,
  auto_generated_reply,
  createdDate,
  productDetails,
  user_name,
  image_link
}: ReviewType) => {

  const supplierId = useAppSelector(selectSupplierId)

  const [answerText, setAnswerText] = useState<string>(auto_generated_reply)

  const onChangeAnswerText = (event: ChangeEvent<HTMLInputElement>) => {
    setAnswerText(event.target.value)
  }

  const [generatorReview, {
    isLoading: isLoadingGenerator,
    isSuccess: isSuccessGenerator,
    isError: isErrorGenerator,
    data
  }] = useGeneratorReviewMutation()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isErrorGenerator) {
      enqueueSnackbar('Для данного товара нет шаблонов ответа.', { variant: 'error' })
    }
  }, [isErrorGenerator])

  useEffect(() => {
    if (isSuccessGenerator && data) {
      setAnswerText(data)
      enqueueSnackbar('Текст ответа успешно сгенерирован.', { variant: 'success' })
    }
  }, [isSuccessGenerator, data])

  const onGeneratorReview = () => {
    generatorReview({ sku: nmId, name: user_name })
  }

  const [sendAnswer, {
    isLoading: isLoadingSendAnswer,
    isSuccess: isSuccessSendAnswer,
    isError: isErrorSendAnswer
  }] = useSendAnswerMutation()

  const list = useAppSelector(selectList)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccessSendAnswer) {
      const newList = list.filter((item) => item.id !== id)
      dispatch(setList(newList))
      enqueueSnackbar('Успешно отправлено.', { variant: 'success' })
    }
  }, [isSuccessSendAnswer])

  useEffect(() => {
    if (isErrorSendAnswer) {
      enqueueSnackbar('Произошла ошибка при отправке.', { variant: 'error' })
    }
  }, [isErrorSendAnswer])

  const onSendAnswer = () => {
    if (answerText.length > 10) {
      sendAnswer({
        answer_text: answerText,
        supplier_id: supplierId,
        feedback_id: id
      })
    } else {
      enqueueSnackbar('Минимальную длину отзыва в 10 символов.', { variant: 'error' })
    }
  }

  return (
    <Paper sx={{ p: 2 }} elevation={4}>
      <Stack direction="row" spacing={1}>
        <Avatar src={image_link} variant="square" sx={{ height: 80, width: 80 }}/>
        <Box flexGrow={1}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Link
              noWrap
              variant="h6"
              color="primary"
              underline="none"
              target="_blank"
              href={getMpProductUrl(nmId, 'wb')}
            >
              {productDetails.productName}
            </Link>
            <Typography noWrap color="text.secondary" sx={{ fontSize: 12 }}>
              Размер: {size != 'none' ? size : 'не удалось определить'}
            </Typography>
          </Stack>
          <Typography color="text.secondary" fontWeight={700} fontSize={16}>
            {user_name != 'none' ? user_name : 'Имя пользователя определить не удалось'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {text}
          </Typography>
        </Box>
        <Box sx={{ textAlign: 'end' }}>
          <Typography color="text.secondary">
            <IconUpdatedDate sx={{ mr: 1 }}/>
            {fDate(createdDate)}
          </Typography>
          <Rating name="read-only" value={productValuation} readOnly/>
        </Box>
      </Stack>
      <TextField
        fullWidth
        multiline
        label="Ответ"
        value={answerText}
        onChange={onChangeAnswerText}
        sx={{ my: 2 }}
      />
      <Stack direction="row" justifyContent="space-between" spacing={2}>
        {can_auto_generate_reply ? (
          <LoadingButton
            variant="contained"
            loading={isLoadingGenerator}
            onClick={onGeneratorReview}
            disabled={isErrorGenerator}
          >
            Сгенерировать ответ
          </LoadingButton>
        ) : <Box/>}
        <LoadingButton
          variant="contained"
          loading={isLoadingSendAnswer}
          onClick={onSendAnswer}
        >
          Отправить
        </LoadingButton>
      </Stack>
    </Paper>
  )
}
export default ReviewCard
