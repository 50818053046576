import * as colors   from '@mui/material/colors'
import {createTheme} from '@mui/material/styles'
import {THEME_COLOR} from '../contants/configs'

export type ThemeColorType =
  'red'
  | 'pink'
  | 'purple'
  | 'deepPurple'
  | 'indigo'
  | 'blue'
  | 'lightBlue'
  | 'cyan'
  | 'teal'
  | 'green'
  | 'lightGreen'
  | 'lime'
  | 'yellow'
  | 'amber'
  | 'orange'
  | 'deepOrange'
  | 'brown'
  | 'grey'
  | 'blueGrey';

export const getPaletteColor = (
  color: ThemeColorType,
  contrastText: string = '#FFFFFF'
) => {
  return {
    light: colors[color][300],
    main: colors[color][500],
    dark: colors[color][700],
    contrastText: contrastText
  }
}

const theme = createTheme({
  palette: {
    primary: getPaletteColor(THEME_COLOR),
    error: getPaletteColor('pink')
  },
})

export default theme