import api from '../../../app/store/api'
import {
  CreateManufactureOrderRequest,
  CreateManufactureOrderResponse,
  CreateManufactureStatusRequest,
  CreateManufactureStatusResponse,
  DeleteManufactureStatusRequest,
  GetManufactureOrderRequest,
  GetManufactureOrderResponse,
  GetManufactureOrdersListRequest,
  GetManufactureOrdersListResponse,
  GetManufactureStatusesListResponse,
  UpdateManufactureOrderRequest,
  UpdateManufactureOrderResponse,
  UpdateManufactureStatusRequest,
  UpdateManufactureStatusResponse
}          from '../types'

export const manufacturesApi = api.injectEndpoints({
  endpoints: builder => ({
    // GET: api/manufacture/statuses - Получение Списка Статусов
    getManufactureStatusesList: builder.query<GetManufactureStatusesListResponse, void>({
      query: () => ({
        url: `/api/manufacture/statuses`,
      })
    }),
    // POST: api/manufacture/statuses - Добавления Статусов
    createManufactureStatus: builder.mutation<CreateManufactureStatusResponse, CreateManufactureStatusRequest>({
      query: (status) => ({
        url: '/api/manufacture/statuses',
        method: 'POST',
        body: status
      })
    }),
    // PUT: api/manufacture/{status_id} - Изменение Статусаа
    updateManufactureStatus: builder.mutation<UpdateManufactureStatusResponse, UpdateManufactureStatusRequest>({
      query: (status) => ({
        url: `/api/manufacture/statuses/${status.id}`,
        method: 'PUT',
        body: status
      })
    }),
    // DELETE: /api/manufacture/{status_id} - Удаления Статуса
    deleteManufactureStatus: builder.mutation<void, DeleteManufactureStatusRequest>({
      query: ({ id }) => ({
        url: `/api/manufacture/statuses/${id}`,
        method: 'DELETE'
      })
    }),
    // GET: api/manufacture/orders - Получение Списка Заказов На Производство
    getManufactureOrdersList: builder.query<GetManufactureOrdersListResponse, GetManufactureOrdersListRequest>({
      query: ({ order, limit, offset }) => ({
        url: `/api/manufacture/orders?order=${order}&limit=${limit}&offset=${offset}`,
      })
    }),
    // POST: api/manufacture/orders - Добавление Заказа На Производство
    createManufactureOrder: builder.mutation<CreateManufactureOrderResponse, CreateManufactureOrderRequest>({
      query: (order) => ({
        url: '/api/manufacture/orders',
        method: 'POST',
        body: order
      })
    }),
    // GET: api/manufacture/orders/{order_id} - Получение Заказа На Производство
    getManufactureOrder: builder.query<GetManufactureOrderResponse, GetManufactureOrderRequest>({
      query: ({ order_id }) => ({
        url: `/api/manufacture/orders/${order_id}`,
      })
    }),
    // PUT: api/manufacture/orders/{order_id} - Изменение Заказа На Производство
    updateManufactureOrder: builder.mutation<UpdateManufactureOrderResponse, UpdateManufactureOrderRequest>({
      query: (order) => ({
        url: `/api/manufacture/orders/${order.id}`,
        method: 'PUT',
        body: order
      })
    }),
    // DELETE: /api/manufacture/orders/{order_id} - Удаление Заказа На Производство
  })
})

export const {
  useGetManufactureStatusesListQuery,
  useCreateManufactureStatusMutation,
  useUpdateManufactureStatusMutation,
  useDeleteManufactureStatusMutation,
  useGetManufactureOrdersListQuery,
  useCreateManufactureOrderMutation,
  useGetManufactureOrderQuery,
  useUpdateManufactureOrderMutation
} = manufacturesApi
