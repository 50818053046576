import type {ChangeEvent}           from 'react'
import {
  useEffect,
  useState
}                                   from 'react'
import Table                        from '@mui/material/Table'
import TableBody                    from '@mui/material/TableBody'
import TableContainer               from '@mui/material/TableContainer'
import TablePagination              from '@mui/material/TablePagination'
import TableRow                     from '@mui/material/TableRow'
import Paper                        from '@mui/material/Paper'
import Typography                   from '@mui/material/Typography'
import Link                         from '@mui/material/Link'
import Stack                        from '@mui/material/Stack'
import TableFooter                  from '@mui/material/TableFooter'
import {
  fCurrency,
  fNumber,
  fPcs,
  fPercentRound
}                                   from '../../../../../app/utils/formatNumber'
import {REPORT_TABLE_ROWS_PER_PAGE} from '../../../../../app/contants/configs'
import type {
  RealisationRowType,
  RealisationTableHeadCellType
}                                   from '../../../types'
import StyledTableRow               from '../../table/styled/StyledTableRow'
import StyledTableCell              from '../../table/styled/StyledTableCell'
import RealisationTableHead         from './RealisationTableHead'
import RealisationTableShowColumns  from './RealisationTableShowColumns'
import useLocalStorage              from '../../../../../app/hooks/useLocalStorage'

const RealisationTable = ({
  rows
}: {
  rows: RealisationRowType[];
}) => {

  const [headCells, setHeadCells] = useState<RealisationTableHeadCellType[]>([
    { key: 'link', show: true, label: 'Название', sticky: true },
    {
      key: 'profit_per_unit',
      show: true,
      label: 'Прибыль с 1 шт',
      format: 'currency',
      align: 'right'
    }, // no total
    {
      key: 'margin_per_unit',
      show: true,
      label: 'Маржинальность',
      format: 'percent',
      align: 'right'
    }, // no total
    { key: 'stock', show: true, label: 'Остаток', format: 'pcs', total: 0, align: 'right' },
    { key: 'days_stock', show: true, label: 'Дней', format: 'pcs', align: 'right' }, // no total
    {
      key: 'weeks_stat',
      show: true,
      label: '',
      format: 'pcs',
      total: [0, 0, 0, 0],
      align: 'right'
    },
    { key: 'net_cost', show: true, label: 'СС', format: 'currency', align: 'right' }, // no total
    {
      key: 'stocks_net_cost',
      show: true,
      label: 'Сумма остатка',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    { key: 'sales_count', show: true, label: 'Продаж', format: 'pcs', total: 0, align: 'right' },
    {
      key: 'turnover_for_period',
      show: true,
      label: 'Оборот',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'sale_price_avarage',
      show: true,
      label: 'Средняя цена продажи',
      format: 'currency',
      align: 'right'
    }, // no total
    {
      key: 'deliveries_count',
      show: true,
      label: 'Доставок',
      format: 'pcs',
      total: 0,
      align: 'right'
    },
    {
      key: 'deliveries_total',
      show: true,
      label: 'Доставок',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'returns_count',
      show: true,
      label: 'Возвратов',
      format: 'pcs',
      total: 0,
      align: 'right'
    },
    {
      key: 'returns_total',
      show: true,
      label: 'Возвратов',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    { key: 'penalty_count', show: true, label: 'Штрафов', format: 'pcs', total: 0, align: 'right' },
    {
      key: 'penalty_total',
      show: true,
      label: 'Штрафов',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'comission_total',
      show: true,
      label: 'Всего комиссий',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'comission_avarage',
      show: true,
      label: 'Средняя комиссия',
      format: 'currency',
      align: 'right'
    }, // no total
    {
      key: 'for_pay_total',
      show: true,
      label: 'К выплате всего',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'for_pay_avarage',
      show: true,
      label: 'К выплате за шт',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'wb_takes_average',
      show: true,
      label: 'Вб в среднем забирает',
      format: 'currency',
      align: 'right'
    }, // no total
    {
      key: 'wb_takes_persent',
      show: true,
      label: 'Вб в среднем забирает',
      format: 'percent',
      align: 'right'
    }, // no total
    {
      key: 'money_income_total',
      show: true,
      label: 'Приход денег',
      format: 'currency',
      total: 0,
      align: 'right'
    },
    {
      key: 'redeems',
      show: true,
      label: 'Выкупы',
      format: 'pcs',
      total: 0,
      align: 'right'
    },
    {
      key: 'money_incomes_with_out_redeems',
      show: true,
      label: 'Приход денег за минусом выкупов',
      format: 'currency',
      total: 0,
      align: 'right'
    }
  ])

  const [page, setPage] = useState<number>(0)

  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>(
    'REALISATION_TABLE_ROWS_PER_PAGE',
    REPORT_TABLE_ROWS_PER_PAGE,
    true
  )

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const getFormat = (value: number | string, format?: 'currency' | 'pcs' | 'percent') => {
    const v = typeof value === 'number' ? fNumber(value) : 0
    if (format === 'currency') {
      return fCurrency(v)
    } else if (format === 'pcs') {
      return fPcs(v)
    } else if (format === 'percent') {
      return fPercentRound(v)
    }
    return fNumber(v)
  }

  useEffect(() => {
    setPage(0)
    let totalCols = headCells.map(col => ({
      ...col,
      total: Array.isArray(col.total) ? [0, 0, 0, 0] : col.total === undefined ? undefined : 0
    }))
    rows.forEach(row => {
      totalCols = totalCols.map((col) => {
        if (col.total === undefined) {
          return col
        }
        if (Array.isArray(col.total)) {
          let total = col.total.map((t, i) => {
            // @ts-ignore
            if (row[col.key][i] === undefined) {
              return t
            }
            // @ts-ignore
            return t + row[col.key][i]
          })
          return { ...col, total }
        } else {
          return {
            ...col,
            total: (col.total + Number(row[col.key]))
          }
        }
      })
    })
    setHeadCells(totalCols)
  }, [rows])

  return (
    <Paper sx={{ width: '100%', mb: 4, p: 1 }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
        <Table aria-labelledby="tableTitle" size="small">
          <RealisationTableHead headCells={headCells}/>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
              <StyledTableRow hover tabIndex={-1} key={index}>
                {headCells.map((headCell) => headCell.show ? headCell.key == 'link' ? (
                  <StyledTableCell
                    key={headCell.key}
                    align={headCell.align ?? 'left'}
                    component="th"
                    scope="row"
                    sx={headCell.sticky ? {
                      position: 'sticky',
                      left: 0,
                      backgroundColor: '#fff'
                    } : {
                      backgroundColor: '#fff'
                    }}
                  >
                    <Typography variant="inherit" noWrap>
                      <Link href={row.link.link} target="_blank">{row.link.name}</Link>
                    </Typography>
                  </StyledTableCell>
                ) : headCell.key === 'margin_per_unit' ? (
                  <StyledTableCell
                    key={headCell.key}
                    align={headCell.align ?? 'left'}
                    sx={row[headCell.key] < 100 ? { color: '#f00' } : {}}
                  >
                    <Typography variant="inherit" noWrap>
                      {getFormat(row[headCell.key], headCell.format)}
                    </Typography>
                  </StyledTableCell>
                ) : headCell.key === 'days_stock' ? (
                  <StyledTableCell
                    key={headCell.key}
                    align={headCell.align ?? 'left'}
                    sx={row[headCell.key] > 160 ? { color: '#f00' } : {}}
                  >
                    <Typography variant="inherit" noWrap>
                      {getFormat(row[headCell.key], headCell.format)}
                    </Typography>
                  </StyledTableCell>
                ) : headCell.key === 'weeks_stat' ? (
                  <>
                    {(headCell.total as number[])[3] > 0 && (
                      <StyledTableCell
                        key={`${headCell.key}-3`}
                        align={headCell.align ?? 'left'}
                      >
                        <Typography variant="inherit" noWrap>
                          {getFormat(row.weeks_stat[3], headCell.format)}
                        </Typography>
                      </StyledTableCell>
                    )}
                    {(headCell.total as number[])[2] > 0 && (
                      <StyledTableCell
                        key={`${headCell.key}-2`}
                        align={headCell.align ?? 'left'}
                      >
                        <Typography variant="inherit" noWrap>
                          {getFormat(row.weeks_stat[2], headCell.format)}
                        </Typography>
                      </StyledTableCell>
                    )}
                    {(headCell.total as number[])[1] > 0 && (
                      <StyledTableCell
                        key={`${headCell.key}-1`}
                        align={headCell.align ?? 'left'}
                      >
                        <Typography variant="inherit" noWrap>
                          {getFormat(row.weeks_stat[1], headCell.format)}
                        </Typography>
                      </StyledTableCell>
                    )}
                    {(headCell.total as number[])[0] > 0 && (
                      <StyledTableCell
                        key={`${headCell.key}-0`}
                        align={headCell.align ?? 'left'}
                      >
                        <Typography variant="inherit" noWrap>
                          {getFormat(row.weeks_stat[0], headCell.format)}
                        </Typography>
                      </StyledTableCell>
                    )}
                  </>
                ) : (
                  <StyledTableCell key={headCell.key} align={headCell.align ?? 'left'}>
                    <Typography variant="inherit" noWrap>
                      {getFormat(row[headCell.key], headCell.format)}
                    </Typography>
                  </StyledTableCell>
                ) : null)}
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {headCells.map((headCell) => headCell.show ? Array.isArray(headCell.total) ? headCell.total.filter(t => t > 0).map((t, i) => (
                <StyledTableCell
                  key={`${headCell.key}-${i}`}
                  align={headCell.align ?? 'left'}
                >
                  <Typography variant="inherit" noWrap>
                    {getFormat(t, headCell.format)}
                  </Typography>
                </StyledTableCell>
              )) : (
                <StyledTableCell
                  key={headCell.key}
                  align={headCell.align ?? 'left'}
                  sx={headCell.sticky ? {
                    position: 'sticky',
                    left: 0,
                    zIndex: 2
                  } : {}}
                >
                  <Typography variant="inherit" noWrap>
                    {headCell.total ? getFormat(headCell.total, headCell.format) : ''}
                  </Typography>
                </StyledTableCell>
              ) : null)}
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" paddingTop={1}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <RealisationTableShowColumns columns={headCells} setColumns={setHeadCells}/>
      </Stack>
    </Paper>
  )
}

export default RealisationTable
