import api from '../../../app/store/api'
import {
  CreateSearchPhraseRequest,
  CreateSearchPhraseResponse,
  DeleteSearchPhraseByCategoryRequest,
  DeleteSearchPhraseByCategoryResponse,
  DeleteSearchPhraseRequest,
  DeleteSearchPhraseResponse,
  SearchPhrasesResponse
}          from '../types'

export const searchPhrasesApi = api.injectEndpoints({
  endpoints: builder => ({
    getSearchPhrases: builder.query<SearchPhrasesResponse, void>({
      query: () => ({
        url: 'management/search_phrases',
      })
    }),
    createSearchPhrase: builder.mutation<CreateSearchPhraseResponse, CreateSearchPhraseRequest>({
      query: (searchPhrase) => ({
        url: 'management/search_phrases',
        method: 'POST',
        body: searchPhrase
      })
    }),
    deleteSearchPhrase: builder.mutation<DeleteSearchPhraseResponse, DeleteSearchPhraseRequest>({
      query: ({ mp_code, sku, phrase }) => ({
        url: `/management/search_phrases/sku/${mp_code}/${sku}/${phrase}`,
        method: 'DELETE'
      })
    }),
    deleteSearchPhraseByCategory: builder.mutation<DeleteSearchPhraseByCategoryResponse, DeleteSearchPhraseByCategoryRequest>({
      query: ({ mp_code, category_id, phrase }) => ({
        url: `management/search_phrases/category/${mp_code}/${category_id}/${phrase}`,
        method: 'DELETE'
      })
    }),
  })
})

export const {
  useGetSearchPhrasesQuery,
  useCreateSearchPhraseMutation,
  useDeleteSearchPhraseMutation,
  useDeleteSearchPhraseByCategoryMutation
} = searchPhrasesApi