import Toolbar            from '@mui/material/Toolbar'
import Typography         from '@mui/material/Typography'
import Box                from '@mui/material/Box'
import {
  PERMISSION_MARKET_CREATE,
  PERMISSION_MARKETS_LIST
}                         from '../../../app/contants/permissions'
import AllowGuard         from '../../auth/guards/AllowGuard'
import CreateMarketButton from '../components/CreateMarketButton'
import MarketsTable       from '../components/table/MarketsTable'
import MarketDrawer       from '../components/MarketDrawer'

const Markets = () => {
  return (
    <AllowGuard permissions={[PERMISSION_MARKETS_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Маркетплейсы
        </Typography>
        <Box flexGrow={1}/>
        <AllowGuard permissions={[PERMISSION_MARKET_CREATE]}>
          <CreateMarketButton/>
        </AllowGuard>
      </Toolbar>
      <MarketsTable/>
      <MarketDrawer/>
    </AllowGuard>
  )
}

export default Markets