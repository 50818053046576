import Box                         from '@mui/material/Box'
import OutlinedInput               from '@mui/material/OutlinedInput'
import InputLabel                  from '@mui/material/InputLabel'
import MenuItem                    from '@mui/material/MenuItem'
import FormControl                 from '@mui/material/FormControl'
import Select, {SelectChangeEvent} from '@mui/material/Select'
import Chip                        from '@mui/material/Chip'
import {MarketFieldType}           from '../types'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const MarketFieldsSelectChip = ({
  fields,
  setFields
}: {
  fields: MarketFieldType[];
  setFields: (fields: MarketFieldType[]) => void;
}) => {

  const fieldsList: MarketFieldType[] = ['sku', 'size']

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    setFields(event.target.value as MarketFieldType[])
  }

  return (
    <FormControl size="small">
      <InputLabel id="market-fields-chip-label">
        Мета поля
      </InputLabel>
      <Select
        labelId="market-fields-chip-label"
        id="market-fields-chip"
        multiple
        value={fields}
        onChange={handleChange}
        input={<OutlinedInput id="market-fields-chip" label="Мета поля"/>}
        renderValue={(selected: MarketFieldType[]) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} size="small"/>
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {fieldsList.map((field) => (
          <MenuItem key={field} value={field}>
            {field}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default MarketFieldsSelectChip
