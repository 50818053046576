import TableRow          from '@mui/material/TableRow'
import Skeleton          from '@mui/material/Skeleton'
import {StyledTableCell} from './ProductCategoriesTable'

const ProductCategoriesTableSkeleton = ({
  rowsCount = 5
}: {
  rowsCount?: number;
}) => {

  return (
    <>
      {[...Array(rowsCount)].map((_, key) => (
        <TableRow key={`skeleton-${key}`} tabIndex={-1}>
          <StyledTableCell>
            <Skeleton variant="text" width={180}/>
          </StyledTableCell>
          <StyledTableCell align="right" padding="none" width={24}>
            <Skeleton variant="text" width={30}/>
          </StyledTableCell>
        </TableRow>
      ))}
    </>
  )
}

export default ProductCategoriesTableSkeleton