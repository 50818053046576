import {
  useEffect,
  useState
}                                            from 'react'
import {useParams}                           from 'react-router-dom'
import Typography                            from '@mui/material/Typography'
import Stack                                 from '@mui/material/Stack'
import TextField                             from '@mui/material/TextField'
import {DatePicker}                          from '@mui/x-date-pickers/DatePicker'
import Alert                                 from '@mui/material/Alert'
import Box                                   from '@mui/material/Box'
import TableContainer                        from '@mui/material/TableContainer'
import Table                                 from '@mui/material/Table'
import TableHead                             from '@mui/material/TableHead'
import TableRow                              from '@mui/material/TableRow'
import TableBody                             from '@mui/material/TableBody'
import Paper                                 from '@mui/material/Paper'
import Button                                from '@mui/material/Button'
import {useSnackbar}                         from 'notistack'
import IconArrowLeftSolid                    from '../../../app/components/icons/IconArrowLeftSolid'
import IconSearch                            from '../../../app/components/icons/IconSearch'
import LoadingBox                            from '../../../app/components/LoadingBox'
import {PERMISSION_REPORTS_REALISATION_VIEW} from '../../../app/contants/permissions'
import AllowGuard                            from '../../auth/guards/AllowGuard'
import type {MarketCodeType}                 from '../../markets/types'
import {
  useGetSearchPhrasesListQuery,
  useGetSearchPositionsMutation
}                                            from '../services/reportsApi'
import StyledTableCell                       from '../components/table/styled/StyledTableCell'
import SearchPositionsTable                  from '../components/table/SearchPositionsTable'

const SearchPositions = () => {

  const { mpCode = 'wb' } = useParams<{ mpCode: MarketCodeType }>()

  const [phrase, setPhrase] = useState<string | null>(null)
  const [date, setDate] = useState<string | null>(String(new Date()))

  const {
    data: phrases,
    isLoading: isLoadingPhrases,
    isFetching: isFetchingPhrases
  } = useGetSearchPhrasesListQuery({ mp_code: mpCode })

  const [getSearchPositions, {
    data: positions,
    isLoading: isLoadingPositions,
    isSuccess: isSuccessPositions,
    isError: isErrorPositions,
    error: errorPositions
  }] = useGetSearchPositionsMutation()

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (mpCode && phrase && date) {
      getSearchPositions({
        mp_code: mpCode,
        phrase: phrase,
        date: date,
      })
    } else if (mpCode && phrase && date == null) {
      enqueueSnackbar('Выберите дату.', { variant: 'error' })
    }
  }, [mpCode, phrase, date])

  const onBack = () => {
    setPhrase(null)
    // setDate(null)
  }

  return (
    <AllowGuard permissions={[PERMISSION_REPORTS_REALISATION_VIEW]}>
      <Stack direction="row" spacing={2} alignItems="center" paddingTop={2}>
        <Typography color="text.secondary" variant="h4" noWrap>
          Отчет по позициям {date && phrase && `по запросу: ${phrase}`}
        </Typography>
        {date && phrase && (
          <Button
            variant="contained"
            startIcon={<IconArrowLeftSolid/>}
            onClick={onBack}
          >
            <Typography variant="inherit" noWrap>
              Вернуться к списку запросов
            </Typography>
          </Button>
        )}
        <Box flexGrow={1}/>
        <DatePicker
          label="Дата"
          inputFormat="dd/MM/yyyy"
          value={date}
          onChange={(newValue) => setDate(newValue)}
          renderInput={(params) => (
            <TextField {...params} name="date" size="small"/>
          )}
        />
      </Stack>
      <Stack direction="column" flexGrow={1} paddingTop={4}>
        <LoadingBox isLoading={isLoadingPhrases || isFetchingPhrases || isLoadingPositions}>
          {(phrase && date) ? (positions && positions.length > 0) ? (
            <SearchPositionsTable mpCode={mpCode} date={date} rows={positions}/>
          ) : (
            <Alert
              variant="filled"
              icon={<IconSearch sx={{ fontSize: 56 }}/>}
              sx={{ backgroundColor: 'error.main' }}
            >
              <Typography variant="h6" fontWeight={900}>
                Данные не найдены
              </Typography>
              Выберите другую <strong>«дату»</strong> или <strong>«поисковую фразу»</strong>.
            </Alert>
          ) : (
            <Paper sx={{ width: '100%', mb: 4, p: 2 }}>
              <TableContainer
                sx={{
                  maxHeight: 'calc(100vh - 300px)',
                  border: '1px solid #e0e0e0',
                  borderBottom: 'none'
                }}
              >
                <Table stickyHeader aria-labelledby="tableTitle" size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>
                        <Typography color="text.secondary" fontSize={14} noWrap>
                          Запрос
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Typography color="text.secondary" fontSize={14} noWrap>
                          Количество товаров
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {phrases && phrases.map((p, key) => (
                      <TableRow hover key={key} onClick={() => setPhrase(p.phrase)}>
                        <StyledTableCell>
                          {p.phrase}
                        </StyledTableCell>
                        <StyledTableCell>
                          {p.count}
                        </StyledTableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          )}
        </LoadingBox>
      </Stack>
    </AllowGuard>
  )
}

export default SearchPositions