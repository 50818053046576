import IconButton                           from '@mui/material/IconButton'
import IconPenToSquare                      from '../../../../../app/components/icons/IconPenToSquare'
import {PERMISSION_PRODUCT_CATEGORY_UPDATE} from '../../../../../app/contants/permissions'
import {useAppDispatch}                     from '../../../../../app/store/hooks'
import useAllow                             from '../../../../auth/hooks/useAllow'
import {setUpdateProductCategory}           from '../../../store/productCategorySlice'
import type {ProductCategoryType}           from '../../../types'

const UpdateProductCategoryButton = ({
  productCategory
}: {
  productCategory: ProductCategoryType
}) => {

  const allowUpdate = useAllow([PERMISSION_PRODUCT_CATEGORY_UPDATE])

  const dispatch = useAppDispatch()

  const onUpdate = () => {
    dispatch(setUpdateProductCategory(productCategory))
  }

  return (
    <>
      {allowUpdate && (
        <IconButton color="primary" onClick={onUpdate}>
          <IconPenToSquare/>
        </IconButton>
      )}
    </>
  )
}

export default UpdateProductCategoryButton