import {
  createSlice,
  PayloadAction
}                            from '@reduxjs/toolkit'
import type {RootState}      from '../../../app/store/types'
import {ProductCategoryType} from '../types'

type ProductCategoryState = {
  openDrawer: boolean;
  updateProductCategory: ProductCategoryType | null;
}

const initialState: ProductCategoryState = {
  openDrawer: false,
  updateProductCategory: null
}

const productCategorySlice = createSlice({
  name: 'productCategory',
  initialState,
  reducers: {
    onCloseDrawer: (state) => {
      state.openDrawer = false
      state.updateProductCategory = null
    },
    onCreateProductCategory: (state) => {
      state.openDrawer = true
      state.updateProductCategory = null
    },
    setUpdateProductCategory: (state, action: PayloadAction<ProductCategoryType>) => {
      state.openDrawer = true
      state.updateProductCategory = action.payload
    }
  },
})

export const {
  onCloseDrawer,
  onCreateProductCategory,
  setUpdateProductCategory,
} = productCategorySlice.actions

export default productCategorySlice.reducer

export const selectOpenDrawer = (state: RootState) => state.productCategory.openDrawer
export const selectUpdateProductCategory = (state: RootState) => state.productCategory.updateProductCategory
