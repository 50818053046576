import type {ChangeEvent} from 'react'
import FormControlLabel   from '@mui/material/FormControlLabel'
import Checkbox           from '@mui/material/Checkbox'
import Typography         from '@mui/material/Typography'
import {
  useAppDispatch,
  useAppSelector
}                         from '../../../../app/store/hooks'
import {
  selectArchived,
  setArchived
}                         from '../../store/productsSlice'

const CheckboxArchived = () => {

  const archived = useAppSelector(selectArchived)
  const dispatch = useAppDispatch()

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setArchived(e.target.checked))
  }

  return (
    <FormControlLabel
      control={<Checkbox checked={archived} onChange={onChange}/>}
      label={<Typography noWrap>Показать архивные</Typography>}
    />
  )
}

export default CheckboxArchived