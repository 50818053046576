import {
  createSlice,
  PayloadAction
}                       from '@reduxjs/toolkit'
import type {RootState} from '../../../app/store/types'

type ProductsState = {
  linked: boolean;
  archived: boolean;
  categories: number[];
  noCategory: boolean;
}

const initialState: ProductsState = {
  linked: true,
  archived: false,
  categories: [],
  noCategory: false
}

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setLinked: (state, action: PayloadAction<boolean>) => {
      state.linked = action.payload
    },
    setArchived: (state, action: PayloadAction<boolean>) => {
      state.archived = action.payload
    },
    setCategories: (state, action: PayloadAction<number[]>) => {
      state.categories = action.payload.filter(i => i != 0)
      state.noCategory = false
    },
    setNoCategory: (state) => {
      state.categories = [0]
      state.noCategory = true
    }
  },
})

export const {
  setLinked,
  setArchived,
  setCategories,
  setNoCategory
} = productsSlice.actions

export default productsSlice.reducer

export const selectLinked = (state: RootState) => state.products.linked
export const selectArchived = (state: RootState) => state.products.archived
export const selectCategories = (state: RootState) => state.products.categories
export const selectNoCategory = (state: RootState) => state.products.noCategory
