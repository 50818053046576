import Avatar            from '@mui/material/Avatar'
import Button            from '@mui/material/Button'
import {MarketCodeType}  from '../../../markets/types'
import {getMpProductUrl} from '../../../../app/utils/mpProducts'
import IconArchive       from '../../../../app/components/icons/IconArchive'

const MpProduct = ({
  code,
  sku,
  size,
  archive
}: {
  code: MarketCodeType;
  sku: string;
  size?: string | null;
  archive?: boolean;
}) => {

  return (
    <Button
      component="a"
      color="inherit"
      href={getMpProductUrl(sku, code)}
      target="_blank"
      sx={{ ml: -1, px: 1 }}
      startIcon={<Avatar src={`/img/mp/${code}.png`} sx={{ height: 28, width: 28 }}/>}
    >
      {archive && <IconArchive sx={{ color: 'text.secondary', fontSize: 18, mr: 1 }}/>}
      {sku}{size && `, размер: ${size}`}
    </Button>
  )
}

export default MpProduct