import api                from '../../../app/store/api'
import type {
  FeedbackStatisticsRequest,
  FeedbackStatisticsResponse,
  RealisationForAbcRequest,
  RealisationForAbcResponse,
  RealisationRequest,
  RealisationResponse,
  RealisationRowForAbcType,
  RealisationRowType,
  SearchPhrasesListRequest,
  SearchPhrasesListResponse,
  SearchPositionsRequest,
  SearchPositionsResponse,
  SearchProductPositionsRequest,
  SearchProductPositionsResponse,
  SupplyNeedsRequest,
  SupplyNeedsResponse,
  SupplyNeedsRowType
}                         from '../types'
import {SearchPhraseType} from '../types'
import {fApiDate}         from '../../../app/utils/formatTime'

const sortByNameCompare = (a: SupplyNeedsRowType | RealisationRowType, b: SupplyNeedsRowType | RealisationRowType) => {
  if (a.link.name < b.link.name) {
    return -1
  }
  if (a.link.name > b.link.name) {
    return 1
  }
  return 0
}

const sortByMoneyIncomesWithOutRedeemsCompare = (a: RealisationRowForAbcType, b: RealisationRowForAbcType) => {
  return b.money_incomes_with_out_redeems - a.money_incomes_with_out_redeems
}

export const productsApi = api.injectEndpoints({
  endpoints: builder => ({
    getSupplyNeeds: builder.query<SupplyNeedsResponse, SupplyNeedsRequest>({
      query: ({ mp_code }) => ({
        url: `/api/report/${mp_code}/supply_needs`,
      }),
      transformResponse: (response: SupplyNeedsResponse) => {
        return {
          ...response,
          rows: response.rows.sort(sortByNameCompare)
        }
      },
    }),
    getRealisation: builder.mutation<RealisationResponse, RealisationRequest>({
      query: ({ mp_code, date_from, date_to, report_id }) => {
        return report_id ? {
          url: `/api/report/${mp_code}/relisation/${report_id}`,
        } : (date_from && date_to) ? {
          url: `/api/report/${mp_code}/relisation?date_from=${fApiDate(date_from)}&date_to=${fApiDate(date_to)}`,
        } : `/api/report/${mp_code}/relisation`
      },
      transformResponse: (response: RealisationResponse) => {
        return {
          ...response,
          rows: response.rows.sort(sortByNameCompare)
        }
      },
    }),
    getRealisationForAbc: builder.mutation<RealisationForAbcResponse, RealisationForAbcRequest>({
      query: ({ mp_code, date_from, date_to }) => ({
        url: `/api/report/${mp_code}/relisation?date_from=${fApiDate(date_from)}&date_to=${fApiDate(date_to)}`,
      }),
      transformResponse: (response: RealisationResponse) => {
        let rows = response.rows.map(({
          link,
          archive,
          categories,
          legal_entity_id,
          sales_count,
          turnover_for_period,
          money_incomes_with_out_redeems
        }) => ({
          link,
          archive,
          categories,
          legal_entity_id,
          sales_count,
          turnover_for_period,
          money_incomes_with_out_redeems
        }))
        return rows.sort(sortByMoneyIncomesWithOutRedeemsCompare)
      },
    }),
    getFeedbacksStatistics: builder.mutation<FeedbackStatisticsResponse, FeedbackStatisticsRequest>({
      query: ({ date_from, date_to }) => ({
        url: `/api/feedbacks/statistics?date_from=${fApiDate(date_from)}&date_to=${fApiDate(date_to)}`,
      })
    }),
    getSearchPhrasesList: builder.query<SearchPhrasesListResponse, SearchPhrasesListRequest>({
      query: ({ mp_code }) => ({
        url: `/api/search/phrases?mp_code=${mp_code}`,
      }),
      transformResponse: (response: SearchPhrasesListResponse) => {
        return response.sort((a: SearchPhraseType, b: SearchPhraseType) => {
          if (a.phrase < b.phrase) {
            return -1
          }
          if (a.phrase > b.phrase) {
            return 1
          }
          return 0
        })
      },
    }),
    getSearchPositions: builder.mutation<SearchPositionsResponse, SearchPositionsRequest>({
      query: ({ mp_code, phrase, date }) => ({
        url: `/api/search/positions/${mp_code}/${phrase}?date=${fApiDate(date)}`,
      })
    }),
    getSearchProductPositions: builder.mutation<SearchProductPositionsResponse, SearchProductPositionsRequest>({
      query: ({ mp_code, product_id, date }) => ({
        url: `/api/search/product_positions/${product_id}/${mp_code}?date=${fApiDate(date)}`,
      })
    })
  })
})

export const {
  useGetSupplyNeedsQuery,
  useGetRealisationMutation,
  useGetRealisationForAbcMutation,
  useGetFeedbacksStatisticsMutation,
  useGetSearchPhrasesListQuery,
  useGetSearchPositionsMutation,
  useGetSearchProductPositionsMutation
} = productsApi
