import type {ChangeEvent}     from 'react'
import {
  useEffect,
  useState
}                             from 'react'
import AppBar                 from '@mui/material/AppBar'
import Toolbar                from '@mui/material/Toolbar'
import Typography             from '@mui/material/Typography'
import Stack                  from '@mui/material/Stack'
import Button                 from '@mui/material/Button'
import LoadingButton          from '@mui/lab/LoadingButton'
import TextField              from '@mui/material/TextField'
import {useSnackbar}          from 'notistack'
import {useAppDispatch}       from '../../../app/store/hooks'
import {setUpdateMarket}      from '../store/marketSlice'
import {
  useGetMarketsListQuery,
  useUpdateMarketMutation
}                             from '../services/marketsApi'
import type {
  MarketFieldType,
  MarketType,
  UpdateMarketRequest
}                             from '../types'
import MarketFieldsSelectChip from './MarketFieldsSelectChip'

const UpdateMarket = ({
  market,
  onClose,
}: {
  market: MarketType;
  onClose: () => void;
}) => {

  const [updateMarket, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useUpdateMarketMutation()

  const { refetch } = useGetMarketsListQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateMarket(data))
      refetch()
    }
  }, [isSuccess, data])

  const [formData, setFormData] = useState<UpdateMarketRequest>({
    id: market.id,
    name: market.name,
    code: market.code,
    fields: market.fields,
    gs_needs_key: market.gs_needs_key
  })

  useEffect(() => {
    setFormData({
      id: market.id,
      name: market.name,
      code: market.code,
      fields: market.fields,
      gs_needs_key: market.gs_needs_key
    })
  }, [market])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const setFields = (fields: MarketFieldType[]) => {
    setFormData({ ...formData, fields })
  }

  const { enqueueSnackbar } = useSnackbar()

  const onSave = () => {
    updateMarket(formData)
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">
            Изменить маркетплейсе
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="name"
          label="Название"
          onChange={onChange}
          defaultValue={market.name}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="code"
          label="Код"
          onChange={onChange}
          defaultValue={market.code}
          disabled={isLoading}
        />
        <MarketFieldsSelectChip
          fields={formData.fields ?? []}
          setFields={setFields}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="gs_needs_key"
          label="ID файла отчета потребностей в Google sheets"
          onChange={onChange}
          defaultValue={market.gs_needs_key}
          disabled={isLoading}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default UpdateMarket