import {
  createSlice,
  PayloadAction
}                       from '@reduxjs/toolkit'
import type {RootState} from '../../../app/store/types'
import {ManagerType}    from '../types'

type ManagerState = {
  openDrawer: boolean;
  updateManager: ManagerType | null;
}

const initialState: ManagerState = {
  openDrawer: false,
  updateManager: null
}

const managerSlice = createSlice({
  name: 'manager',
  initialState,
  reducers: {
    onCloseDrawer: (state) => {
      state.openDrawer = false
      state.updateManager = null
    },
    onCreateManager: (state) => {
      state.openDrawer = true
      state.updateManager = null
    },
    setUpdateManager: (state, action: PayloadAction<ManagerType>) => {
      state.openDrawer = true
      state.updateManager = action.payload
    }
  },
})

export const {
  onCloseDrawer,
  onCreateManager,
  setUpdateManager,
} = managerSlice.actions

export default managerSlice.reducer

export const selectOpenDrawer = (state: RootState) => state.manager.openDrawer
export const selectUpdateManager = (state: RootState) => state.manager.updateManager
