import {
  ChangeEvent,
  useEffect,
  useState
}                               from 'react'
import AppBar                   from '@mui/material/AppBar'
import Toolbar                  from '@mui/material/Toolbar'
import Typography               from '@mui/material/Typography'
import Stack                    from '@mui/material/Stack'
import Button                   from '@mui/material/Button'
import LoadingButton            from '@mui/lab/LoadingButton'
import {
  Card,
  CardContent,
  TextField
}                               from '@mui/material'
import Box                      from '@mui/material/Box'
import Tabs                     from '@mui/material/Tabs'
import Tab                      from '@mui/material/Tab'
import Avatar                   from '@mui/material/Avatar'
import {useSnackbar}            from 'notistack'
import {MarketCodeType}         from '../../../markets/types'
import {useGetMarketsListQuery} from '../../../markets/services/marketsApi'
import LoadingBox               from '../../../../app/components/LoadingBox'
import {
  useAppDispatch,
  useAppSelector
}                               from '../../../../app/store/hooks'
import TabPanel                 from '../../../../app/components/TabPanel'
import useTabs                  from '../../../../app/hooks/useTabs'
import {setUpdateProduct}       from '../../store/productSlice'
import {selectLinked}           from '../../store/productsSlice'
import {
  CreateProductRequest,
  useCreateProductMutation,
  useGetProductsListQuery
}                               from '../../services/productsApi'

const allProps = (index: MarketCodeType, label: string) => ({
  'id': `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
  'icon': <Avatar src={`/img/mp/${index}.png`} variant="square" sx={{ height: 24, width: 24 }}/>,
  'label': <Typography noWrap>{label}</Typography>,
  'value': index
})

const CreateProduct = ({ onClose }: { onClose: () => void; }) => {

  const [createProduct, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useCreateProductMutation()

  const linked = useAppSelector(selectLinked)

  const { refetch } = useGetProductsListQuery({ linked })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateProduct(data))
      refetch()
    }
  }, [isSuccess, data])

  const { data: markets, isLoading: isLoadingMarkets } = useGetMarketsListQuery()

  const [formData, setFormData] = useState<CreateProductRequest>({
    name: '',
    mp_products: []
  })

  const { currentTab, onChangeTab } = useTabs<MarketCodeType>('wb')

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const onChangeMpProducts = (code: MarketCodeType, name: string, value: string | number | boolean) => {
    let mpProduct: any = formData.mp_products.filter(({ mp_code }) => mp_code == code)[0]
    if (mpProduct) {
      const other = formData.mp_products.filter(({ mp_code }) => mp_code != code)
      setFormData({
        ...formData, mp_products: [...other, {
          ...mpProduct,
          [name]: value
        }]
      })
    } else {
      setFormData({
        ...formData, mp_products: [...formData.mp_products, {
          mp_code: code,
          [name]: value
        }]
      })
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const onSave = () => {
    if (formData.name.length > 0) {
      createProduct(formData)
    } else {
      enqueueSnackbar('Название продукта не может быть пустой строкой.', { variant: 'error' })
    }
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">
            Создать новый товар
          </Typography>
        </Toolbar>
      </AppBar>
      <LoadingBox isLoading={isLoadingMarkets}>
        <Stack flexGrow={1} sx={{ px: 3, pt: 4 }} spacing={3}>
          <TextField
            fullWidth
            variant="outlined"
            name="name"
            label="Название товара"
            onChange={onChange}
            disabled={isLoading}
          />
          <Card sx={{ flexGrow: 1 }}>
            <Box sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}>
              <Tabs
                value={currentTab}
                onChange={onChangeTab}
                variant="fullWidth"
                aria-label="Вкладки маркетплейсов"
                TabIndicatorProps={{ style: { height: '6px' } }}
                allowScrollButtonsMobile
              >
                {markets && markets.map(({ id, code, name }) => (
                  <Tab key={id} {...allProps(code, name)} />
                ))}
              </Tabs>
            </Box>
            <CardContent>
              {markets && markets.map(({ code }, key) => (
                <TabPanel key={key} value={currentTab} index={code}>
                  <Stack direction="row" spacing={3}>
                    <Stack direction="row" spacing={2}>
                      <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        name="sku"
                        label="SKU"
                        sx={{ width: 240 }}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(code, e.target.name, e.target.value)}
                        disabled={isLoading}
                      />
                      {code == 'wb' && (
                        <TextField
                          fullWidth
                          size="small"
                          variant="outlined"
                          name="size"
                          label="Размер"
                          sx={{ width: 140 }}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(code, e.target.name, e.target.value)}
                          disabled={isLoading}
                        />
                      )}
                    </Stack>
                    <Box flexGrow={1}/>
                    {/*<FormControlLabel*/}
                    {/*  control={(*/}
                    {/*    <Checkbox*/}
                    {/*      name="archive"*/}
                    {/*      defaultChecked={false}*/}
                    {/*      onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeMpProducts(code, e.target.name, e.target.checked)}*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*  label="Отметить как архивные"*/}
                    {/*/>*/}
                  </Stack>
                </TabPanel>
              ))}
            </CardContent>
          </Card>
        </Stack>
      </LoadingBox>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default CreateProduct