import IconButton                  from '@mui/material/IconButton'
import Link                        from '@mui/material/Link'
import TableRow                    from '@mui/material/TableRow'
import Typography                  from '@mui/material/Typography'
import IconPenToSquare             from '../../../../app/components/icons/IconPenToSquare'
import {PERMISSION_MANAGER_UPDATE} from '../../../../app/contants/permissions'
import {useAppDispatch}            from '../../../../app/store/hooks'
import useAllow                    from '../../../auth/hooks/useAllow'
import type {ManagerType}          from '../../types'
import {setUpdateManager}          from '../../store/managerSlice'
import {StyledTableCell}           from './ManagersTable'

const ManagersTableRow = ({
  manager
}: {
  manager: ManagerType
}) => {

  const allowUpdate = useAllow([PERMISSION_MANAGER_UPDATE])

  const dispatch = useAppDispatch()

  const onUpdate = () => {
    dispatch(setUpdateManager(manager))
  }

  return (
    <TableRow hover tabIndex={-1}>
      <StyledTableCell>
        {allowUpdate ? (
          <Link onClick={onUpdate} underline="hover">
            {manager.name}
          </Link>
        ) : (
          <Typography variant="inherit">
            {manager.name}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell align="right" padding="none">
        {allowUpdate && (
          <IconButton color="primary" onClick={onUpdate}>
            <IconPenToSquare/>
          </IconButton>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

export default ManagersTableRow