import Link                                 from '@mui/material/Link'
import TableRow                             from '@mui/material/TableRow'
import Typography                           from '@mui/material/Typography'
import {useAppDispatch}                     from '../../../../../app/store/hooks'
import {PERMISSION_PRODUCT_CATEGORY_UPDATE} from '../../../../../app/contants/permissions'
import useAllow                             from '../../../../auth/hooks/useAllow'
import {setUpdateProductCategory}           from '../../../store/productCategorySlice'
import type {ProductCategoryType}           from '../../../types'
import UpdateProductCategoryButton          from './UpdateProductCategoryButton'
import DeleteProductCategoryButton          from './DeleteProductCategoryButton'
import {StyledTableCell}                    from './ProductCategoriesTable'

const ProductCategoriesTableRow = ({
  productCategory
}: {
  productCategory: ProductCategoryType
}) => {

  const allowUpdate = useAllow([PERMISSION_PRODUCT_CATEGORY_UPDATE])

  const dispatch = useAppDispatch()

  const onUpdate = () => {
    dispatch(setUpdateProductCategory(productCategory))
  }

  return (
    <TableRow hover tabIndex={-1}>
      <StyledTableCell>
        {allowUpdate ? (
          <Link onClick={onUpdate} underline="hover">
            {productCategory.name}
          </Link>
        ) : (
          <Typography variant="inherit">
            {productCategory.name}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell align="right" padding="none">
        <UpdateProductCategoryButton productCategory={productCategory}/>
        <DeleteProductCategoryButton id={productCategory.id}/>
      </StyledTableCell>
    </TableRow>
  )
}

export default ProductCategoriesTableRow