import {styled} from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import TableHead from '@mui/material/TableHead'
import {useGetLegalEntitiesListQuery} from '../../services/legalEntitiesApi'
import LegalEntitiesTableRow from './LegalEntitiesTableRow'
import LegalEntitiesTableSkeleton from './LegalEntitiesTableSkeleton'

export const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.background.paper,
  }
}))

const LegalEntitiesTable = () => {

  const {data, isLoading, isFetching} = useGetLegalEntitiesListQuery()

  return (
    <Paper sx={{width: '100%', mt: 2, mb: 4, p: 2, pb: 0}}>
      <TableContainer>
        <Table aria-labelledby="legal-entities-list-table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Название юридического лица
                </Typography>
              </StyledTableCell>
              <StyledTableCell/>
              <StyledTableCell>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  WB Токен типа Стандартный
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  WB Токен типа Реклама
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  WB Токен типа Статистика
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Ozon ID клиента
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Ozon API-ключ
                </Typography>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Идентификатор бизнеса
                </Typography>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Идентификатор кампании
                </Typography>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  YM OAuth ID клиента
                </Typography>
                <Typography color="text.secondary" fontSize={14} noWrap>
                  YM OAuth Токен
                </Typography>
              </StyledTableCell>
              <StyledTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isFetching ? (
              <LegalEntitiesTableSkeleton/>
            ) : data && data.map((legalEntity) => (
              <LegalEntitiesTableRow key={legalEntity.id} legalEntity={legalEntity}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default LegalEntitiesTable
