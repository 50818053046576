import {SyntheticEvent} from 'react'
import {
  Link as RouterLink,
  useLocation,
  useNavigate
}                       from 'react-router-dom'
import MuiAppBar        from '@mui/material/AppBar'
import Toolbar          from '@mui/material/Toolbar'
import Link             from '@mui/material/Link'
import Box              from '@mui/material/Box'
import Typography       from '@mui/material/Typography'
import Container        from '@mui/material/Container'
import Tabs             from '@mui/material/Tabs'
import Tab              from '@mui/material/Tab'
import {
  alpha,
  styled
}                       from '@mui/material/styles'
import ReportsMenu      from '../../features/reports/components/ReportsMenu'
import LogoutButton     from '../../features/auth/components/LogoutButton'
import {selectUsername} from '../../features/auth/store/authSlice'
import useAllow         from '../../features/auth/hooks/useAllow'
import ProductsMenu     from '../../features/products/components/ProductsMenu'
import ManufactureMenu  from '../../features/manufacture/components/ManufactureMenu'
import {useAppSelector} from '../store/hooks'
import {
  PERMISSION_MANUFACTURE_ORDER_CREATE,
  PERMISSION_MANUFACTURE_ORDERS_LIST,
  PERMISSION_PRODUCTS_LIST,
  PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
  PERMISSION_REPORTS_REALISATION_VIEW,
  PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW,
  PERMISSION_REVIEWS_VIEW
}                       from '../contants/permissions'
import roles            from '../contants/roles'
import AdminMenu        from './AdminMenu'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)',
  backgroundColor: alpha(theme.palette.background.default, 0.8),
}))

const AppHeader = () => {

  const username = useAppSelector(selectUsername)
  const { pathname } = useLocation()

  const navigate = useNavigate()

  const onChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue)
  }

  const viewReviewsAllow = useAllow([
    PERMISSION_REVIEWS_VIEW,
  ])

  const viewProductsAllow = useAllow([
    PERMISSION_PRODUCTS_LIST,
  ])

  const viewReportsAllow = useAllow([
    PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW,
    PERMISSION_REPORTS_REALISATION_VIEW,
    PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW
  ])

  const adminAllow = useAllow(roles.admin)

  const viewManufactureAllow = useAllow([
    PERMISSION_MANUFACTURE_ORDERS_LIST,
    PERMISSION_MANUFACTURE_ORDER_CREATE
  ])

  return (
    <AppBar position="sticky" color="transparent" elevation={2}>
      <Toolbar>
        <Link component={RouterLink} to={'/'} sx={{ py: 2 }}>
          <img src="/img/logo.png" alt="logo" height={50}/>
        </Link>
        <Box sx={{ flexGrow: 1 }}/>
        <Typography
          variant="button"
          component="div"
          sx={{ mr: 2 }}
        >
          {username}
        </Typography>
        <LogoutButton edge="end"/>
      </Toolbar>
      <Container maxWidth={false}>
        <Box sx={{ borderTop: 1, borderColor: 'divider' }}>
          <Tabs
            value={pathname.split('/')[1]}
            onChange={onChange}
            aria-label="Навигация по главной странице"
            TabIndicatorProps={{ style: { height: '6px' } }}
          >
            {viewProductsAllow && <Tab label={<ProductsMenu/>} value="products" sx={{ p: 0 }}/>}
            {viewReportsAllow && <Tab label={<ReportsMenu/>} value="reports" sx={{ p: 0 }}/>}
            {viewReviewsAllow && <Tab label="Отзывы" value="reviews"/>}
            {adminAllow && <Tab label={<AdminMenu/>} value="admin" sx={{ p: 0 }}/>}
            {viewManufactureAllow && <Tab label={<ManufactureMenu/>} value="manufacture" sx={{ p: 0 }}/>}
          </Tabs>
        </Box>
      </Container>
    </AppBar>
  )
}

export default AppHeader