import {
  useEffect,
  useState
}                                 from 'react'
import Paper                      from '@mui/material/Paper'
import TableContainer             from '@mui/material/TableContainer'
import Table                      from '@mui/material/Table'
import TableHead                  from '@mui/material/TableHead'
import TableBody                  from '@mui/material/TableBody'
import TableRow                   from '@mui/material/TableRow'
import Typography                 from '@mui/material/Typography'
import Link                       from '@mui/material/Link'
import {
  fCurrency,
  fPcs,
  fPercentRound
}                                 from '../../../../../app/utils/formatNumber'
import {RealisationRowForAbcType} from '../../../types'
import StyledTableCell            from '../../table/styled/StyledTableCell'

type AbcRowType = {
  'abc': string;
  'turnover_amount': number;
  'turnover_percent': number;
  'products_pcs': number;
  'products_percent': number;
}

type ItemType = {
  'abc': string;
  link: {
    name: string;
    link: string;
  };
  sales_count: number;
  turnover_for_period: number;
  money_incomes_with_out_redeems: number;
}

const AbcAnalysisTable = ({ rows }: { rows: RealisationRowForAbcType[] }) => {

  const [abcRows, setAbcRows] = useState<AbcRowType[]>([])
  const [items, setItems] = useState<ItemType[]>([])

  useEffect(() => {
    let newAbcRows: AbcRowType[] = [
      {
        'abc': 'AA',
        'turnover_amount': 0,
        'turnover_percent': 0,
        'products_pcs': 0,
        'products_percent': 0
      },
      {
        'abc': 'AB',
        'turnover_amount': 0,
        'turnover_percent': 0,
        'products_pcs': 0,
        'products_percent': 0
      },
      {
        'abc': 'AC',
        'turnover_amount': 0,
        'turnover_percent': 0,
        'products_pcs': 0,
        'products_percent': 0
      },
      {
        'abc': 'B',
        'turnover_amount': 0,
        'turnover_percent': 0,
        'products_pcs': 0,
        'products_percent': 0
      },
      {
        'abc': 'C',
        'turnover_amount': 0,
        'turnover_percent': 0,
        'products_pcs': 0,
        'products_percent': 0
      }
    ]
    const sum = rows.reduce((a, row) => {
      return a + row.money_incomes_with_out_redeems
    }, 0)
    let s = 0
    setItems(rows.map(row => {
      s += row.money_incomes_with_out_redeems
      const p = s / (sum / 100)
      if (p <= 64) {
        newAbcRows[0].turnover_amount += row.turnover_for_period
        newAbcRows[0].products_pcs += 1
        return { ...row, abc: 'AA' }
      } else if (p <= 76) {
        newAbcRows[1].turnover_amount += row.turnover_for_period
        newAbcRows[1].products_pcs += 1
        return { ...row, abc: 'AB' }
      } else if (p <= 80) {
        newAbcRows[2].turnover_amount += row.turnover_for_period
        newAbcRows[2].products_pcs += 1
        return { ...row, abc: 'AC' }
      } else if (p <= 95) {
        newAbcRows[3].turnover_amount += row.turnover_for_period
        newAbcRows[3].products_pcs += 1
        return { ...row, abc: 'B' }
      } else {
        newAbcRows[4].turnover_amount += row.turnover_for_period
        newAbcRows[4].products_pcs += 1
        return { ...row, abc: 'C' }
      }
    }))
    const turnoverAmountSum = newAbcRows.reduce((a, row) => {
      return a + row.turnover_amount
    }, 0)
    const productsPcsSum = rows.length
    setAbcRows(newAbcRows.map(row => ({
      ...row,
      turnover_percent: row.turnover_amount / (turnoverAmountSum / 100),
      products_percent: row.products_pcs / (productsPcsSum / 100)
    })))
  }, [rows])

  return (
    <>
      <Paper sx={{ width: '100%', mb: 4 }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Категория
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Оборот, руб
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Оборот, %
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Продуктов, шт
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Продуктов, %
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {abcRows.map(row => (
                <TableRow>
                  <StyledTableCell>
                    <Typography variant="button" fontSize={14} noWrap>
                      {row.abc}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    {fCurrency(row.turnover_amount)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {fPercentRound(row.turnover_percent)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {fPcs(row.products_pcs)}
                  </StyledTableCell>
                  <StyledTableCell>
                    {fPercentRound(row.products_percent)}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper sx={{ width: '100%', mb: 4 }}>
        <TableContainer>
          <Table aria-labelledby="tableTitle" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Категория
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Название
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    К перечислению за вычетом выкупов
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map(row => (
                <TableRow>
                  <StyledTableCell>
                    <Typography variant="button" fontSize={14} noWrap>
                      {row.abc}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography variant="inherit" noWrap>
                      <Link href={row.link.link} target="_blank">
                        {row.link.name}
                      </Link>
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    {fCurrency(row.money_incomes_with_out_redeems)}
                  </StyledTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

export default AbcAnalysisTable