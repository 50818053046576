import type {PropsWithChildren} from 'react'
import Paper                    from '@mui/material/Paper'
import Skeleton                 from '@mui/material/Skeleton'
import LoadingBox               from '../../../app/components/LoadingBox'
import Stack                    from '@mui/material/Stack'
import Box                      from '@mui/material/Box'

const ReviewsListLoading = ({
  children,
  isLoading,
  isFetching,
  rowsCount
}: PropsWithChildren<{
  isLoading: boolean;
  isFetching: boolean;
  rowsCount: number;
}>) => {
  return (
    <LoadingBox isLoading={isLoading}>
      {isFetching ? (
        <Stack spacing={4}>
          {[...Array(rowsCount)].map((v, i) => (
            <Paper key={i} sx={{ p: 2 }} elevation={4}>
              <Stack direction="row" spacing={1}>
                <Skeleton variant="rectangular" sx={{ height: 80, width: 80 }}/>
                <Box flexGrow={1}>
                  <Skeleton variant="text" sx={{ height: 32, width: 220, mb: 2 }}/>
                  <Skeleton variant="text" sx={{ height: 16, width: '90%' }}/>
                  <Skeleton variant="text" sx={{ height: 16, width: '70%' }}/>
                </Box>
                <Stack alignItems={'end'}>
                  <Skeleton variant="text" sx={{ height: 32, width: 130 }}/>
                  <Stack direction="row" spacing={0.5}>
                    <Skeleton variant="rectangular" sx={{ height: 20, width: 20 }}/>
                    <Skeleton variant="rectangular" sx={{ height: 20, width: 20 }}/>
                    <Skeleton variant="rectangular" sx={{ height: 20, width: 20 }}/>
                    <Skeleton variant="rectangular" sx={{ height: 20, width: 20 }}/>
                    <Skeleton variant="rectangular" sx={{ height: 20, width: 20 }}/>
                  </Stack>
                </Stack>
              </Stack>
              <Skeleton variant="rectangular" sx={{ height: 60, width: '100%', my: 2 }}/>
              <Stack direction="row" justifyContent="space-between">
                <Skeleton variant="rectangular" sx={{ height: 40, width: 180 }}/>
                <Skeleton variant="rectangular" sx={{ height: 40, width: 120 }}/>
              </Stack>
            </Paper>
          ))}
        </Stack>
      ) : children}
    </LoadingBox>
  )
}

export default ReviewsListLoading