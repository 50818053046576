import {
  useEffect,
  useState
}                          from 'react'
import {useAppSelector}    from '../../../app/store/hooks'
import {selectPermissions} from '../store/authSlice'

const useAllow = (permissions: string[]) => {

  const authPermissions = useAppSelector(selectPermissions)

  const [allow, setAllow] = useState<boolean>(false)

  useEffect(() => {
    setAllow(permissions.filter(p => authPermissions.includes(p)).length > 0)
  }, [permissions, authPermissions])

  return allow
}

export default useAllow