import type {ChangeEvent}          from 'react'
import {
  useEffect,
  useState
}                                  from 'react'
import AppBar                      from '@mui/material/AppBar'
import Toolbar                     from '@mui/material/Toolbar'
import Typography                  from '@mui/material/Typography'
import Stack                       from '@mui/material/Stack'
import Button                      from '@mui/material/Button'
import LoadingButton               from '@mui/lab/LoadingButton'
import TextField                   from '@mui/material/TextField'
import {useSnackbar}               from 'notistack'
import {useAppDispatch}            from '../../../app/store/hooks'
import {
  useCreateManagerMutation,
  useGetManagersListQuery
}                                  from '../services/managersApi'
import type {CreateManagerRequest} from '../types'
import {setUpdateManager}          from '../store/managerSlice'

const CreateManager = ({
  onClose,
}: {
  onClose: () => void;
}) => {

  const [createManager, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useCreateManagerMutation()

  const { refetch } = useGetManagersListQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateManager(data))
      refetch()
    }
  }, [isSuccess, data])

  const [formData, setFormData] = useState<CreateManagerRequest>({
    name: '',
  })

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const { enqueueSnackbar } = useSnackbar()

  const onSave = () => {
    createManager(formData)
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">
            Добавить менеджера
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="name"
          label="Имя менеджера"
          onChange={onChange}
          disabled={isLoading}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default CreateManager