import type {ChangeEvent}                            from 'react'
import {
  useEffect,
  useState
}                                                    from 'react'
import Table                                         from '@mui/material/Table'
import TableBody                                     from '@mui/material/TableBody'
import TableContainer                                from '@mui/material/TableContainer'
import Link                                          from '@mui/material/Link'
import Typography                                    from '@mui/material/Typography'
import {
  green,
  red,
  yellow
}                                                    from '@mui/material/colors'
import Stack                                         from '@mui/material/Stack'
import TablePagination                               from '@mui/material/TablePagination'
import Card                                          from '@mui/material/Card'
import {
  InputAdornment,
  InputBase
}                                                    from '@mui/material'
import {
  fNumber,
  fRoundToTenths
}                                                    from '../../../../../app/utils/formatNumber'
import {MANUFACTURE_ORDER_NEEDS_TABLE_ROWS_PER_PAGE} from '../../../../../app/contants/configs'
import useLocalStorage                               from '../../../../../app/hooks/useLocalStorage'
import type {
  SupplyNeedsRowType,
  SupplyNeedsTableHeadCellType,
  SupplyNeedsTableShowColumnsType,
  SupplyNeedsTableShowNeedsType
}                                                    from '../../../../reports/types'
import StyledTableRow                                from '../../../../reports/components/table/styled/StyledTableRow'
import StyledTableCell                               from '../../../../reports/components/table/styled/StyledTableCell'
import type {ManufactureOrderItemsStateType}         from '../../../types'
import NeedsTableHead                                from './NeedsTableHead'
import NeedsTableShowColumns                         from './NeedsTableShowColumns'

const NeedsTable = ({
  rows,
  dateFrom,
  dateTo,
  days,
  showNeeds,
  items,
  changeItem
}: {
  rows: SupplyNeedsRowType[];
  dateFrom: string;
  dateTo: string;
  days: number;
  showNeeds: SupplyNeedsTableShowNeedsType;
  items: ManufactureOrderItemsStateType;
  changeItem: (props: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
    wh_name: string;
    quantity: number;
  }) => void;
}) => {

  const [page, setPage] = useState<number>(0)

  const [rowsPerPage, setRowsPerPage] = useLocalStorage<number>(
    'MANUFACTURE_ORDER_NEEDS_TABLE_ROWS_PER_PAGE',
    MANUFACTURE_ORDER_NEEDS_TABLE_ROWS_PER_PAGE,
    true
  )

  const handleChangePage = (event: unknown, newPage: number) => setPage(newPage)

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [dates, setDates] = useState<string[]>([])

  const [showColumns, setShowColumns] = useLocalStorage<SupplyNeedsTableShowColumnsType>(
    'MANUFACTURE_ORDER_NEEDS_TABLE_SHOW_COLUMNS',
    {
      'name': true,
      'sku': true,
      'whs': true,
      'stock': true,
      'avarage_daily_orders': true,
      'days_stock': true,
      'to_order': true,
      'week_1': true,
      'week_2': true,
      'week_3': true,
      'week_4': true
    },
    true
  )

  useEffect(() => {
    let listDate = []
    let dateMove = new Date(dateFrom)
    let strDate = dateFrom
    while (strDate < dateTo) {
      strDate = dateMove.toISOString().slice(0, 10)
      listDate.push(strDate)
      dateMove.setDate(dateMove.getDate() + 1)
    }
    const newDates = listDate.reverse()
    setDates(newDates)
    setShowColumns(newDates.reduce((a, v) => ({ ...a, [v]: true }), { ...showColumns, in_order: true }))
  }, [dateFrom, dateTo])

  const getToOrder = (toOrder: number, daysStock: number, averageDailyOrders: number) => {
    // если потребность в днях <= days_stock то to_order = 0
    // если потребность в днях > days_stock то to_order = потребность в днях Х average_daily_orders
    if (days == 60) {
      return fNumber(toOrder)
    } else if (days <= daysStock) {
      return 0
    }
    return fNumber(days * averageDailyOrders)
  }

  const headCells: SupplyNeedsTableHeadCellType[] = [
    { key: 'name', label: 'Название', sticky: true },
    { key: 'sku', label: 'SKU', align: 'center' },
    { key: 'whs', label: 'Склад' },
    { key: 'stock', label: 'Остаток', align: 'right' },
    { key: 'avarage_daily_orders', label: 'Ср. расх', align: 'right' },
    { key: 'days_stock', label: 'Дней', align: 'right' },
    { key: 'to_order', label: 'Заказ', align: 'right' },
    { key: 'in_order', label: 'В заказ', align: 'right' },
    { key: 'week_4', label: 'Неделя 4', align: 'right' },
    { key: 'week_3', label: 'Неделя 3', align: 'right' },
    { key: 'week_2', label: 'Неделя 2', align: 'right' },
    { key: 'week_1', label: 'Неделя 1', align: 'right' }
  ]

  const getQuantityValue = ({
    legalEntityId,
    productId,
    whName,
    items
  }: {
    legalEntityId: number;
    productId: number;
    whName: string;
    items: ManufactureOrderItemsStateType;
  }): number | string => {
    if (items[legalEntityId] !== undefined) {
      if (items[legalEntityId][productId] !== undefined) {
        if (items[legalEntityId][productId]['quantity'] !== undefined) {
          if (items[legalEntityId][productId]['quantity'][whName] !== undefined) {
            return items[legalEntityId][productId]['quantity'][whName]
          }
        }
      }
    }
    return ''
  }

  return (
    <Card sx={{ flexGrow: 1, mb: 4 }}>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
        <Table stickyHeader aria-labelledby="tableTitle" size="small">
          <NeedsTableHead showColumns={showColumns} headCells={headCells} dates={dates}/>
          <TableBody>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              const whsRows = Object.entries(row.whs).filter(w => {
                if (w[0] == 'all') {
                  return false
                }
                let show = false
                if (showNeeds == 'all') {
                  show = true
                } else if (w[1].days_stock >= 60) {
                  show = showNeeds == 'green'
                } else if (w[1].days_stock > 30) {
                  show = showNeeds == 'yellow'
                } else {
                  show = showNeeds == 'red'
                }
                return show
              })
              return whsRows.sort().map((whsRow, index) => {
                let color = whsRow[1].days_stock >= 60 ? green['100'] : whsRow[1].days_stock > 30 ? yellow['100'] : red['100']
                return (
                  <StyledTableRow
                    tabIndex={-1}
                    key={index}
                    hover
                  >
                    {index == 0 && (
                      <>
                        {showColumns['name'] && (
                          <StyledTableCell
                            component="th"
                            scope="row"
                            rowSpan={whsRows.length}
                            sx={{
                              position: 'sticky',
                              left: 0,
                              verticalAlign: 'top',
                              minWidth: 400,
                              backgroundColor: '#fff !important',
                              borderRight: '1px solid #e0e0e0',
                              borderTop: '2px solid #878787 !important'
                            }}
                          >
                            <Link href={row.link.link} target="_blank">
                              {row.link.name}
                            </Link>
                          </StyledTableCell>
                        )}
                        {showColumns['sku'] && (
                          <StyledTableCell
                            align="center"
                            rowSpan={whsRows.length}
                            sx={{
                              verticalAlign: 'top',
                              backgroundColor: '#fff !important',
                              borderRight: '1px solid #e0e0e0',
                              borderTop: '2px solid #878787 !important'
                            }}
                          >
                            {row.sku}
                          </StyledTableCell>
                        )}
                      </>
                    )}
                    {showColumns['whs'] && (
                      <StyledTableCell
                        sx={{
                          backgroundColor: color,
                          borderRight: '1px solid #e0e0e0',
                          ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                        }}
                      >
                        <Typography variant="inherit" noWrap>
                          {whsRow[0]}
                        </Typography>
                      </StyledTableCell>
                    )}
                    {showColumns['stock'] && (
                      <StyledTableCell
                        sx={{
                          backgroundColor: color,
                          borderRight: '1px solid #e0e0e0',
                          ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                        }}
                        align="right"
                      >
                        {whsRow[1].stock}
                      </StyledTableCell>
                    )}
                    {showColumns['avarage_daily_orders'] && (
                      <StyledTableCell
                        sx={{
                          backgroundColor: color,
                          borderRight: '1px solid #e0e0e0',
                          ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                        }}
                        align="right"
                      >
                        {fRoundToTenths(whsRow[1].avarage_daily_orders)}
                      </StyledTableCell>
                    )}
                    {showColumns['days_stock'] && (
                      <StyledTableCell
                        sx={{
                          backgroundColor: color,
                          borderRight: '1px solid #e0e0e0',
                          ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                        }}
                        align="right"
                      >
                        {fNumber(whsRow[1].days_stock)}
                      </StyledTableCell>
                    )}
                    {showColumns['to_order'] && (
                      <StyledTableCell
                        sx={{
                          backgroundColor: color,
                          borderRight: '1px solid #e0e0e0',
                          ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                        }}
                        align="right"
                      >
                        {getToOrder(whsRow[1].to_order, whsRow[1].days_stock, whsRow[1].avarage_daily_orders)}
                      </StyledTableCell>
                    )}
                    {showColumns['in_order'] && (
                      <StyledTableCell
                        sx={{
                          borderRight: '1px solid #e0e0e0',
                          ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                        }}
                        align="right"
                      >
                        <InputBase
                          placeholder="0"
                          endAdornment={(
                            <InputAdornment
                              position="end"
                              disableTypography={true}
                              sx={{ m: 0 }}
                            >
                              шт
                            </InputAdornment>
                          )}
                          sx={{ fontSize: 12 }}
                          inputProps={{
                            sx: { textAlign: 'center' }
                          }}
                          value={getQuantityValue({
                            legalEntityId: row.legal_entity_id,
                            productId: row.product_id,
                            whName: whsRow[0],
                            items,
                          })}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => changeItem({
                            legal_entity_id: row.legal_entity_id,
                            product_id: row.product_id,
                            product_name: row.link.name,
                            wh_name: whsRow[0],
                            quantity: Number(event.target.value)
                          })}
                        />
                      </StyledTableCell>
                    )}
                    {
                      showColumns['week_4'] && (
                        <StyledTableCell
                          sx={{
                            backgroundColor: color,
                            borderRight: '1px solid #e0e0e0',
                            ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                          }}
                          align="right"
                        >
                          {whsRow[1].weeks_stat[3] ?? 0}
                        </StyledTableCell>
                      )
                    }
                    {
                      showColumns['week_3'] && (
                        <StyledTableCell
                          sx={{
                            backgroundColor: color,
                            borderRight: '1px solid #e0e0e0',
                            ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                          }}
                          align="right"
                        >
                          {whsRow[1].weeks_stat[2] ?? 0}
                        </StyledTableCell>
                      )
                    }
                    {
                      showColumns['week_2'] && (
                        <StyledTableCell
                          sx={{
                            backgroundColor: color,
                            borderRight: '1px solid #e0e0e0',
                            ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                          }}
                          align="right"
                        >
                          {whsRow[1].weeks_stat[1] ?? 0}
                        </StyledTableCell>
                      )
                    }
                    {
                      showColumns['week_1'] && (
                        <StyledTableCell
                          sx={{
                            backgroundColor: color,
                            borderRight: '1px solid #e0e0e0',
                            ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                          }}
                          align="right"
                        >
                          {whsRow[1].weeks_stat[0] ?? 0}
                        </StyledTableCell>
                      )
                    }
                    {
                      dates.map(date => showColumns[date] && (
                        <StyledTableCell
                          key={date}
                          sx={{
                            backgroundColor: color,
                            borderRight: '1px solid #e0e0e0',
                            ...(index == 0 && { borderTop: '2px solid #878787 !important' })
                          }}
                          align="right"
                        >
                          {whsRow[1].days_stat[date] ?? 0}
                        </StyledTableCell>
                      ))
                    }
                  </StyledTableRow>
                )
              })
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" paddingTop={1}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <NeedsTableShowColumns
          columns={headCells}
          dates={dates}
          showColumns={showColumns}
          setShowColumns={setShowColumns}
        />
      </Stack>
    </Card>
  )
}

export default NeedsTable
