import Toolbar                     from '@mui/material/Toolbar'
import Typography                  from '@mui/material/Typography'
import Box                         from '@mui/material/Box'
import {
  PERMISSION_PRODUCT_CATEGORIES_LIST,
  PERMISSION_PRODUCT_CATEGORY_CREATE
}                                  from '../../../app/contants/permissions'
import AllowGuard                  from '../../auth/guards/AllowGuard'
import CreateProductCategoryButton from '../components/categories/CreateProductCategoryButton'
import ProductCategoriesTable      from '../components/categories/table/ProductCategoriesTable'
import ProductCategoryDrawer       from '../components/categories/ProductCategoryDrawer'

const ProductCategories = () => {
  return (
    <AllowGuard permissions={[PERMISSION_PRODUCT_CATEGORIES_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Категории
        </Typography>
        <Box flexGrow={1}/>
        <AllowGuard permissions={[PERMISSION_PRODUCT_CATEGORY_CREATE]}>
          <CreateProductCategoryButton/>
        </AllowGuard>
      </Toolbar>
      <ProductCategoriesTable/>
      <ProductCategoryDrawer/>
    </AllowGuard>
  )
}

export default ProductCategories