import {
  useEffect,
  useState
}                                           from 'react'
import IconButton                           from '@mui/material/IconButton'
import ConfirmDialog                        from '../../../../../app/components/dialog/ConfirmDialog'
import {PERMISSION_PRODUCT_CATEGORY_DELETE} from '../../../../../app/contants/permissions'
import IconDelete                           from '../../../../../app/components/icons/IconDelete'
import useAllow                             from '../../../../auth/hooks/useAllow'
import {
  useDeleteProductCategoryMutation,
  useGetProductCategoriesListQuery
}                                           from '../../../services/productsApi'

const DeleteProductCategoryButton = ({ id }: { id: number }) => {

  const allowDelete = useAllow([PERMISSION_PRODUCT_CATEGORY_DELETE])

  const [deleteProductCategory, {
    isLoading,
    isSuccess,
    isError
  }] = useDeleteProductCategoryMutation()

  const { refetch } = useGetProductCategoriesListQuery()

  useEffect(() => {
    if (isSuccess) {
      refetch()
    }
  }, [isSuccess])

  const [open, setOpen] = useState<boolean>(false)

  const onConfirmDelete = () => {
    deleteProductCategory({ id })
  }

  return allowDelete ? (
    <>
      <IconButton color="error" onClick={() => setOpen(true)}>
        <IconDelete/>
      </IconButton>
      <ConfirmDialog
        title="Вы уверены, что хотите удалить?"
        Icon={IconDelete}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  ) : <></>
}

export default DeleteProductCategoryButton