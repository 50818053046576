import * as React              from 'react'
import SvgIcon, {SvgIconProps} from '@mui/material/SvgIcon'

const IconAngleUpRegular = ({ fontSize = 'inherit', ...rest }: SvgIconProps) => (
  <SvgIcon viewBox="0 0 384 512" fontSize={fontSize} {...rest}>
    <path
      d="M23.5 294.5l152-143.1C180.1 146.2 186.1 144 192 144s11.88 2.188 16.5 6.562l152 143.1c9.625 9.125 10.03 24.31 .9375 33.93c-9.125 9.688-24.38 10.03-33.94 .9375l-135.5-128.4l-135.5 128.4c-9.562 9.094-24.75 8.75-33.94-.9375C13.47 318.9 13.87 303.7 23.5 294.5z"
    />
  </SvgIcon>
)

export default IconAngleUpRegular