import {
  useEffect,
  useState
}                                 from 'react'
import Button                     from '@mui/material/Button'
import Stack                      from '@mui/material/Stack'
import RolesList                  from './RolesList'
import {RoleType}                 from '../../types'
import {useGetUserRolesListQuery} from '../../services/usersApi'
import LoadingBox                 from '../../../../app/components/LoadingBox'

const not = (a: RoleType[], b: RoleType[]) => a.filter((role) => b.indexOf(role) === -1)

const intersection = (a: RoleType[], b: RoleType[]) => a.filter((role) => b.indexOf(role) !== -1)

const union = (a: RoleType[], b: RoleType[]) => [...a, ...not(b, a)]

const unique = (roles: RoleType[]) => roles.filter((role, index) => {
  return roles.indexOf(role) === index
})

const RolesTransferList = ({
  values,
  setValues
}: {
  values: RoleType[];
  setValues: (newValues: RoleType[]) => void;
}) => {

  const { data, isLoading, isFetching } = useGetUserRolesListQuery()

  const [checked, setChecked] = useState<RoleType[]>([])
  const [rolesList, setRolesList] = useState<RoleType[]>([])

  useEffect(() => {
    if (data) {
      setRolesList(not(data, values))
    }
  }, data)

  const rolesListChecked = intersection(checked, rolesList)
  const valuesChecked = intersection(checked, values)

  const handleToggle = (role: RoleType) => {
    const currentIndex = checked.indexOf(role)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(role)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const numberOfChecked = (roles: RoleType[]): number => intersection(checked, roles).length

  const handleToggleAll = (roles: RoleType[]) => {
    if (numberOfChecked(roles) === roles.length) {
      setChecked(not(checked, roles))
    } else {
      setChecked(union(checked, roles))
    }
  }

  const handleCheckedValues = () => {
    setValues(unique(values.concat(rolesListChecked)))
    setRolesList(not(rolesList, rolesListChecked))
    setChecked(not(checked, rolesListChecked))
  }

  const handleCheckedRolesList = () => {
    setRolesList(unique(rolesList.concat(valuesChecked)))
    setValues(not(values, valuesChecked))
    setChecked(not(checked, valuesChecked))
  }

  return (
    <LoadingBox isLoading={isLoading || isFetching}>
      <Stack direction="row" flexGrow={1} spacing={2} alignItems="stretch">
        <RolesList
          title="Выбор ролей"
          roles={rolesList}
          checked={checked}
          numberOfChecked={numberOfChecked}
          handleToggleAll={handleToggleAll}
          handleToggle={handleToggle}
        />
        <Stack alignItems="center" flexGrow={1}>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedValues}
            disabled={rolesListChecked.length === 0}
            aria-label="move selected values"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRolesList}
            disabled={valuesChecked.length === 0}
            aria-label="move selected roles list"
          >
            &lt;
          </Button>
        </Stack>
        <RolesList
          title="Выбранный"
          roles={values}
          checked={checked}
          numberOfChecked={numberOfChecked}
          handleToggleAll={handleToggleAll}
          handleToggle={handleToggle}
        />
      </Stack>
    </LoadingBox>
  )
}

export default RolesTransferList
