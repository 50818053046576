import {
  Route,
  Routes
}                                     from 'react-router-dom'
import {AdapterDateFns}               from '@mui/x-date-pickers/AdapterDateFns'
import {LocalizationProvider}         from '@mui/x-date-pickers/LocalizationProvider'
import AppLayout                      from './app/components/AppLayout'
import Home                           from './app/routes/Home'
import Admin                          from './app/routes/Admin'
import Users                          from './features/users/routes/Users'
import LegalEntities                  from './features/legalEntities/routes/LegalEntities'
import Markets                        from './features/markets/routes/Markets'
import Managers                       from './features/managers/routes/Managers'
import ProductsRoot                   from './features/products/routes/ProductsRoot'
import Products                       from './features/products/routes/Products'
import ProductCategories              from './features/products/routes/ProductCategories'
import ProductCategoriesSearchPhrases from './features/products/routes/ProductCategoriesSearchPhrases'
import Reviews                        from './features/reviews/routes/Reviews'
import Login                          from './features/auth/routes/Login'
import Reports                        from './features/reports/routes/Reports'
import SupplyNeeds                    from './features/reports/routes/SupplyNeeds'
import Realisation                    from './features/reports/routes/Realisation'
import FeedbackStatistics             from './features/reports/routes/FeedbackStatistics'
import SearchPositions                from './features/reports/routes/SearchPositions'
import AbcAnalysis                    from './features/reports/routes/AbcAnalysis'
import Manufacture                    from './features/manufacture/routes/Manufacture'
import ManufactureOrderCreate         from './features/manufacture/routes/ManufactureOrderCreate'
import ManufactureOrders              from './features/manufacture/routes/ManufactureOrders'
import ManufactureOrderUpdate         from './features/manufacture/routes/ManufactureOrderUpdate'

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Routes>
      <Route path="/" element={<AppLayout/>}>
        <Route index element={<Home/>}/>
        <Route path="admin" element={<Admin/>}>
          <Route path="users" element={<Users/>}/>
          <Route path="markets" element={<Markets/>}/>
          <Route path="legal-entities" element={<LegalEntities/>}/>
          <Route path="managers" element={<Managers/>}/>
        </Route>
        <Route path="products" element={<ProductsRoot/>}>
          <Route index element={<Products/>}/>
          <Route path="categories" element={<ProductCategories/>}/>
          <Route path="categories/search-phrases" element={<ProductCategoriesSearchPhrases/>}/>
        </Route>
        <Route path="reviews" element={<Reviews/>}/>
        <Route path="reports" element={<Reports/>}>
          <Route path="needs/:mpCode" element={<SupplyNeeds/>}/>
          <Route path="realisation/:mpCode" element={<Realisation/>}/>
          <Route path="feedbacks-statistics/:mpCode" element={<FeedbackStatistics/>}/>
          <Route path="search-positions/:mpCode" element={<SearchPositions/>}/>
          <Route path="abc-analysis/:mpCode" element={<AbcAnalysis/>}/>
        </Route>
        <Route path="manufacture" element={<Manufacture/>}>
          <Route index element={<ManufactureOrders/>}/>
          <Route path="orders" element={<ManufactureOrders/>}/>
          <Route path="orders/:mpCode/create" element={<ManufactureOrderCreate/>}/>
          <Route path="orders/:mpCode/update/:orderId" element={<ManufactureOrderUpdate/>}/>
        </Route>
      </Route>
      <Route path="login" element={<Login/>}/>
    </Routes>
  </LocalizationProvider>
)

export default App
