import {Outlet}   from 'react-router-dom'
import roles      from '../contants/roles'
import AllowGuard from '../../features/auth/guards/AllowGuard'

const Admin = () => {
  return (
    <AllowGuard permissions={roles.admin}>
      <Outlet/>
    </AllowGuard>
  )
}

export default Admin