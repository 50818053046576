import type {ChangeEvent} from 'react'
import {useState}         from 'react'
import {useNavigate}      from 'react-router-dom'
import {useDispatch}      from 'react-redux'
import {useSnackbar}      from 'notistack'
import Paper              from '@mui/material/Paper'
import Box                from '@mui/material/Box'
import Typography         from '@mui/material/Typography'
import Stack              from '@mui/material/Stack'
import TextField          from '@mui/material/TextField'
import LoadingButton      from '@mui/lab/LoadingButton'
import {styled}           from '@mui/material/styles'
import {
  AuthRequest,
  useLoginMutation
}                         from '../services/authApi'
import {setAuth}          from '../store/authSlice'

const RootStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2, 3),
  backgroundColor: theme.palette.background.paper
}))

const Login = () => {

  const [credentials, setCredentials] = useState<AuthRequest>({
    username: '',
    password: ''
  })

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCredentials({ ...credentials, [event.target.name]: event.target.value })
  }

  const [login, { isLoading }] = useLoginMutation()
  const dispatch = useDispatch()

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useNavigate()

  const onSubmit = async (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    event.persist()
    try {
      const { access_token } = await login(credentials).unwrap()
      dispatch(setAuth(access_token))
      enqueueSnackbar('Вы успешно вошли в систему', { variant: 'success' })
      navigate('/')
    } catch (error) {
      enqueueSnackbar('Не удалось проверить учетные данные', { variant: 'error' })
    }
  }

  return (
    <RootStyled>
      <Paper
        sx={{
          width: 400,
          maxWidth: '100%',
          px: 4,
          py: 6
        }}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={onSubmit}
        >
          <Stack spacing={5}>
            <Typography variant="h3" align="center" noWrap>
              Авторизация
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              name="username"
              label="Логин"
              onChange={onChange}
            />
            <TextField
              fullWidth
              type="password"
              variant="outlined"
              name="password"
              label="Пароль"
              onChange={onChange}
            />
            <LoadingButton
              type="submit"
              size="large"
              variant="contained"
              loading={isLoading}
              sx={{ height: 56 }}
            >
              Войти
            </LoadingButton>
          </Stack>
        </Box>
      </Paper>
    </RootStyled>
  )
}

export default Login
