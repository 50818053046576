import {
  SyntheticEvent,
  useEffect,
  useState
}                                         from 'react'
import Toolbar                            from '@mui/material/Toolbar'
import Typography                         from '@mui/material/Typography'
import Box                                from '@mui/material/Box'
import Tabs                               from '@mui/material/Tabs'
import Tab                                from '@mui/material/Tab'
import useTabs                            from '../../../app/hooks/useTabs'
import TabPanel                           from '../../../app/components/TabPanel'
import NeedsTable                         from '../components/needs/table/NeedsTable'
import {MarketCodeType}                   from '../../markets/types'
import {useGetSupplyNeedsQuery}           from '../../reports/services/reportsApi'
import useLocalStorage                    from '../../../app/hooks/useLocalStorage'
import {
  SupplyNeedsRowType,
  SupplyNeedsTableFiltersType,
  WhsType
}                                         from '../../reports/types'
import LoadingBox                         from '../../../app/components/LoadingBox'
import NeedsFiltersDrawer                 from '../components/needs/NeedsFiltersDrawer'
import Paper                              from '@mui/material/Paper'
import Stack                              from '@mui/material/Stack'
import LoadingButton                      from '@mui/lab/LoadingButton'
import {
  CreateManufactureOrderRequest,
  GetManufactureOrderResponse,
  GetManufactureOrdersListRequest,
  ManufactureOrderItem,
  ManufactureOrderItemsStateType,
  UpdateManufactureOrderRequest
}                                         from '../types'
import ManufactureStatusesSelect          from '../components/ManufactureStatusesSelect'
import Divider                            from '@mui/material/Divider'
import TextField                          from '@mui/material/TextField'
import MatrixTabPanel                     from '../components/matrix/MatrixTabPanel'
import MatrixExportOrderInfoButton        from '../components/matrix/MatrixExportOrderInfoButton'
import {useGetWarehousesListQuery}        from '../../warehouses/services/warehousesApi'
import {useSnackbar}                      from 'notistack'
import {formatISO9075}                    from 'date-fns'
import {useGetManufactureOrdersListQuery} from '../services/manufacturesApi'

const ManufactureOrderForm = ({
  mpCode,
  order,
  create,
  update,
  isLoading
}: {
  mpCode: MarketCodeType;
  order?: GetManufactureOrderResponse
  create?: (props: CreateManufactureOrderRequest) => void;
  update?: (props: UpdateManufactureOrderRequest) => void;
  isLoading: boolean;
}) => {

  const { currentTab, onChangeTab } = useTabs<number>(0)

  const { enqueueSnackbar } = useSnackbar()

  const {
    data: supplyNeedData,
    isLoading: isLoadingSupplyNeed,
    isFetching: isFetchingSupplyNeed,
    isSuccess: isSuccessSupplyNeed
  } = useGetSupplyNeedsQuery({ mp_code: mpCode })

  const [whsOptions, setWhsOptions] = useState<string[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (supplyNeedData) {
      setLoading(true)
      let newWhsOptions: string[] = []
      supplyNeedData.rows.forEach(({ whs }) => {
        newWhsOptions = [...newWhsOptions, ...Object.keys(whs)]
      })
      setWhsOptions(Array.from(new Set(newWhsOptions)))
    }
  }, [supplyNeedData])

  const [filters, setFilters] = useLocalStorage<SupplyNeedsTableFiltersType>(
    'MANUFACTURE_ORDER_NEEDS_TABLE_FILTERS',
    {
      legal_entity_id: 0,
      manager_id: 0,
      categories: [],
      archive: false,
      whs: [],
      days: 60,
      showNeeds: 'all'
    },
    true
  )

  useEffect(() => {
    if (supplyNeedData) {
      let supplyNeedDataRows = []
      if (filters.categories.slice(-1)[0] === 0) {
        supplyNeedDataRows = supplyNeedData.rows.filter((row) => (
          row.categories.length == 0 && (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (filters.manager_id == 0 && row.manager_id !== null) ||
            (filters.manager_id == 'none' && row.manager_id == null) ||
            filters.manager_id == row.manager_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      } else if (filters.categories.length > 0) {
        supplyNeedDataRows = supplyNeedData.rows.filter((row) => {
          if (
            (
              filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
            ) && (
              (filters.manager_id == 0 && row.manager_id !== null) ||
              (filters.manager_id == 'none' && row.manager_id == null) ||
              filters.manager_id == row.manager_id
            ) && (
              (!filters.archive && !row.archive) || filters.archive
            )
          ) {
            for (let cat of row.categories) {
              if (filters.categories.includes(cat.id)) {
                return true
              }
            }
          }
          return false
        })
      } else {
        supplyNeedDataRows = supplyNeedData.rows.filter((row) => (
          (
            filters.legal_entity_id == 0 || row.legal_entity_id == filters.legal_entity_id
          ) && (
            (filters.manager_id == 0 && row.manager_id !== null) ||
            (filters.manager_id == 'none' && row.manager_id == null) ||
            filters.manager_id == row.manager_id
          ) && (
            (!filters.archive && !row.archive) || filters.archive
          )
        ))
      }
      if (filters.whs.length > 0) {
        supplyNeedDataRows = supplyNeedDataRows.map((row) => {
          let newWhs: WhsType = {}
          Object.entries(row.whs).forEach(([key, value]) => {
            if (filters.whs.includes(key)) {
              newWhs[key] = value
            }
          })
          return { ...row, whs: newWhs }
        })
      }
      setRows(supplyNeedDataRows)
      setLoading(false)
    }
  }, [whsOptions, filters])

  const [rows, setRows] = useState<SupplyNeedsRowType[]>([])

  const [items, setItems] = useState<ManufactureOrderItemsStateType>({})

  useEffect(() => {
    if (order !== undefined) {
      let newItems: ManufactureOrderItemsStateType = {}
      order.items.forEach(item => {
        let legalEntityIdItems = {}
        let productIdItems = {}
        let quantityItems = {}
        if (newItems[item.legal_entity_id] !== undefined) {
          legalEntityIdItems = newItems[item.legal_entity_id]
          if (newItems[item.legal_entity_id][item.product_id] !== undefined) {
            productIdItems = newItems[item.legal_entity_id][item.product_id]
            if (newItems[item.legal_entity_id][item.product_id]['quantity'] !== undefined) {
              quantityItems = newItems[item.legal_entity_id][item.product_id]['quantity']
            }
          }
        }
        newItems = {
          ...newItems,
          [item.legal_entity_id]: {
            ...legalEntityIdItems,
            [item.product_id]: {
              ...productIdItems,
              product_id: item.product_id,
              product_name: item.name,
              quantity: {
                ...quantityItems,
                [item.wh_name]: item.quantity
              }
            }
          }
        }
      })
      setItems(newItems)
    }
  }, [order])

  const changeItem = ({
    legal_entity_id,
    product_id,
    product_name,
    wh_name,
    quantity
  }: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
    wh_name: string;
    quantity: number;
  }) => {
    let legalEntityIdItems = {}
    let productIdItems = {}
    let quantityItems = {}
    if (items[legal_entity_id] !== undefined) {
      legalEntityIdItems = items[legal_entity_id]
      if (items[legal_entity_id][product_id] !== undefined) {
        productIdItems = items[legal_entity_id][product_id]
        if (items[legal_entity_id][product_id]['quantity'] !== undefined) {
          quantityItems = items[legal_entity_id][product_id]['quantity']
        }
      }
    }
    const newItems = {
      ...items,
      [legal_entity_id]: {
        ...legalEntityIdItems,
        [product_id]: {
          ...productIdItems,
          product_id: product_id,
          product_name: product_name,
          quantity: {
            ...quantityItems,
            [wh_name]: quantity
          }
        }
      }
    }
    setItems(newItems)
  }

  const addProduct = ({
    legal_entity_id,
    product_id,
    product_name
  }: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
  }) => {
    const newItems = {
      ...items,
      [legal_entity_id]: {
        ...(items[legal_entity_id] !== undefined ? items[legal_entity_id] : {}),
        [product_id]: {
          product_id: product_id,
          product_name: product_name,
          quantity: {}
        }
      }
    }
    setItems(newItems)
  }

  const addWarehouse = ({
    legal_entity_id,
    wh_name
  }: {
    legal_entity_id: number;
    wh_name: string;
  }) => {
    if (items[legal_entity_id] !== undefined) {
      let newItems: ManufactureOrderItemsStateType = {}
      for (const productId in items[legal_entity_id]) {
        newItems = {
          ...items,
          [legal_entity_id]: {
            ...items[legal_entity_id],
            [productId]: {
              ...items[legal_entity_id][productId],
              quantity: {
                ...items[legal_entity_id][productId]['quantity'],
                [wh_name]: 0
              }
            }
          }
        }
      }
      setItems(newItems)
    }
  }

  const { data: warehousesList } = useGetWarehousesListQuery({ mp_code: mpCode })

  const [params, setParams] = useLocalStorage<GetManufactureOrdersListRequest>(
    'MANUFACTURE_ORDERS_TABLE_PARAMS',
    {
      order: 'desc',
      limit: 5,
      offset: 0
    },
    true
  )
  const { refetch: refetchOrderList } = useGetManufactureOrdersListQuery(params)

  const onSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (warehousesList) {
      const form = event.currentTarget
      const formElements = form.elements as typeof form.elements & {
        status_id: { value: string };
        comment: { value: string };
      }
      if (formElements.status_id.value.length == 0) {
        enqueueSnackbar('Выберите статус.', { variant: 'error' })
      }
      const whIds: Record<string, number> = warehousesList.reduce((ids, wh) => ({ ...ids, [wh.name]: wh.id }), {})
      let orderItems: ManufactureOrderItem[] = []
      for (const legalEntityId in items) {
        for (const productId in items[legalEntityId]) {
          for (const whName in items[legalEntityId][productId]['quantity']) {
            if (whIds[whName] !== undefined) {
              if (items[legalEntityId][productId]['quantity'][whName] !== 0) {
                orderItems.push({
                  product_id: Number(productId),
                  quantity: items[legalEntityId][productId]['quantity'][whName],
                  warehouse_id: whIds[whName],
                  mp_code: mpCode
                })
              }
            }
          }
        }
      }
      if (orderItems.length > 0) {
        if (order !== undefined && update !== undefined) {
          update({
            id: order.id,
            date_time: order.date_time,
            status_id: Number(formElements.status_id.value),
            comment: formElements.comment.value,
            items: orderItems
          })
        } else if (create !== undefined) {
          create({
            date_time: formatISO9075(new Date()),
            status_id: Number(formElements.status_id.value),
            comment: formElements.comment.value,
            items: orderItems
          })
        }
      } else {
        enqueueSnackbar('Заказ на производство пуст.', { variant: 'error' })
      }
    }
  }

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          {order ? 'Изменить' : 'Создать'} производственный заказ
        </Typography>
        <Box flexGrow={1}/>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
        >
          Сохранить
        </LoadingButton>
      </Toolbar>
      <Paper sx={{ width: '100%', p: 2, mb: 3 }}>
        <ManufactureStatusesSelect
          label="Выберите статус"
          size="small"
          name="status_id"
          sx={{
            minWidth: 320,
            maxWidth: '100%',
          }}
          defaultValue={order ? order.status_id : ''}
        />
        <Divider sx={{ mt: 3, mb: 2 }}/>
        <Stack direction="row" marginBottom={2}>
          <Tabs
            value={currentTab}
            onChange={onChangeTab}
            aria-label="Создать заказ"
            TabIndicatorProps={{ style: { height: '6px' } }}
            allowScrollButtonsMobile
          >
            <Tab label="Потребности"/>
            <Tab label="Матрица"/>
          </Tabs>
          <Box flexGrow={1}/>
          <TabPanel value={currentTab} index={0}>
            <NeedsFiltersDrawer
              filters={filters}
              setFilters={setFilters}
              whsOptions={whsOptions}
              disabled={isLoadingSupplyNeed}
            />
          </TabPanel>
          <TabPanel value={currentTab} index={1}>
            <MatrixExportOrderInfoButton
              items={items}
            />
          </TabPanel>
        </Stack>
        <LoadingBox
          isLoading={isLoadingSupplyNeed || isFetchingSupplyNeed || loading}
          text={!supplyNeedData ? 'Загрузка данных...' : 'Форматирование данных...'}
        >
          <TabPanel value={currentTab} index={0} sx={{ p: 0 }}>
            {supplyNeedData && rows.length > 0 && (
              <NeedsTable
                rows={rows}
                dateFrom={supplyNeedData.date_from}
                dateTo={supplyNeedData.date_to}
                days={filters.days}
                showNeeds={filters.showNeeds}
                items={items}
                changeItem={changeItem}
              />
            )}
          </TabPanel>
          <TabPanel value={currentTab} index={1} sx={{ p: 0 }}>
            <MatrixTabPanel
              mpCode={mpCode}
              items={items}
              changeItem={changeItem}
              addProduct={addProduct}
              addWarehouse={addWarehouse}
            />
          </TabPanel>
        </LoadingBox>
        <TextField
          name="comment"
          label="Комментарий"
          size="small"
          multiline
          fullWidth
          rows={2}
          maxRows={4}
          sx={{ mb: 2 }}
          defaultValue={order ? order.comment : ''}
        />
      </Paper>
    </Box>
  )
}

export default ManufactureOrderForm