import {
  useEffect,
  useState
}                                             from 'react'
import Paper                                  from '@mui/material/Paper'
import TableContainer                         from '@mui/material/TableContainer'
import Table                                  from '@mui/material/Table'
import TableHead                              from '@mui/material/TableHead'
import TableRow                               from '@mui/material/TableRow'
import Typography                             from '@mui/material/Typography'
import TableBody                              from '@mui/material/TableBody'
import Drawer                                 from '@mui/material/Drawer'
import LoadingBox                             from '../../../../app/components/LoadingBox'
import {MarketCodeType}                       from '../../../markets/types'
import {useGetSearchProductPositionsMutation} from '../../services/reportsApi'
import {SearchPositionsResponse}              from '../../types'
import StyledTableCell                        from './styled/StyledTableCell'
import StyledTableRow                         from './styled/StyledTableRow'

const SearchPositionsTable = ({
  mpCode,
  date,
  rows
}: {
  mpCode: MarketCodeType;
  date: string;
  rows: SearchPositionsResponse;
}) => {

  const [product, setProduct] = useState<{
    id: number;
    name: string;
  } | null>(null)

  const [getSearchProductPositions, {
    data,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useGetSearchProductPositionsMutation()

  useEffect(() => {
    if (product !== null) {
      getSearchProductPositions({
        mp_code: mpCode,
        product_id: product.id,
        date: date
      })
    }
  }, [product])

  return (
    <>
      <Paper sx={{ width: '100%', mb: 4, p: 2 }}>
        <TableContainer
          sx={{
            maxHeight: 'calc(100vh - 300px)',
            border: '1px solid #e0e0e0',
            borderBottom: 'none'
          }}
        >
          <Table stickyHeader aria-labelledby="tableTitle" size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Позиция
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  <Typography color="text.secondary" fontSize={14} noWrap>
                    Продукт
                  </Typography>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(({ sku, name, product_id, position }, key) => (
                <StyledTableRow
                  key={key}
                  onClick={() => setProduct({
                    id: product_id,
                    name: name
                  })}
                  sx={{ cursor: 'pointer' }}
                >
                  <StyledTableCell align="left">
                    {position}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {name}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Drawer
        anchor="right"
        open={Boolean(product)}
        onClose={() => setProduct(null)}
        PaperProps={{ sx: { width: 880, maxWidth: '100%' } }}
      >
        <LoadingBox isLoading={isLoading}>
          <TableContainer sx={{ height: '100vh', pb: 4 }}>
            <Table stickyHeader aria-labelledby="tableTitle" size="small">
              <TableHead>
                <TableRow>
                  <StyledTableCell colSpan={2}>
                    <Typography color="text.secondary" fontSize={24} noWrap>
                      Позиции товара по запросам
                    </Typography>
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell>
                    <Typography color="text.secondary" fontSize={14} noWrap sx={{ pt: 2 }}>
                      Позиция
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell>
                    <Typography color="text.secondary" fontSize={14} noWrap sx={{ pt: 2 }}>
                      Запрос
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.map(({ sku, phrase, position }, key) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell align="left">
                      {position ?? 0}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {phrase}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </LoadingBox>
      </Drawer>
    </>
  )
}

export default SearchPositionsTable