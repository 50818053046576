import {
  useCallback,
  useState
}                 from 'react'
import useStorage from './useStorage'

const useLocalStorage = <T> (
  key: string,
  initialValue: T,
  autoSave?: boolean
): [T, (value: T) => void, () => void, boolean] => {

  const { getItem, setItem } = useStorage('local')

  const [value, setStoredValue] = useState<T>(() => getItem<T>(key, initialValue))
  const [savedValue, setSavedValue] = useState<boolean>(true)

  const setValue = (value: T) => {
    setStoredValue(value)
    setSavedValue(!!autoSave)
    if (autoSave) {
      setItem<T>(key, value)
    }
  }

  const saveValue = useCallback(() => {
    setItem<T>(key, value)
    setSavedValue(true)
  }, [value])

  return [value, setValue, saveValue, savedValue]
}

export default useLocalStorage