import {useSnackbar}                    from 'notistack'
import Button                           from '@mui/material/Button'
import IconDownload                     from '../../../../app/components/icons/IconDownload'
import {useGetLegalEntitiesListQuery}   from '../../../legalEntities/services/legalEntitiesApi'
import {ManufactureOrderItemsStateType} from '../../types'

const MatrixExportOrderInfoButton = ({
  items
}: {
  items: ManufactureOrderItemsStateType;
}) => {

  const { data: legalEntities } = useGetLegalEntitiesListQuery()

  const { enqueueSnackbar } = useSnackbar()

  const onExportOrderInfo = () => {
    if (legalEntities) {
      const legalEntityNames: Record<string, number> = legalEntities.reduce((ids, l) => ({
        ...ids,
        [l.id]: l.name
      }), {})
      let rows: Record<string, Record<string, Record<string, string>>> = {}
      for (const legalEntityId in items) {
        const legalEntityName = legalEntityNames[legalEntityId]
        for (const productId in items[legalEntityId]) {
          const productName = items[legalEntityId][productId]['product_name']
          for (const whName in items[legalEntityId][productId]['quantity']) {
            let rows1 = {}
            let rows2 = {}
            if (rows[legalEntityName] !== undefined) {
              rows1 = rows[legalEntityName]
              if (rows[legalEntityName][whName] !== undefined) {
                rows2 = rows[legalEntityName][whName]
              }
            }
            if (items[legalEntityId][productId]['quantity'][whName] !== 0) {
              rows = {
                ...rows,
                [legalEntityName]: {
                  ...rows1,
                  [whName]: {
                    ...rows2,
                    [productName]: items[legalEntityId][productId]['quantity'][whName]
                  }
                }
              }
            }
          }
        }
      }
      if(Object.keys(rows).length > 0) {
        let fileData = ''
        for (const key1 in rows) {
          fileData += `${key1}:\n\n\n`
          for (const key2 in rows[key1]) {
            fileData += `${key2}:\n`
            for (const key3 in rows[key1][key2]) {
              fileData += `${key3} - ${rows[key1][key2][key3]}\n`
            }
            fileData += `\n`
          }
          fileData += `\n\n`
        }

        const blob = new Blob([fileData], { type: 'text/plain' })
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.download = 'manufacture-order.txt'
        link.href = url
        link.click()
      } else {
        enqueueSnackbar('Заказ на производство пуст.', { variant: 'error' })
      }
    }
  }

  return (
    <Button
      color="primary"
      variant="contained"
      startIcon={<IconDownload/>}
      onClick={onExportOrderInfo}
      disabled={!Object.keys(items).length}
    >
      Экспорт в текстовый файл
    </Button>
  )
}

export default MatrixExportOrderInfoButton