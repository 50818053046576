export const PERMISSION_REVIEWS_VIEW = 'REVIEWS_VIEW'

export const PERMISSION_PRODUCTS_LIST = 'PRODUCTS_LIST'
export const PERMISSION_PRODUCTS_UPDATE = 'PRODUCTS_UPDATE'
export const PERMISSION_PRODUCTS_CREATE = 'PRODUCTS_CREATE'

export const PERMISSION_PRODUCT_CATEGORIES_LIST = 'PRODUCT_CATEGORIES_LIST'
export const PERMISSION_PRODUCT_CATEGORY_UPDATE = 'PRODUCT_CATEGORY_UPDATE'
export const PERMISSION_PRODUCT_CATEGORY_CREATE = 'PRODUCT_CATEGORY_CREATE'
export const PERMISSION_PRODUCT_CATEGORY_DELETE = 'PRODUCT_CATEGORY_DELETE'

export const PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASES_LIST = 'PRODUCT_CATEGORY_SEARCH_PHRASES_LIST'
export const PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_UPDATE = 'PRODUCT_CATEGORY_SEARCH_PHRASE_UPDATE'
export const PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_CREATE = 'PRODUCT_CATEGORY_SEARCH_PHRASE_CREATE'
export const PERMISSION_PRODUCT_CATEGORY_SEARCH_PHRASE_DELETE = 'PRODUCT_CATEGORY_SEARCH_PHRASE_DELETE'

export const PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW = 'REPORTS_SUPPLY_NEEDS_VIEW'
export const PERMISSION_REPORTS_REALISATION_VIEW = 'REPORTS_REALISATION_VIEW'
export const PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW = 'REPORTS_FEEDBACK_STATISTICS_VIEW'
export const PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW = 'REPORTS_SEARCH_POSITIONS_VIEW'
export const PERMISSION_REPORTS_ABC_ANALYSIS_VIEW = 'REPORTS_ABC_ANALYSIS_VIEW'

export const PERMISSION_LEGAL_ENTITIES_LIST = 'LEGAL_ENTITIES_LIST'
export const PERMISSION_LEGAL_ENTITY_CREATE = 'LEGAL_ENTITY_CREATE'
export const PERMISSION_LEGAL_ENTITY_UPDATE = 'LEGAL_ENTITY_UPDATE'

export const PERMISSION_MANAGERS_LIST = 'MANAGERS_LIST'
export const PERMISSION_MANAGER_CREATE = 'MANAGER_CREATE'
export const PERMISSION_MANAGER_UPDATE = 'MANAGER_UPDATE'

export const PERMISSION_MARKETS_LIST = 'MARKETS_LIST'
export const PERMISSION_MARKET_CREATE = 'MARKET_CREATE'
export const PERMISSION_MARKET_UPDATE = 'MARKET_UPDATE'

export const PERMISSION_USERS_LIST = 'USERS_LIST'
export const PERMISSION_USER_CREATE = 'USER_CREATE'
export const PERMISSION_USER_UPDATE = 'USER_UPDATE'
export const PERMISSION_USER_DELETE = 'USER_DELETE'

export const PERMISSION_WAREHOUSES_LIST = 'WAREHOUSES_LIST'
export const PERMISSION_WAREHOUSE_CREATE = 'WAREHOUSE_CREATE'
export const PERMISSION_WAREHOUSE_UPDATE = 'WAREHOUSE_UPDATE'
export const PERMISSION_WAREHOUSE_DELETE = 'WAREHOUSE_DELETE'

export const PERMISSION_MANUFACTURE_STATUSES_LIST = 'MANUFACTURE_STATUSES_LIST'
export const PERMISSION_MANUFACTURE_STATUS_CREATE = 'MANUFACTURE_STATUS_CREATE'
export const PERMISSION_MANUFACTURE_STATUS_UPDATE = 'MANUFACTURE_STATUS_UPDATE'
export const PERMISSION_MANUFACTURE_STATUS_DELETE = 'MANUFACTURE_STATUS_DELETE'

export const PERMISSION_MANUFACTURE_ORDERS_LIST = 'MANUFACTURE_ORDERS_LIST'
export const PERMISSION_MANUFACTURE_ORDER_CREATE = 'MANUFACTURE_ORDER_CREATE'
export const PERMISSION_MANUFACTURE_ORDER_UPDATE = 'MANUFACTURE_ORDER_UPDATE'
export const PERMISSION_MANUFACTURE_ORDER_DELETE = 'MANUFACTURE_ORDER_DELETE'