import Drawer        from '@mui/material/Drawer'
import {
  useAppDispatch,
  useAppSelector
}                    from '../../../app/store/hooks'
import {
  PERMISSION_MANAGER_CREATE,
  PERMISSION_MANAGER_UPDATE
}                    from '../../../app/contants/permissions'
import AllowGuard    from '../../auth/guards/AllowGuard'
import {
  onCloseDrawer,
  selectOpenDrawer,
  selectUpdateManager
}                    from '../store/managerSlice'
import UpdateManager from './UpdateManager'
import CreateManager from './CreateManager'

const ManagerDrawer = () => {

  const open = useAppSelector(selectOpenDrawer)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(onCloseDrawer())
  }

  const updateManager = useAppSelector(selectUpdateManager)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
    >
      {updateManager ? (
        <AllowGuard permissions={[PERMISSION_MANAGER_UPDATE]}>
          <UpdateManager manager={updateManager} onClose={onClose}/>
        </AllowGuard>
      ) : (
        <AllowGuard permissions={[PERMISSION_MANAGER_CREATE]}>
          <CreateManager onClose={onClose}/>
        </AllowGuard>
      )}
    </Drawer>
  )
}

export default ManagerDrawer