import Drawer       from '@mui/material/Drawer'
import {
  useAppDispatch,
  useAppSelector
}                   from '../../../app/store/hooks'
import {
  PERMISSION_MARKET_CREATE,
  PERMISSION_MARKET_UPDATE
}                   from '../../../app/contants/permissions'
import AllowGuard   from '../../auth/guards/AllowGuard'
import {
  onCloseDrawer,
  selectOpenDrawer,
  selectUpdateMarket
}                   from '../store/marketSlice'
import UpdateMarket from './UpdateMarket'
import CreateMarket from './CreateMarket'

const MarketDrawer = () => {

  const open = useAppSelector(selectOpenDrawer)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(onCloseDrawer())
  }

  const updateMarket = useAppSelector(selectUpdateMarket)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
    >
      {updateMarket ? (
        <AllowGuard permissions={[PERMISSION_MARKET_UPDATE]}>
          <UpdateMarket market={updateMarket} onClose={onClose}/>
        </AllowGuard>
      ) : (
        <AllowGuard permissions={[PERMISSION_MARKET_CREATE]}>
          <CreateMarket onClose={onClose}/>
        </AllowGuard>
      )}
    </Drawer>
  )
}

export default MarketDrawer