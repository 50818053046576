import type {ChangeEvent}                 from 'react'
import {useState}                         from 'react'
import Drawer                             from '@mui/material/Drawer'
import AppBar                             from '@mui/material/AppBar'
import Toolbar                            from '@mui/material/Toolbar'
import Typography                         from '@mui/material/Typography'
import Stack                              from '@mui/material/Stack'
import FormControl                        from '@mui/material/FormControl'
import InputLabel                         from '@mui/material/InputLabel'
import Select, {SelectChangeEvent}        from '@mui/material/Select'
import OutlinedInput                      from '@mui/material/OutlinedInput'
import Box                                from '@mui/material/Box'
import Chip                               from '@mui/material/Chip'
import MenuItem                           from '@mui/material/MenuItem'
import Input                              from '@mui/material/Input'
import TextField                          from '@mui/material/TextField'
import Button                             from '@mui/material/Button'
import FormControlLabel                   from '@mui/material/FormControlLabel'
import Checkbox                           from '@mui/material/Checkbox'
import IconFilters                        from '../../../../app/components/icons/IconFilters'
import SelectManagers                     from '../../../managers/components/SelectManagers'
import SelectLegalEntities                from '../../../legalEntities/components/SelectLegalEntities'
import {useGetProductCategoriesListQuery} from '../../../products/services/productsApi'
import type {
  SupplyNeedsTableFiltersType,
  SupplyNeedsTableShowNeedsType
}                                         from '../../../reports/types'

const NeedsFiltersDrawer = ({
  filters,
  setFilters,
  whsOptions,
  disabled
}: {
  filters: SupplyNeedsTableFiltersType;
  setFilters: (newFilters: SupplyNeedsTableFiltersType) => void;
  whsOptions: string[];
  disabled: boolean;
}) => {

  const [open, setOpen] = useState<boolean>(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { data: categories } = useGetProductCategoriesListQuery()

  const onChangeLegalEntityId = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, legal_entity_id: Number(event.target.value) })
  }

  const onChangeManagerId = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, manager_id: event.target.value == 'none' ? 'none' : Number(event.target.value) })
  }

  const onChangeCategories = (event: SelectChangeEvent<number[]>) => {
    const { target: { value } } = event
    if (value.slice(-1)[0] === 0) {
      setFilters({ ...filters, categories: [0] })
    } else {
      setFilters({ ...filters, categories: value as number[] })
    }
  }

  const onChangeWhs = (event: SelectChangeEvent<string[]>) => {
    const { target: { value } } = event
    if (value.slice(-1)[0] === 'all') {
      setFilters({ ...filters, whs: [] })
    } else {
      setFilters({ ...filters, whs: value as string[] })
    }
  }

  const onChangeDays = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, days: Number(event.target.value) })
  }

  const onChangeShowNeeds = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, showNeeds: event.target.value as SupplyNeedsTableShowNeedsType })
  }

  const onChangeArchive = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, archive: e.target.checked })
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={onOpen}
        startIcon={<IconFilters/>}
        disabled={disabled}
      >
        Фильтры
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
      >
        <AppBar
          position="sticky"
          elevation={0}
          sx={{ py: 1 }}
        >
          <Toolbar>
            <Typography variant="h5">
              Фильтры
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
          <Stack direction="row" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Typography noWrap>
                Потребность на
              </Typography>
              <Input
                defaultValue={filters.days}
                inputProps={{ sx: { p: 0, textAlign: 'center', width: 50 } }}
                onChange={onChangeDays}
              />
              <Typography>
                дней
              </Typography>
            </Stack>
            <TextField
              select
              fullWidth
              size="small"
              label="Показать потребности"
              defaultValue={filters.showNeeds}
              onChange={onChangeShowNeeds}
            >
              <MenuItem value="all">все</MenuItem>
              <MenuItem value="green">зеленый</MenuItem>
              <MenuItem value="yellow">желтый</MenuItem>
              <MenuItem value="red">красный</MenuItem>
            </TextField>
          </Stack>
          <FormControl sx={{ minWidth: 240, width: 1 }} size="small">
            <InputLabel>Категории</InputLabel>
            <Select
              multiple
              size="small"
              value={filters.categories ?? []}
              onChange={onChangeCategories}
              input={<OutlinedInput size="small" label="Категории"/>}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {categories && [...[{
                    id: 0,
                    name: 'Без категории'
                  }], ...categories].filter(({ id }) => selected.includes(id)).map(({ id, name }) => (
                    <Chip key={id} label={name} size="small"/>
                  ))}
                </Box>
              )}
            >
              <MenuItem value={0}>
                Без категории
              </MenuItem>
              {categories && categories.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ minWidth: 240, width: 1 }} size="small">
            <InputLabel>Склады</InputLabel>
            <Select
              multiple
              size="small"
              value={filters.whs}
              onChange={onChangeWhs}
              input={<OutlinedInput size="small" label="Категории"/>}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((op) => (
                    <Chip key={op} label={op} size="small"/>
                  ))}
                </Box>
              )}
            >
              {whsOptions.map((op) => op == 'all' ? (
                <MenuItem key={op} value={op}>
                  Все склады
                </MenuItem>
              ) : (
                <MenuItem key={op} value={op}>
                  {op}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SelectManagers
            fullWidth
            size="small"
            defaultValue={filters.manager_id}
            onChange={onChangeManagerId}
          >
            <MenuItem value="none">
              Без менеджера
            </MenuItem>
            <MenuItem value={0}>
              Все менеджеры
            </MenuItem>
          </SelectManagers>
          <SelectLegalEntities
            fullWidth
            size="small"
            defaultValue={filters.legal_entity_id}
            onChange={onChangeLegalEntityId}
          >
            <MenuItem value={0}>
              Все юр. лица
            </MenuItem>
          </SelectLegalEntities>
          <FormControlLabel
            control={<Checkbox checked={filters.archive} onChange={onChangeArchive}/>}
            label={<Typography noWrap>Включая архивные</Typography>}
          />
        </Stack>
      </Drawer>
    </>
  )
}

export default NeedsFiltersDrawer