import type {ChangeEvent} from 'react'
import {
  useEffect,
  useState
}                         from 'react'
import {useSnackbar}      from 'notistack'
import Stack              from '@mui/material/Stack'
import LoadingButton      from '@mui/lab/LoadingButton'
import TextField          from '@mui/material/TextField'
import MenuItem           from '@mui/material/MenuItem'
import Card               from '@mui/material/Card'
import CardContent        from '@mui/material/CardContent'
import Box                from '@mui/material/Box'
import Chip               from '@mui/material/Chip'
import {
  useAppDispatch,
  useAppSelector
}                         from '../../../../app/store/hooks'
import ConfirmDialog      from '../../../../app/components/dialog/ConfirmDialog'
import IconDelete         from '../../../../app/components/icons/IconDelete'
import {
  useAddProductCategoryToProductMutation,
  useDeleteProductCategoryToProductMutation,
  useGetProductCategoriesListQuery,
  useGetProductsListQuery
}                         from '../../services/productsApi'
import {
  addCategoryInUpdateProduct,
  deleteCategoryInUpdateProduct
}                         from '../../store/productSlice'
import {selectLinked}     from '../../store/productsSlice'
import type {
  ProductCategoryType,
  ProductType
}                         from '../../types'

const AddProductCategory = ({
  product,
  categories
}: {
  product: ProductType;
  categories: ProductCategoryType[]
}) => {

  const { data } = useGetProductCategoriesListQuery()

  const [options, setOptions] = useState<ProductCategoryType[]>([])

  useEffect(() => {
    if (data) {
      const ids = categories.map(({ id }) => id)
      setOptions(data.filter(({ id }) => !ids.includes(id)))
    }
  }, [categories, data])

  const [addProductCategoryToProduct, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useAddProductCategoryToProductMutation()

  const [categoryId, setCategoryId] = useState<number | null>(null)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryId(Number(event.target.value))
  }

  const onAdd = () => {
    if (categoryId !== null) {
      addProductCategoryToProduct({
        product_id: product.id,
        category_id: categoryId
      })
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useAppDispatch()

  const linked = useAppSelector(selectLinked)

  const { refetch } = useGetProductsListQuery({ linked })

  useEffect(() => {
    if (isSuccess && categoryId) {
      dispatch(addCategoryInUpdateProduct({
        product: product,
        categories: options.filter(({ id }) => categoryId == id)
      }))
      enqueueSnackbar('Категория добавлена к товару.', { variant: 'success' })
      setCategoryId(null)
      refetch()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }, [isError, error])

  const [deleteProductCategoryToProduct, {
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
    isError: isErrorDelete,
    error: errorDelete
  }] = useDeleteProductCategoryToProductMutation()

  const [deleteCategoryId, setDeleteCategoryId] = useState<number | null>(null)

  const onConfirmDelete = () => {
    if (deleteCategoryId !== null) {
      deleteProductCategoryToProduct({
        product_id: product.id,
        category_id: deleteCategoryId
      })
    }
  }

  useEffect(() => {
    if (isSuccessDelete && deleteCategoryId !== null) {
      dispatch(deleteCategoryInUpdateProduct({
        product: product,
        categoryId: deleteCategoryId
      }))
      enqueueSnackbar('Категория была удалена для продукта.', { variant: 'success' })
      setDeleteCategoryId(null)
      refetch()
    }
  }, [isSuccessDelete])

  return (
    <Card>
      <CardContent>
        {options.length > 0 && (
          <Stack direction="row" spacing={2} marginBottom={2}>
            <TextField
              select
              size="small"
              label="Выберите категорию для добавления"
              value={categoryId}
              onChange={onChange}
              sx={{ flexGrow: 1 }}
              disabled={isLoading}
            >
              {options.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
            <LoadingButton
              size="small"
              variant="contained"
              loading={isLoading}
              onClick={onAdd}
            >
              Добавлять
            </LoadingButton>
          </Stack>
        )}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {categories.length > 0 ? categories.map(({ id, name }) => (
            <Chip key={id} label={name} size="small" onDelete={() => setDeleteCategoryId(id)}/>
          )) : (
            <Chip label="Категории не заданы" color="error" size="small"/>
          )}
        </Box>
        <ConfirmDialog
          title="Вы уверены, что хотите удалить?"
          Icon={IconDelete}
          loading={isLoadingDelete}
          open={Boolean(deleteCategoryId)}
          onConfirm={onConfirmDelete}
        />
      </CardContent>
    </Card>
  )
}

export default AddProductCategory