import {useEffect}                  from 'react'
import Paper                        from '@mui/material/Paper'
import Typography                   from '@mui/material/Typography'
import Stack                        from '@mui/material/Stack'
import Chip                         from '@mui/material/Chip'
import {PERMISSION_PRODUCTS_UPDATE} from '../../../../app/contants/permissions'
import {useAppDispatch}             from '../../../../app/store/hooks'
import useAllow                     from '../../../auth/hooks/useAllow'
import type {ProductsListItemType}  from '../../types'
import {useGetProductMutation}      from '../../services/productsApi'
import {setUpdateProduct}           from '../../store/productSlice'
import MpProduct                    from './MpProduct'

const ProductCard = ({ id, name, categories, mp_products }: ProductsListItemType) => {

  const [getProduct, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useGetProductMutation()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateProduct(data))
    }
  }, [isSuccess, data])

  const allowUpdate = useAllow([PERMISSION_PRODUCTS_UPDATE])

  const onClick = () => {
    if (id && allowUpdate) {
      getProduct({ id })
    }
  }

  return (
    <Paper sx={{ p: 2 }} elevation={4}>
      {name && (
        <Typography
          noWrap
          variant="h6"
          color="primary"
          marginBottom={2}
          onClick={onClick}
          sx={{ cursor: 'pointer' }}
        >
          {name}
        </Typography>
      )}
      {categories && categories.length > 0 && (
        <Stack direction="row" spacing={1} marginBottom={2}>
          {categories.map((cat, key) => (
            <Chip key={key} label={cat.name} size="small"/>
          ))}
        </Stack>
      )}
      <Stack direction="row" spacing={2}>
        {mp_products.map(({ mp_code, sku, size, archive }, key) => (
          <MpProduct key={key} code={mp_code} sku={sku} size={size} archive={archive}/>
        ))}
      </Stack>
    </Paper>
  )
}

export default ProductCard
