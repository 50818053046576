import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice}        from '@reduxjs/toolkit'
import jwt_decode           from 'jwt-decode'
import _                    from 'lodash'
import useStorage           from '../../../app/hooks/useStorage'
import type {RootState}     from '../../../app/store/types'
import type {
  JWTDecodeType,
  RoleType
}                           from '../types'
import roles                from '../../../app/contants/roles'

type AuthState = {
  token: string | null;
  username: string | null;
  user_id: number | null;
  exp: number | null;
  roles: RoleType[];
  permissions: string[];
}

const { getItem, setItem, removeItem } = useStorage('local')

const defaultState: AuthState = {
  token: null,
  username: null,
  user_id: null,
  exp: null,
  roles: [],
  permissions: []
}

const initialState: AuthState = getItem<AuthState>('AUTH_KEY', defaultState)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<string>) => {
      state.token = action.payload
      const jwt = jwt_decode(action.payload) as JWTDecodeType
      state.username = jwt.sub
      state.user_id = jwt.user_id
      state.exp = jwt.exp
      state.roles = jwt.roles
      let permissions: string[] = []
      jwt.roles.map(role => {
        permissions = [...permissions, ...roles[role]]
      })
      permissions = _.uniq(permissions)

      state.permissions = permissions
      setItem<AuthState>('AUTH_KEY', {
        token: action.payload,
        username: jwt.sub,
        user_id: jwt.user_id,
        exp: jwt.exp,
        roles: jwt.roles,
        permissions: permissions
      })
    },
    logout: (state) => {
      state.token = null
      state.username = null
      state.user_id = null
      state.exp = null
      state.roles = []
      state.permissions = []
      removeItem('AUTH_KEY')
    }
  }
})

export const { setAuth, logout } = authSlice.actions

export default authSlice.reducer

export const selectToken = (state: RootState) => state.auth.token
export const selectUsername = (state: RootState) => state.auth.username
export const selectRoles = (state: RootState) => state.auth.roles
export const selectPermissions = (state: RootState) => state.auth.permissions
