import Toolbar          from '@mui/material/Toolbar'
import Typography       from '@mui/material/Typography'
import Box              from '@mui/material/Box'
import {
  PERMISSION_USER_CREATE,
  PERMISSION_USERS_LIST
}                       from '../../../app/contants/permissions'
import AllowGuard       from '../../auth/guards/AllowGuard'
import CreateUserButton from '../components/CreateUserButton'
import UserDrawer       from '../components/UserDrawer'
import UsersTable       from '../components/table/UsersTable'

const Markets = () => {
  return (
    <AllowGuard permissions={[PERMISSION_USERS_LIST]}>
      <Toolbar disableGutters>
        <Typography color="text.secondary" variant="h4" noWrap>
          Пользователи
        </Typography>
        <Box flexGrow={1}/>
        <AllowGuard permissions={[PERMISSION_USER_CREATE]}>
          <CreateUserButton/>
        </AllowGuard>
      </Toolbar>
      <UsersTable/>
      <UserDrawer/>
    </AllowGuard>
  )
}

export default Markets