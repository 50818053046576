import Drawer            from '@mui/material/Drawer'
import {
  useAppDispatch,
  useAppSelector
}                        from '../../../app/store/hooks'
import {
  PERMISSION_LEGAL_ENTITY_CREATE,
  PERMISSION_LEGAL_ENTITY_UPDATE
}                        from '../../../app/contants/permissions'
import AllowGuard        from '../../auth/guards/AllowGuard'
import {
  onCloseDrawer,
  selectOpenDrawer,
  selectUpdateLegalEntity
}                        from '../store/legalEntitySlice'
import UpdateLegalEntity from './UpdateLegalEntity'
import CreateLegalEntity from './CreateLegalEntity'

const LegalEntityDrawer = () => {

  const open = useAppSelector(selectOpenDrawer)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(onCloseDrawer())
  }

  const updateLegalEntity = useAppSelector(selectUpdateLegalEntity)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
    >
      {updateLegalEntity ? (
        <AllowGuard permissions={[PERMISSION_LEGAL_ENTITY_UPDATE]}>
          <UpdateLegalEntity legalEntity={updateLegalEntity} onClose={onClose}/>
        </AllowGuard>
      ) : (
        <AllowGuard permissions={[PERMISSION_LEGAL_ENTITY_CREATE]}>
          <CreateLegalEntity onClose={onClose}/>
        </AllowGuard>
      )}
    </Drawer>
  )
}

export default LegalEntityDrawer