import React                from 'react'
import ReactDOM             from 'react-dom/client'
import {HashRouter}         from 'react-router-dom'
import {Provider}           from 'react-redux'
import {
  CssBaseline,
  ThemeProvider
}                           from '@mui/material'
import store                from './app/store/store'
import theme                from './app/theme'
import App                  from './App'
import NotificationProvider from './features/notification/containers/NotificationProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <NotificationProvider>
          <HashRouter>
            <App/>
          </HashRouter>
        </NotificationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
)
