import Button            from '@mui/material/Button'
import IconPlus          from '../../../app/components/icons/IconPlus'
import {useAppDispatch}  from '../../../app/store/hooks'
import {onCreateManager} from '../store/managerSlice'

const CreateManagerButton = () => {

  const dispatch = useAppDispatch()

  const onClick = () => {
    dispatch(onCreateManager())
  }

  return (
    <Button
      size="large"
      variant="contained"
      sx={{ p: 1, minWidth: 'auto' }}
      onClick={onClick}
    >
      <IconPlus sx={{ fontSize: 28 }}/>
    </Button>
  )
}

export default CreateManagerButton