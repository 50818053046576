import {
  ChangeEvent,
  useEffect,
  useState
}                        from 'react'
import AppBar            from '@mui/material/AppBar'
import Toolbar           from '@mui/material/Toolbar'
import Typography        from '@mui/material/Typography'
import Stack             from '@mui/material/Stack'
import Button            from '@mui/material/Button'
import LoadingButton     from '@mui/lab/LoadingButton'
import TextField         from '@mui/material/TextField'
import Link              from '@mui/material/Link'
import Box               from '@mui/material/Box'
import {useSnackbar}     from 'notistack'
import {useAppDispatch}  from '../../../app/store/hooks'
import {setUpdateUser}   from '../store/userSlice'
import {
  useGetUsersListQuery,
  useUpdateUserMutation
}                        from '../services/usersApi'
import type {
  RoleType,
  UpdateUserRequest,
  UserType
}                        from '../types'
import RolesTransferList from './roles/RolesTransferList'

const UpdateUser = ({
  user,
  onClose,
}: {
  user: UserType;
  onClose: () => void;
}) => {

  const [updateUser, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useUpdateUserMutation()

  const { data: users, refetch } = useGetUsersListQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateUser(data))
      refetch()
    }
  }, [isSuccess, data])

  const [formData, setFormData] = useState<UpdateUserRequest>({
    id: user.id,
    username: user.username,
    roles: user.roles
  })

  useEffect(() => {
    setFormData({
      id: user.id,
      username: user.username,
      roles: user.roles
    })
  }, [user])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const setRoles = (roles: RoleType[]) => {
    setFormData({ ...formData, roles })
  }

  const [password, setPassword] = useState<{
    newPassword: string;
    confirmNewPassword: string;
  } | null>(null)

  const onShowPassword = () => {
    setPassword({
      newPassword: '',
      confirmNewPassword: ''
    })
  }

  const onHidePassword = () => {
    setPassword(null)
  }

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    if (password) {
      setPassword({ ...password, [event.target.name]: event.target.value })
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const onSave = () => {
    if (users && users.filter(({ username }) => username == formData.username).length > 1) {
      enqueueSnackbar('Пользователь с таким логином уже существует.', { variant: 'error' })
    } else {
      if (password) {
        if (password.newPassword.length < 6) {
          enqueueSnackbar('Минимальная длина пароля 6 символов.', { variant: 'error' })
        } else if (password.newPassword !== password.confirmNewPassword) {
          enqueueSnackbar('Пароль и подтверждение пароля не совпадают.', { variant: 'error' })
        } else {
          updateUser({ ...formData, password: password.newPassword })
        }
      } else {
        updateUser(formData)
      }
    }
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">
            Изменить пользователя
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
        <Stack>
          <TextField
            fullWidth
            size="small"
            variant="outlined"
            name="username"
            label="Имя пользователя"
            onChange={onChange}
            defaultValue={user.username}
            disabled={isLoading}
          />
          {password == null && (
            <Box textAlign="right" paddingTop={1}>
              <Link onClick={onShowPassword}>
                Изменить пароль
              </Link>
            </Box>
          )}
        </Stack>
        {password !== null && (
          <Stack direction="row" spacing={1}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              type="password"
              name="newPassword"
              label="Новый пароль"
              onChange={onChangePassword}
              defaultValue={password ? password.newPassword : ''}
              disabled={isLoading}
            />
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              type="password"
              name="confirmNewPassword"
              label="Подтвердите новый пароль"
              onChange={onChangePassword}
              defaultValue={password ? password.confirmNewPassword : ''}
              disabled={isLoading}
            />
            <Button
              variant="contained"
              color="error"
              onClick={onHidePassword}
              sx={{ minWidth: 120 }}
            >
              Скрывать
            </Button>
          </Stack>
        )}
        <RolesTransferList
          values={formData.roles ?? user.roles}
          setValues={setRoles}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default UpdateUser