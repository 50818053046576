import type {PropsWithChildren} from 'react'
import useAllow                 from '../hooks/useAllow'

const AllowGuard = ({
  children,
  permissions
}: PropsWithChildren<{
  permissions: string[]
}>) => {

  const allow = useAllow(permissions)

  return allow ? <>{children}</> : <></>
}

export default AllowGuard