import type {PayloadAction} from '@reduxjs/toolkit'
import {createSlice}        from '@reduxjs/toolkit'
import {ReviewType}         from '../types'
import type {RootState}     from '../../../app/store/types'

type ReviewsState = {
  supplierId: string;
  page: number;
  list: ReviewType[]
}

const reviewsSlice = createSlice({
  name: 'reviews',
  initialState: {
    supplierId: '',
    page: 0,
    list: []
  } as ReviewsState,
  reducers: {
    setSupplierId: (state, action: PayloadAction<string>) => {
      state.supplierId = action.payload
      state.page = 0
      state.list = []
    },
    nextPage: (state) => {
      state.page += 1
    },
    setList: (state, action: PayloadAction<ReviewType[]>) => {
      if (state.page > 0) {
        state.list = state.list.concat(action.payload)
      } else {
        state.list = action.payload
      }
    },
  },
})

export const { setSupplierId, nextPage, setList } = reviewsSlice.actions

export default reviewsSlice.reducer

export const selectSupplierId = (state: RootState) => state.reviews.supplierId
export const selectPage = (state: RootState) => state.reviews.page
export const selectList = (state: RootState) => state.reviews.list