import {useState}                       from 'react'
import Drawer                           from '@mui/material/Drawer'
import AppBar                           from '@mui/material/AppBar'
import Toolbar                          from '@mui/material/Toolbar'
import Typography                       from '@mui/material/Typography'
import IconButton                       from '@mui/material/IconButton'
import List                             from '@mui/material/List'
import {
  ListItem,
  ListItemButton,
  ListItemText
}                                       from '@mui/material'
import IconPlus                         from '../../../../app/components/icons/IconPlus'
import {useGetProductsListQuery}        from '../../../products/services/productsApi'
import LoadingBox                       from '../../../../app/components/LoadingBox'
import {ManufactureOrderItemsStateType} from '../../types'

const MatrixAddProductDrawer = ({
  legalEntityId,
  items,
  addProduct
}: {
  legalEntityId: number;
  items: ManufactureOrderItemsStateType;
  addProduct: (props: {
    legal_entity_id: number;
    product_id: number;
    product_name: string;
  }) => void;
}) => {

  const [open, setOpen] = useState<boolean>(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { data, isLoading, isFetching } = useGetProductsListQuery({ linked: true })

  return (
    <>
      <IconButton onClick={onOpen}>
        <IconPlus/>
      </IconButton>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
      >
        <AppBar
          position="sticky"
          elevation={0}
          sx={{ py: 1 }}
        >
          <Toolbar>
            <Typography variant="h5">
              Добавить продукт
            </Typography>
          </Toolbar>
        </AppBar>
        <LoadingBox isLoading={isLoading || isFetching}>
          <List>
            {data && data.items.map(({ id, name, mp_products }) => (
              (
                mp_products.filter(({ legal_entity_id }) => legal_entity_id == legalEntityId).length > 0
              ) &&
              id !== undefined && name !== undefined &&
              (
                items[legalEntityId] == undefined || (
                  items[legalEntityId] !== undefined && items[legalEntityId][id] == undefined
                )
              )
            ) && (
              <ListItem
                key={id}
                onClick={() => addProduct({
                  legal_entity_id: legalEntityId,
                  product_id: id,
                  product_name: name,
                })}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary={name}/>
                  <IconPlus/>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </LoadingBox>
      </Drawer>
    </>
  )
}

export default MatrixAddProductDrawer