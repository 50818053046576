import api from '../../../app/store/api'
import {
  CreateWarehouseRequest,
  CreateWarehouseResponse,
  DeleteWarehouseRequest,
  GetWarehousesListRequest,
  GetWarehousesListResponse,
  UpdateWarehouseRequest,
  UpdateWarehouseResponse
}          from '../types'

export const warehousesApi = api.injectEndpoints({
  endpoints: builder => ({
    // GET: /api/warehouses/{mp_code} - Полученный Список Складов
    getWarehousesList: builder.query<GetWarehousesListResponse, GetWarehousesListRequest>({
      query: ({ mp_code }) => ({
        url: `/api/warehouses/${mp_code}`,
      })
    }),
    // POST: /api/warehouses/ - Добавления Склада
    createWarehouse: builder.mutation<CreateWarehouseResponse, CreateWarehouseRequest>({
      query: (warehouse) => ({
        url: '/api/warehouses/',
        method: 'POST',
        body: warehouse
      })
    }),
    // PUT: /api/warehouses/{warehouse_id} - Изменение Склада
    updateWarehouse: builder.mutation<UpdateWarehouseResponse, UpdateWarehouseRequest>({
      query: (warehouse) => ({
        url: `/api/warehouses/${warehouse.id}`,
        method: 'PUT',
        body: warehouse
      })
    }),
    // DELETE: /api/warehouses/{warehouse_id} - Удаление Склада
    deleteWarehouse: builder.mutation<void, DeleteWarehouseRequest>({
      query: ({ id }) => ({
        url: `/api/warehouses/${id}`,
        method: 'DELETE'
      })
    }),
  })
})

export const {
  useGetWarehousesListQuery,
  useCreateWarehouseMutation,
  useUpdateWarehouseMutation,
  useDeleteWarehouseMutation
} = warehousesApi
