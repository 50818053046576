import {styled}                      from '@mui/material/styles'
import Table                         from '@mui/material/Table'
import TableBody                     from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer                from '@mui/material/TableContainer'
import TableRow                      from '@mui/material/TableRow'
import Paper                         from '@mui/material/Paper'
import Typography                    from '@mui/material/Typography'
import TableHead                     from '@mui/material/TableHead'
import {useGetMarketsListQuery}      from '../../services/marketsApi'
import MarketsTableRow               from './MarketsTableRow'
import MarketsTableSkeleton          from './MarketsTableSkeleton'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.background.paper,
  }
}))

const MarketsTable = () => {

  const { data, isLoading, isFetching } = useGetMarketsListQuery()

  return (
    <Paper sx={{ width: '100%', mt: 2, mb: 4, p: 2, pb: 0 }}>
      <TableContainer>
        <Table aria-labelledby="markets-list-table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Название
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Код
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Мета поля
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  ID файла отчета потребностей в Google sheets
                </Typography>
              </StyledTableCell>
              <StyledTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isFetching ? (
              <MarketsTableSkeleton/>
            ) : data && data.map((market) => (
              <MarketsTableRow key={market.id} market={market}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default MarketsTable
