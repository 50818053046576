import type {MouseEvent}    from 'react'
import {useState}           from 'react'
import {Link}               from 'react-router-dom'
import Menu                 from '@mui/material/Menu'
import MenuItem             from '@mui/material/MenuItem'
import Collapse             from '@mui/material/Collapse'
import ListItemIcon         from '@mui/material/ListItemIcon'
import ListItemText         from '@mui/material/ListItemText'
import Avatar               from '@mui/material/Avatar'
import Typography           from '@mui/material/Typography'
import IconAngleUpRegular   from '../../../app/components/icons/IconAngleUpRegular'
import IconAngleDownRegular from '../../../app/components/icons/IconAngleDownRegular'
import useAllow             from '../../auth/hooks/useAllow'
import {
  PERMISSION_REPORTS_ABC_ANALYSIS_VIEW,
  PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW,
  PERMISSION_REPORTS_REALISATION_VIEW,
  PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW,
  PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW
}                           from '../../../app/contants/permissions'

const ReportsMenu = () => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [needsOpen, setNeedsOpen] = useState<boolean>(false)
  const [realisationOpen, setRealisationOpen] = useState<boolean>(false)
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false)
  const [searchPositionsOpen, setSearchPositionsOpen] = useState<boolean>(false)
  const [abcAnalysisOpen, setAbcAnalysisOpen] = useState<boolean>(false)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => {
    setAnchorEl(null)
    setNeedsOpen(false)
    setRealisationOpen(false)
    setFeedbackOpen(false)
    setSearchPositionsOpen(false)
    setAbcAnalysisOpen(false)
  }

  const viewSupplyNeedsAllow = useAllow([PERMISSION_REPORTS_SUPPLY_NEEDS_VIEW])
  const viewRealisationAllow = useAllow([PERMISSION_REPORTS_REALISATION_VIEW])
  const viewFeedbackAllow = useAllow([PERMISSION_REPORTS_FEEDBACK_STATISTICS_VIEW])
  const viewSearchPositionsAllow = useAllow([PERMISSION_REPORTS_SEARCH_POSITIONS_VIEW])
  const viewAbcAnalysisAllow = useAllow([PERMISSION_REPORTS_ABC_ANALYSIS_VIEW])

  return (
    <>
      <Typography variant="inherit" noWrap onClick={handleClick} sx={{ px: 2, py: 1.5 }}>
        Отчеты
      </Typography>
      <Menu
        anchorEl={anchorEl}
        id="report-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            width: 280,
            mt: 2.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 30,
              width: 10,
              height: 10,
              backgroundColor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        {viewSupplyNeedsAllow && (
          <MenuItem onClick={() => setNeedsOpen(!needsOpen)}>
            <ListItemText primary="Потребности"/>
            {needsOpen ? <IconAngleUpRegular/> : <IconAngleDownRegular/>}
          </MenuItem>
        )}
        <Collapse in={needsOpen && viewSupplyNeedsAllow} timeout="auto" unmountOnExit>
          <MenuItem
            component={Link}
            to="/reports/needs/wb"
            onClick={handleClose}
          >
            <ListItemIcon>
              <Avatar src={`/img/mp/wb.png`} variant="square" sx={{ height: 24, width: 24 }}/>
            </ListItemIcon>
            Wildberries
          </MenuItem>
          <MenuItem
            component={Link}
            to="/reports/needs/oz"
            onClick={handleClose}
          >
            <ListItemIcon>
              <Avatar src={`/img/mp/oz.png`} variant="square" sx={{ height: 24, width: 24 }}/>
            </ListItemIcon>
            Ozon
          </MenuItem>
        </Collapse>
        {viewRealisationAllow && (
          <MenuItem onClick={() => setRealisationOpen(!realisationOpen)}>
            <ListItemText primary="Реализация"/>
            {realisationOpen ? <IconAngleUpRegular/> : <IconAngleDownRegular/>}
          </MenuItem>
        )}
        <Collapse in={realisationOpen && viewRealisationAllow} timeout="auto" unmountOnExit>
          <MenuItem
            component={Link}
            to="/reports/realisation/wb"
            onClick={handleClose}
          >
            <ListItemIcon>
              <Avatar src={`/img/mp/wb.png`} variant="square" sx={{ height: 24, width: 24 }}/>
            </ListItemIcon>
            Wildberries
          </MenuItem>
        </Collapse>
        {viewFeedbackAllow && (
          <MenuItem onClick={() => setFeedbackOpen(!feedbackOpen)}>
            <ListItemText primary="Отзывы"/>
            {feedbackOpen ? <IconAngleUpRegular/> : <IconAngleDownRegular/>}
          </MenuItem>
        )}
        <Collapse in={feedbackOpen && viewFeedbackAllow} timeout="auto" unmountOnExit>
          <MenuItem
            component={Link}
            to="/reports/feedbacks-statistics/wb"
            onClick={handleClose}
          >
            <ListItemIcon>
              <Avatar src={`/img/mp/wb.png`} variant="square" sx={{ height: 24, width: 24 }}/>
            </ListItemIcon>
            Wildberries
          </MenuItem>
        </Collapse>
        {viewSearchPositionsAllow && (
          <MenuItem onClick={() => setSearchPositionsOpen(!searchPositionsOpen)}>
            <ListItemText primary="Позиции"/>
            {searchPositionsOpen ? <IconAngleUpRegular/> : <IconAngleDownRegular/>}
          </MenuItem>
        )}
        <Collapse in={searchPositionsOpen && viewSearchPositionsAllow} timeout="auto" unmountOnExit>
          <MenuItem
            component={Link}
            to="/reports/search-positions/wb"
            onClick={handleClose}
          >
            <ListItemIcon>
              <Avatar src={`/img/mp/wb.png`} variant="square" sx={{ height: 24, width: 24 }}/>
            </ListItemIcon>
            Wildberries
          </MenuItem>
        </Collapse>
        {viewAbcAnalysisAllow && (
          <MenuItem onClick={() => setAbcAnalysisOpen(!abcAnalysisOpen)}>
            <ListItemText primary="ABC анализ"/>
            {abcAnalysisOpen ? <IconAngleUpRegular/> : <IconAngleDownRegular/>}
          </MenuItem>
        )}
        <Collapse in={abcAnalysisOpen && viewAbcAnalysisAllow} timeout="auto" unmountOnExit>
          <MenuItem
            component={Link}
            to="/reports/abc-analysis/wb"
            onClick={handleClose}
          >
            <ListItemIcon>
              <Avatar src={`/img/mp/wb.png`} variant="square" sx={{ height: 24, width: 24 }}/>
            </ListItemIcon>
            Wildberries
          </MenuItem>
        </Collapse>
      </Menu>
    </>
  )
}

export default ReportsMenu
