import type {ChangeEvent}              from 'react'
import {
  useEffect,
  useState
}                                      from 'react'
import AppBar                          from '@mui/material/AppBar'
import Toolbar                         from '@mui/material/Toolbar'
import Typography                      from '@mui/material/Typography'
import Stack                           from '@mui/material/Stack'
import Button                          from '@mui/material/Button'
import LoadingButton                   from '@mui/lab/LoadingButton'
import TextField                       from '@mui/material/TextField'
import {useSnackbar}                   from 'notistack'
import {useAppDispatch}                from '../../../app/store/hooks'
import {
  useCreateLegalEntityMutation,
  useGetLegalEntitiesListQuery
}                                      from '../services/legalEntitiesApi'
import type {CreateLegalEntityRequest} from '../types'
import {setUpdateLegalEntity}          from '../store/legalEntitySlice'

const CreateLegalEntity = ({
  onClose,
}: {
  onClose: () => void;
}) => {

  const [createLegalEntity, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useCreateLegalEntityMutation()

  const { refetch } = useGetLegalEntitiesListQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateLegalEntity(data))
      refetch()
    }
  }, [isSuccess, data])

  const [formData, setFormData] = useState<CreateLegalEntityRequest>({
    name: '',
    wb_standard_token: '',
    wb_advertising_token: '',
    wb_statistics_token: '',
    ozon_client_id: '',
    ozon_api_key: '',
    ym_business_id: '',
    ym_campaign_id: '',
    ym_oauth_token: '',
    ym_oauth_client_id: ''
  })

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const { enqueueSnackbar } = useSnackbar()

  const onSave = () => {
    createLegalEntity(formData)
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">
            Добавить юридическое лицо
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="name"
          label="Название юр. лица"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="wb_standard_token"
          label="WB Токен типа Стандартный"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="wb_advertising_token"
          label="WB Токен типа Реклама"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="wb_statistics_token"
          label="WB Токен типа Статистика"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ozon_client_id"
          label="Ozon ID клиента"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ozon_api_key"
          label="Ozon API-ключ"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_business_id"
          label="Идентификатор бизнеса"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_campaign_id"
          label="Идентификатор кампании"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_oauth_client_id"
          label="YM OAuth ID клиента"
          onChange={onChange}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_oauth_token"
          label="YM OAuth Токен"
          onChange={onChange}
          disabled={isLoading}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default CreateLegalEntity