import {ThemeColorType} from '../theme'

export const API_HOST: string = process.env.REACT_APP_API_HOST || 'https://mpomega.ru'

export const SNACKBAR_DURATION: number = 2000

// red, pink, purple, deepPurple, indigo, blue, lightBlue, cyan, teal, green,
// lightGreen, lime, yellow, amber, orange, deepOrange, brown, grey, blueGrey
export const THEME_COLOR: ThemeColorType = 'teal'

export const REPORT_TABLE_ROWS_PER_PAGE: number = Number(process.env.REACT_APP_REPORT_TABLE_ROWS_PER_PAGE || 100)
export const MANUFACTURE_ORDER_NEEDS_TABLE_ROWS_PER_PAGE: number = Number(process.env.REACT_APP_MANUFACTURE_ORDER_NEEDS_TABLE_ROWS_PER_PAGE || 10)
