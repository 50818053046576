import type {ChangeEvent}                 from 'react'
import {
  useEffect,
  useState
}                                         from 'react'
import Paper                              from '@mui/material/Paper'
import Stack                              from '@mui/material/Stack'
import Typography                         from '@mui/material/Typography'
import TextField                          from '@mui/material/TextField'
import LoadingButton                      from '@mui/lab/LoadingButton'
import MenuItem                           from '@mui/material/MenuItem'
import {useSnackbar}                      from 'notistack'
import {useGetProductCategoriesListQuery} from '../../products/services/productsApi'
import {MarketCodeType}                   from '../../markets/types'
import {
  useDeleteSearchPhraseByCategoryMutation,
  useGetSearchPhrasesQuery
}                                         from '../services/searchPhrasesApi'

const DeleteSearchPhraseByCategory = ({
  mpCode
}: {
  mpCode: MarketCodeType;
}) => {

  const { data: categories } = useGetProductCategoriesListQuery()

  const { data: phrases } = useGetSearchPhrasesQuery()

  const [deleteSearchPhraseByCategory, {
    data: count,
    isLoading,
    isSuccess,
    isError,
    error
  }] = useDeleteSearchPhraseByCategoryMutation()

  const [categoryId, setCategoryId] = useState<number | null>(null)

  const onChangeCategoryId = (event: ChangeEvent<HTMLInputElement>) => {
    setCategoryId(Number(event.target.value))
  }

  const [phrase, setPhrase] = useState<string | null>(null)

  const onChangePhrase = (event: ChangeEvent<HTMLInputElement>) => {
    setPhrase(event.target.value)
  }

  const onDelete = () => {
    if (phrase) {
      if (categoryId == 0 && categories) {
        categories.forEach(cat => {
          deleteSearchPhraseByCategory({ mp_code: mpCode, category_id: cat.id, phrase: phrase }).then(r => {
            // @ts-ignore
            const c: number = r.data
            if (c > 0) {
              enqueueSnackbar(`Фраза успешно удалена у ${c} продуктов.`, { variant: 'success' })
            }
          })
        })
      } else if (categoryId) {
        deleteSearchPhraseByCategory({ mp_code: mpCode, category_id: categoryId, phrase: phrase })
      }
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isSuccess && categoryId) {
      if (count) {
        enqueueSnackbar(`Фраза успешно удалена к ${count} продуктам.`, { variant: 'success' })
      } else {
        enqueueSnackbar(`Фраза не найдена для удаления.`, { variant: 'info' })
      }
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      enqueueSnackbar('При удалении фразы произошла ошибка.', { variant: 'error' })
    }
  }, [isError, error])

  return (
    <Paper sx={{ width: '100%', mb: 4, p: 2 }}>
      <Typography color="text.secondary" variant="h6" noWrap marginBottom={2}>
        Удалить поисковую фразу
      </Typography>
      <Stack direction="row" spacing={2}>
        <TextField
          select
          size="small"
          label="Выберите категорию"
          value={categoryId}
          onChange={onChangeCategoryId}
          sx={{ flexGrow: 1, maxWidth: 340 }}
          disabled={isLoading}
        >
          <MenuItem value={0}>
            Все
          </MenuItem>
          {categories && categories.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          size="small"
          label="Поисковая фраза"
          value={phrase}
          onChange={onChangePhrase}
          sx={{ flexGrow: 1 }}
          disabled={isLoading}
        >
          {phrases && phrases.map((p, k) => (
            <MenuItem key={k} value={p}>
              {p}
            </MenuItem>
          ))}
        </TextField>
        <LoadingButton
          size="small"
          color="error"
          variant="contained"
          loading={isLoading}
          onClick={onDelete}
          sx={{ width: 120 }}
        >
          Удалить
        </LoadingButton>
      </Stack>
    </Paper>
  )
}

export default DeleteSearchPhraseByCategory