import Stack                      from '@mui/material/Stack'
import Chip                       from '@mui/material/Chip'
import IconButton                 from '@mui/material/IconButton'
import Link                       from '@mui/material/Link'
import TableRow                   from '@mui/material/TableRow'
import Typography                 from '@mui/material/Typography'
import IconPenToSquare            from '../../../../app/components/icons/IconPenToSquare'
import {PERMISSION_MARKET_UPDATE} from '../../../../app/contants/permissions'
import {useAppDispatch}           from '../../../../app/store/hooks'
import useAllow                   from '../../../auth/hooks/useAllow'
import type {MarketType}          from '../../types'
import {setUpdateMarket}          from '../../store/marketSlice'
import {StyledTableCell}          from './MarketsTable'

const MarketsTableRow = ({
  market
}: {
  market: MarketType
}) => {

  const allowUpdate = useAllow([PERMISSION_MARKET_UPDATE])

  const dispatch = useAppDispatch()

  const onUpdate = () => {
    dispatch(setUpdateMarket(market))
  }

  return (
    <TableRow hover tabIndex={-1}>
      <StyledTableCell>
        {allowUpdate ? (
          <Link onClick={onUpdate} underline="hover">
            {market.name}
          </Link>
        ) : (
          <Typography variant="inherit">
            {market.name}
          </Typography>
        )}
      </StyledTableCell>
      <StyledTableCell align="left">
        {market.code}
      </StyledTableCell>
      <StyledTableCell align="left">
        <Stack direction="row" spacing={1}>
          {market.fields.map((field, key) => (
            <Chip key={key} label={field} size="small"/>
          ))}
        </Stack>
      </StyledTableCell>
      <StyledTableCell align="left">
        {market.gs_needs_key}
      </StyledTableCell>
      <StyledTableCell align="right" padding="none">
        {allowUpdate && (
          <IconButton color="primary" onClick={onUpdate}>
            <IconPenToSquare/>
          </IconButton>
        )}
      </StyledTableCell>
    </TableRow>
  )
}

export default MarketsTableRow