import type {PropsWithChildren} from 'react'
import Box                      from '@mui/material/Box'
import CircularProgress         from '@mui/material/CircularProgress'
import Typography               from '@mui/material/Typography'

const LoadingBox = ({
  children,
  isLoading,
  size = 120,
  text
}: PropsWithChildren<{
  isLoading: boolean;
  size?: number;
  text?: string;
}>) => isLoading ? (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      minHeight: `${size * 3}px`
    }}
  >
    <CircularProgress size={size}/>
    <Typography variant="subtitle2" sx={{ pt: 3 }}>{text}</Typography>
  </Box>
) : (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }}
  >
    {children}
  </Box>
)

export default LoadingBox