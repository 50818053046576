import TableHead       from '@mui/material/TableHead'
import TableRow        from '@mui/material/TableRow'
import Typography      from '@mui/material/Typography'
import {fDate}         from '../../../../../app/utils/formatTime'
import type {
  SupplyNeedsTableHeadCellType,
  SupplyNeedsTableShowColumnsType
}                      from '../../../types'
import StyledTableCell from '../../table/styled/StyledTableCell'

const SupplyNeedsTableHead = ({
  showColumns,
  headCells,
  dates
}: {
  showColumns: SupplyNeedsTableShowColumnsType;
  headCells: SupplyNeedsTableHeadCellType[];
  dates: string[];
}) => (
  <TableHead
    sx={{
      position: 'sticky',
      top: 0,
      zIndex: 2
    }}
  >
    <TableRow>
      {headCells.map((headCell, key) => showColumns[headCell.key] && (
        <StyledTableCell
          key={key}
          align={headCell.align ?? 'left'}
          sx={headCell.sticky ? {
            position: 'sticky',
            left: 0,
            zIndex: 3,
            borderRight: '1px solid #e0e0e0'
          } : {}}
        >
          <Typography color="text.secondary" fontSize={14} noWrap>
            {headCell.label}
          </Typography>
        </StyledTableCell>
      ))}
      {dates.map((date, key) => showColumns[date] && (
        <StyledTableCell
          key={key}
          align="right"
        >
          <Typography color="text.secondary" fontSize={14} noWrap>
            {fDate(date)}
          </Typography>
        </StyledTableCell>
      ))}
    </TableRow>
  </TableHead>
)

export default SupplyNeedsTableHead