import {configureStore}       from '@reduxjs/toolkit'
import api                    from './api'
import notificationReducer    from '../../features/notification/store/notificationSlice'
import authReducer            from '../../features/auth/store/authSlice'
import legalEntityReducer     from '../../features/legalEntities/store/legalEntitySlice'
import managerReducer         from '../../features/managers/store/managerSlice'
import marketReducer          from '../../features/markets/store/marketSlice'
import reviewsReducer         from '../../features/reviews/store/reviewsSlice'
import productsReducer        from '../../features/products/store/productsSlice'
import productReducer         from '../../features/products/store/productSlice'
import productCategoryReducer from '../../features/products/store/productCategorySlice'
import userReducer            from '../../features/users/store/userSlice'

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    notification: notificationReducer,
    auth: authReducer,
    legalEntity: legalEntityReducer,
    manager: managerReducer,
    market: marketReducer,
    reviews: reviewsReducer,
    products: productsReducer,
    product: productReducer,
    productCategory: productCategoryReducer,
    user: userReducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
  devTools: true
})

export default store

