import {
  useEffect,
  useState
}                                            from 'react'
import IconButton                            from '@mui/material/IconButton'
import {useSnackbar}                         from 'notistack'
import {MarketCodeType}                      from '../../markets/types'
import {useAppDispatch}                      from '../../../app/store/hooks'
import {deleteSearchPhraseFromUpdateProduct} from '../../products/store/productSlice'
import IconDelete                            from '../../../app/components/icons/IconDelete'
import ConfirmDialog                         from '../../../app/components/dialog/ConfirmDialog'
import {useDeleteSearchPhraseMutation}       from '../services/searchPhrasesApi'
import {ProductType}                         from '../../products/types'

const DeleteSearchPhrase = ({
  mp_code,
  sku,
  phrase,
  product
}: {
  mp_code: MarketCodeType;
  sku: string;
  phrase: string;
  product: ProductType;
}) => {

  const [deleteSearchPhrase, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useDeleteSearchPhraseMutation()

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess) {
      dispatch(deleteSearchPhraseFromUpdateProduct({ mp_code, phrase, product }))
      enqueueSnackbar('Поисковая фраза успешно удалена.', { variant: 'success' })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }, [isError, error])

  const [open, setOpen] = useState<boolean>(false)

  const onConfirmDelete = () => {
    deleteSearchPhrase({ mp_code, sku, phrase })
  }

  return (
    <>
      <IconButton
        size="small"
        sx={{ p: 0 }}
        onClick={() => setOpen(true)}
      >
        <IconDelete sx={{ fontSize: 16 }}/>
      </IconButton>
      <ConfirmDialog
        title="Вы уверены, что хотите удалить?"
        Icon={IconDelete}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  )
}

export default DeleteSearchPhrase