import {useDispatch}     from 'react-redux'
import IconButton        from '@mui/material/IconButton'
import {IconButtonProps} from '@mui/material/IconButton/IconButton'
import {logout}          from '../store/authSlice'
import IconLogout        from '../../../app/components/icons/IconLogout'

const LogoutButton = (props: IconButtonProps) => {

  const dispatch = useDispatch()

  return (
    <IconButton
      size="large"
      color="primary"
      onClick={() => dispatch(logout())}
      {...props}
    >
      <IconLogout/>
    </IconButton>
  )
}

export default LogoutButton