import type {
  ElementType,
  PropsWithChildren
}                    from 'react'
import Dialog        from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle   from '@mui/material/DialogTitle'
import Stack         from '@mui/material/Stack'
import Box           from '@mui/material/Box'
import Button        from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'

const ConfirmDialog = ({
  children,
  title,
  Icon,
  loading,
  open,
  onClose,
  onConfirm
}: PropsWithChildren<{
  title?: string
  Icon?: ElementType;
  loading?: boolean;
  open: boolean;
  onClose?: () => void;
  onConfirm: () => void;
}>) => {

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack direction="row" alignItems="center" paddingTop={3}>
        {Icon && <Icon sx={{ color: 'primary.main', fontSize: 40, marginLeft: 3 }}/>}
        <Box>
          {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
          <DialogContent>
            {children}
          </DialogContent>
        </Box>
      </Stack>
      <DialogActions>
        <Button
          size="large"
          onClick={onClose}
        >
          Нет
        </Button>
        <LoadingButton
          autoFocus
          size="large"
          variant="contained"
          loading={loading}
          onClick={() => {
            if (onClose) {
              onClose()
            }
            onConfirm()
          }}
        >
          Да
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
