import {
  useEffect,
  useState
}                                       from 'react'
import TextField                        from '@mui/material/TextField'
import Stack                            from '@mui/material/Stack'
import Autocomplete                     from '@mui/material/Autocomplete'
import LoadingButton                    from '@mui/lab/LoadingButton'
import {useSnackbar}                    from 'notistack'
import {MarketCodeType}                 from '../../markets/types'
import {useAppDispatch}                 from '../../../app/store/hooks'
import {addSearchPhraseInUpdateProduct} from '../../products/store/productSlice'
import {ProductType}                    from '../../products/types'
import {
  useCreateSearchPhraseMutation,
  useGetSearchPhrasesQuery
}                                       from '../services/searchPhrasesApi'

const AddSearchPhrase = ({
  mp_code,
  sku,
  product
}: {
  mp_code: MarketCodeType;
  sku: string;
  product: ProductType;
}) => {

  const { data } = useGetSearchPhrasesQuery()

  const [createSearchPhrase, {
    isLoading,
    isSuccess,
    isError,
    error
  }] = useCreateSearchPhraseMutation()

  const [phrase, setPhrase] = useState<string | null>(null)

  const onAdd = () => {
    if (phrase) {
      createSearchPhrase({ mp_code, sku, search_phrase: phrase })
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess) {
      dispatch(addSearchPhraseInUpdateProduct({ mp_code, phrase: phrase ?? '', product }))
      enqueueSnackbar('Поисковая фраза успешно добавлена.', { variant: 'success' })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      enqueueSnackbar(error.data.message, { variant: 'error' })
    }
  }, [isError, error])

  return (
    <Stack direction="row" spacing={2}>
      <Autocomplete
        freeSolo
        options={data ?? []}
        onChange={(event, newValue) => {
          setPhrase(newValue)
        }}
        onInputChange={(event, newInputValue) => {
          setPhrase(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Поисковая фраза"
            disabled={isLoading}
          />
        )}
        sx={{ flexGrow: 1 }}
      />
      <LoadingButton
        size="small"
        variant="contained"
        loading={isLoading}
        onClick={onAdd}
      >
        Добавлять
      </LoadingButton>
    </Stack>
  )
}

export default AddSearchPhrase