import Table          from '@mui/material/Table'
import TableBody      from '@mui/material/TableBody'
import TableCell      from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow       from '@mui/material/TableRow'
import Paper          from '@mui/material/Paper'
import TableHead      from '@mui/material/TableHead'
import {
  fCurrency,
  fPcs
}                     from '../../../../app/utils/formatNumber'

const OrdersAndSalesTable = ({ title, yesterday, week, month, avarage_daily }: {
  title: string;
  yesterday?: {
    total: number;
    amount: number;
  };
  week?: {
    total: number;
    amount: number;
  };
  month?: {
    total: number;
    amount: number;
  };
  avarage_daily?: {
    total: number;
    amount: number;
  }
}) => (
  <TableContainer component={Paper} variant="outlined">
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={3}>{title}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {yesterday && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">за вчера</TableCell>
            <TableCell align="right">{fPcs(yesterday.total)}</TableCell>
            <TableCell align="right">{fCurrency(yesterday.amount)}</TableCell>
          </TableRow>
        )}
        {week && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">за неделю</TableCell>
            <TableCell align="right">{fPcs(week.total)}</TableCell>
            <TableCell align="right">{fCurrency(week.amount)}</TableCell>
          </TableRow>
        )}
        {month && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">за месяц</TableCell>
            <TableCell align="right">{fPcs(month.total)}</TableCell>
            <TableCell align="right">{fCurrency(month.amount)}</TableCell>
          </TableRow>
        )}
        {avarage_daily && (
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">в среднем в день</TableCell>
            <TableCell align="right">{fPcs(avarage_daily.total)}</TableCell>
            <TableCell align="right">{fCurrency(avarage_daily.amount)}</TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  </TableContainer>
)

export default OrdersAndSalesTable
