import TableHead                      from '@mui/material/TableHead'
import TableRow                       from '@mui/material/TableRow'
import Typography                     from '@mui/material/Typography'
import {RealisationTableHeadCellType} from '../../../types'
import StyledTableCell                from '../../table/styled/StyledTableCell'

const RealisationTableHead = ({
  headCells
}: {
  headCells: readonly RealisationTableHeadCellType[];
}) => (
  <TableHead>
    <TableRow
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2
      }}
    >
      {headCells.map((headCell) => headCell.show ? Array.isArray(headCell.total) ? (
        <>
          {headCell.total[0] > 0 && (
            <StyledTableCell key={`${headCell.key}-0`} align={headCell.align ?? 'left'}>
              <Typography color="text.secondary" fontSize={14} noWrap>
                Неделя 4
              </Typography>
            </StyledTableCell>
          )}
          {headCell.total[1] > 0 && (
            <StyledTableCell key={`${headCell.key}-1`} align={headCell.align ?? 'left'}>
              <Typography color="text.secondary" fontSize={14} noWrap>
                Неделя 3
              </Typography>
            </StyledTableCell>
          )}
          {headCell.total[2] > 0 && (
            <StyledTableCell key={`${headCell.key}-2`} align={headCell.align ?? 'left'}>
              <Typography color="text.secondary" fontSize={14} noWrap>
                Неделя 2
              </Typography>
            </StyledTableCell>
          )}
          {headCell.total[3] > 0 && (
            <StyledTableCell key={`${headCell.key}-3`} align={headCell.align ?? 'left'}>
              <Typography color="text.secondary" fontSize={14} noWrap>
                Неделя 1
              </Typography>
            </StyledTableCell>
          )}
        </>
      ) : (
        <StyledTableCell
          key={headCell.key}
          align={headCell.align ?? 'left'}
          sx={headCell.sticky ? {
            position: 'sticky',
            left: 0,
            zIndex: 2
          } : {}}
        >
          <Typography color="text.secondary" fontSize={14} noWrap>
            {headCell.label}
          </Typography>
        </StyledTableCell>
      ) : null)}
    </TableRow>
  </TableHead>
)

export default RealisationTableHead