import TableRow        from '@mui/material/TableRow'
import Skeleton        from '@mui/material/Skeleton'
import StyledTableCell from '../StyledTableCell'

const ManufactureOrdersTableSkeleton = ({
  rowsCount = 10
}: {
  rowsCount?: number;
}) => {

  return (
    <>
      {[...Array(rowsCount)].map((_, key) => (
        <TableRow key={`skeleton-${key}`} tabIndex={-1}>
          <StyledTableCell>
            <Skeleton variant="text"/>
          </StyledTableCell>
          <StyledTableCell>
            <Skeleton variant="text"/>
          </StyledTableCell>
          <StyledTableCell>
            <Skeleton variant="text"/>
          </StyledTableCell>
          <StyledTableCell>
            <Skeleton variant="text"/>
          </StyledTableCell>
          <StyledTableCell>
            <Skeleton variant="text"/>
          </StyledTableCell>
        </TableRow>
      ))}
    </>
  )
}

export default ManufactureOrdersTableSkeleton