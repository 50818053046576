import TableRow          from '@mui/material/TableRow'
import Typography        from '@mui/material/Typography'
import {StyledTableCell} from './ProductCategoriesTable'

const ProductCategoriesTableNotFound = () => {

  return (
    <TableRow tabIndex={-1}>
      <StyledTableCell colSpan={2}>
        <Typography color="text.secondary" align="center" sx={{ py: 5 }}>
          Категории не найдены
        </Typography>
      </StyledTableCell>
    </TableRow>
  )
}

export default ProductCategoriesTableNotFound