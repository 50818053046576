import api from '../../../app/store/api'
import {
  CreateUserRequest,
  CreateUserResponse,
  DeleteUserRequest,
  GetUserRolesListResponse,
  GetUsersListResponse,
  UpdateUserRequest,
  UpdateUserResponse,
  UserType
}          from '../types'

const sortByNameCompare = (a: UserType, b: UserType) => {
  if (a.username < b.username) {
    return -1
  }
  if (a.username > b.username) {
    return 1
  }
  return 0
}

export const usersApi = api.injectEndpoints({
  endpoints: builder => ({
    getUsersList: builder.query<GetUsersListResponse, void>({
      query: () => ({
        url: `/management/users`,
      }),
      transformResponse: (response: GetUsersListResponse) => {
        response.sort(sortByNameCompare)
        return response
      },
    }),
    createUser: builder.mutation<CreateUserResponse, CreateUserRequest>({
      query: (user) => ({
        url: `/management/users`,
        method: 'POST',
        body: user
      })
    }),
    updateUser: builder.mutation<UpdateUserResponse, UpdateUserRequest>({
      query: (user) => ({
        url: `/management/users/${user.id}`,
        method: 'PUT',
        body: user
      })
    }),
    deleteUser: builder.mutation<void, DeleteUserRequest>({
      query: ({ id }) => ({
        url: `/management/users/${id}`,
        method: 'DELETE'
      })
    }),
    getUserRolesList: builder.query<GetUserRolesListResponse, void>({
      query: () => ({
        url: `/management/user_roles`,
      })
    }),
  })
})

export const {
  useGetUsersListQuery,
  useCreateUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetUserRolesListQuery
} = usersApi
