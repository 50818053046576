import {
  useEffect,
  useState
}                            from 'react'
import {useSelector}         from 'react-redux'
import LoadingButton         from '@mui/lab/LoadingButton'
import {useAppDispatch}      from '../../../../app/store/hooks'
import IconDelete            from '../../../../app/components/icons/IconDelete'
import ConfirmDialog         from '../../../../app/components/dialog/ConfirmDialog'
import type {MarketCodeType} from '../../../markets/types'
import {
  useDeleteProductMutation,
  useGetProductsListQuery
}                            from '../../services/productsApi'
import {selectLinked}        from '../../store/productsSlice'
import {setUpdateProduct}    from '../../store/productSlice'

const DeleteProductLinkButton = ({ id, mp_code }: { id: number; mp_code: MarketCodeType; }) => {

  const [deleteProductLink, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useDeleteProductMutation()

  const linked = useSelector(selectLinked)

  const { refetch } = useGetProductsListQuery({ linked })

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateProduct(data))
      refetch()
    }
  }, [isSuccess, data])

  const [open, setOpen] = useState<boolean>(false)

  const onConfirmDelete = () => {
    deleteProductLink({ id, mp_code })
  }

  return (
    <>
      <LoadingButton
        variant="contained"
        color="error"
        sx={{ mt: 'auto !important' }}
        loading={isLoading}
        onClick={() => setOpen(true)}
      >
        Удалить
      </LoadingButton>
      <ConfirmDialog
        title="Вы уверены, что хотите удалить?"
        Icon={IconDelete}
        open={open}
        onClose={() => setOpen(false)}
        onConfirm={onConfirmDelete}
      />
    </>
  )
}

export default DeleteProductLinkButton