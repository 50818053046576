import type {MouseEvent} from 'react'
import {useState}        from 'react'
import {Link}            from 'react-router-dom'
import Menu              from '@mui/material/Menu'
import MenuItem          from '@mui/material/MenuItem'
import ListItemText      from '@mui/material/ListItemText'
import Typography        from '@mui/material/Typography'

const AdminMenu = () => {

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Typography variant="inherit" noWrap onClick={handleClick} sx={{ px: 2, py: 1.5 }}>
        Админ
      </Typography>
      <Menu
        anchorEl={anchorEl}
        id="admin-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            width: 280,
            mt: 2.5,
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 30,
              width: 10,
              height: 10,
              backgroundColor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem component={Link} to="/admin/users">
          <ListItemText primary="Пользователи"/>
        </MenuItem>
        <MenuItem component={Link} to="/admin/markets">
          <ListItemText primary="Маркетплейсы"/>
        </MenuItem>
        <MenuItem component={Link} to="/admin/legal-entities">
          <ListItemText primary="Юридические лица"/>
        </MenuItem>
        <MenuItem component={Link} to="/admin/managers">
          <ListItemText primary="Менеджеры"/>
        </MenuItem>
      </Menu>
    </>
  )
}

export default AdminMenu
