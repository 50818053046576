import {styled}                      from '@mui/material/styles'
import Table                         from '@mui/material/Table'
import TableBody                     from '@mui/material/TableBody'
import TableCell, {tableCellClasses} from '@mui/material/TableCell'
import TableContainer                from '@mui/material/TableContainer'
import TableRow                      from '@mui/material/TableRow'
import Paper                         from '@mui/material/Paper'
import Typography                    from '@mui/material/Typography'
import TableHead                     from '@mui/material/TableHead'
import {useGetUsersListQuery}        from '../../services/usersApi'
import UsersTableRow                 from './UsersTableRow'
import UsersTableSkeleton            from './UsersTableSkeleton'

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey.A100,
  },
  [`&.${tableCellClasses.body}`]: {
    backgroundColor: theme.palette.background.paper,
  }
}))

const UsersTable = () => {

  const { data, isLoading, isFetching } = useGetUsersListQuery()

  return (
    <Paper sx={{ width: '100%', mt: 2, mb: 4, p: 2, pb: 0 }}>
      <TableContainer>
        <Table aria-labelledby="users-list-table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Имя пользователя
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Роли пользователей
                </Typography>
              </StyledTableCell>
              <StyledTableCell/>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isFetching ? (
              <UsersTableSkeleton/>
            ) : data && data.map((user) => (
              <UsersTableRow key={user.id} user={user}/>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}

export default UsersTable
