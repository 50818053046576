import {
  ChangeEvent,
  useEffect,
  useState
}                        from 'react'
import AppBar            from '@mui/material/AppBar'
import Toolbar           from '@mui/material/Toolbar'
import Typography        from '@mui/material/Typography'
import Stack             from '@mui/material/Stack'
import Button            from '@mui/material/Button'
import LoadingButton     from '@mui/lab/LoadingButton'
import TextField         from '@mui/material/TextField'
import {useSnackbar}     from 'notistack'
import {useAppDispatch}  from '../../../app/store/hooks'
import {
  useCreateUserMutation,
  useGetUsersListQuery
}                        from '../services/usersApi'
import type {
  CreateUserRequest,
  RoleType
}                        from '../types'
import {setUpdateUser}   from '../store/userSlice'
import RolesTransferList from './roles/RolesTransferList'

const CreateUser = ({
  onClose,
}: {
  onClose: () => void;
}) => {

  const [createUser, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useCreateUserMutation()

  const { data: users, refetch } = useGetUsersListQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateUser(data))
      refetch()
    }
  }, [isSuccess, data])

  const [formData, setFormData] = useState<CreateUserRequest>({
    username: '',
    password: '',
    roles: []
  })

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [event.target.name]: event.target.value })
  }

  const setRoles = (roles: RoleType[]) => {
    setFormData({ ...formData, roles })
  }

  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const onChangeConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value)
  }

  const { enqueueSnackbar } = useSnackbar()

  const onSave = () => {
    if (users && users.filter(({ username }) => username == formData.username).length > 0) {
      enqueueSnackbar('Пользователь с таким логином уже существует.', { variant: 'error' })
    } else if (formData.roles.length < 0) {
      enqueueSnackbar('Роль пользователя не выбрана.', { variant: 'error' })
    } else if (formData.password.length < 6) {
      enqueueSnackbar('Минимальная длина пароля 6 символов.', { variant: 'error' })
    } else if (formData.password !== confirmPassword) {
      enqueueSnackbar('Пароль и подтверждение пароля не совпадают.', { variant: 'error' })
    } else {
      createUser(formData)
    }
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{ py: 1 }}
      >
        <Toolbar>
          <Typography variant="h5">
            Добавить пользователя
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="username"
          label="Имя пользователя"
          onChange={onChange}
          disabled={isLoading}
          inputProps={{
            autocomplete: 'off',
            form: {
              autocomplete: 'off',
            },
          }}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="password"
          name="password"
          label="Пароль"
          onChange={onChange}
          disabled={isLoading}
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          type="password"
          label="Подтвердите пароль"
          onChange={onChangeConfirmPassword}
          disabled={isLoading}
          inputProps={{
            autocomplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
        />
        <RolesTransferList
          values={formData.roles}
          setValues={setRoles}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({ backgroundColor: theme.palette.grey.A100 })}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default CreateUser