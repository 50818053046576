import type {PropsWithChildren} from 'react'
import Paper                    from '@mui/material/Paper'
import Skeleton                 from '@mui/material/Skeleton'
import Stack                    from '@mui/material/Stack'
import LoadingBox               from '../../../../app/components/LoadingBox'

const ProductsListLoading = ({
  children,
  isLoading,
  isFetching,
  rowsCount
}: PropsWithChildren<{
  isLoading: boolean;
  isFetching: boolean;
  rowsCount: number;
}>) => {
  return (
    <LoadingBox isLoading={isLoading}>
      {isFetching ? (
        <Stack spacing={4}>
          {[...Array(rowsCount)].map((v, i) => (
            <Paper key={i} sx={{ p: 2 }} elevation={4}>
              <Skeleton variant="text" sx={{ height: 32, mb: 2 }}/>
              <Stack direction="row" spacing={2}>
                <Skeleton variant="text" sx={{ height: 40, width: 120 }}/>
                <Skeleton variant="text" sx={{ height: 40, width: 110 }}/>
                <Skeleton variant="text" sx={{ height: 40, width: 100 }}/>
                <Skeleton variant="text" sx={{ height: 40, width: 90 }}/>
              </Stack>
            </Paper>
          ))}
        </Stack>
      ) : children}
    </LoadingBox>
  )
}

export default ProductsListLoading