import Drawer     from '@mui/material/Drawer'
import {
  useAppDispatch,
  useAppSelector
}                 from '../../../app/store/hooks'
import {
  PERMISSION_USER_CREATE,
  PERMISSION_USER_UPDATE
}                 from '../../../app/contants/permissions'
import AllowGuard from '../../auth/guards/AllowGuard'
import {
  onCloseDrawer,
  selectOpenDrawer,
  selectUpdateUser
}                 from '../store/userSlice'
import UpdateUser from './UpdateUser'
import CreateUser from './CreateUser'

const UserDrawer = () => {

  const open = useAppSelector(selectOpenDrawer)
  const dispatch = useAppDispatch()

  const onClose = () => {
    dispatch(onCloseDrawer())
  }

  const updateUser = useAppSelector(selectUpdateUser)

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: 500, maxWidth: '100%' } }}
    >
      {updateUser ? (
        <AllowGuard permissions={[PERMISSION_USER_UPDATE]}>
          <UpdateUser user={updateUser} onClose={onClose}/>
        </AllowGuard>
      ) : (
        <AllowGuard permissions={[PERMISSION_USER_CREATE]}>
          <CreateUser onClose={onClose}/>
        </AllowGuard>
      )}
    </Drawer>
  )
}

export default UserDrawer