import type {SyntheticEvent} from 'react'
import {useState}            from 'react'

const useTabs = <T> (defaultValues: T) => {

  const [currentTab, setCurrentTab] = useState(defaultValues || 0)

  return {
    currentTab,
    onChangeTab: (event: SyntheticEvent, newValue: T) => setCurrentTab(newValue),
  }
}

export default useTabs