import type {ChangeEvent} from 'react'
import {
  useEffect,
  useState
} from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import {useSnackbar} from 'notistack'
import {useAppDispatch} from '../../../app/store/hooks'
import {setUpdateLegalEntity} from '../store/legalEntitySlice'
import {
  useGetLegalEntitiesListQuery,
  useUpdateLegalEntityMutation
} from '../services/legalEntitiesApi'
import type {
  LegalEntityType,
  UpdateLegalEntityRequest
} from '../types'
import {
  FormControlLabel,
  Switch
} from '@mui/material'

const UpdateLegalEntity = ({
                             legalEntity,
                             onClose,
                           }: {
  legalEntity: LegalEntityType;
  onClose: () => void;
}) => {

  const [updateLegalEntity, {
    data,
    isLoading,
    isSuccess,
    isError
  }] = useUpdateLegalEntityMutation()

  const {refetch} = useGetLegalEntitiesListQuery()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess && data) {
      dispatch(setUpdateLegalEntity(data))
      refetch()
    }
  }, [isSuccess, data])

  const [formData, setFormData] = useState<UpdateLegalEntityRequest>({
    id: legalEntity.id,
    name: legalEntity.name,
    active: legalEntity.active,
    wb_standard_token: legalEntity.wb_standard_token,
    wb_advertising_token: legalEntity.wb_advertising_token,
    wb_statistics_token: legalEntity.wb_statistics_token,
    ozon_client_id: legalEntity.ozon_client_id,
    ozon_api_key: legalEntity.ozon_api_key,
    ym_business_id: legalEntity.ym_business_id,
    ym_campaign_id: legalEntity.ym_campaign_id,
    ym_oauth_token: legalEntity.ym_oauth_token,
    ym_oauth_client_id: legalEntity.ym_oauth_client_id
  })

  useEffect(() => {
    setFormData({
      id: legalEntity.id,
      name: legalEntity.name,
      active: legalEntity.active,
      wb_standard_token: legalEntity.wb_standard_token,
      wb_advertising_token: legalEntity.wb_advertising_token,
      wb_statistics_token: legalEntity.wb_statistics_token,
      ozon_client_id: legalEntity.ozon_client_id,
      ozon_api_key: legalEntity.ozon_api_key,
      ym_business_id: legalEntity.ym_business_id,
      ym_campaign_id: legalEntity.ym_campaign_id,
      ym_oauth_token: legalEntity.ym_oauth_token,
      ym_oauth_client_id: legalEntity.ym_oauth_client_id
    })
  }, [legalEntity])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [event.target.name]: event.target.value})
  }

  const onChangeActive = (event: ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, 'active': event.target.checked})
  }

  const {enqueueSnackbar} = useSnackbar()

  const onSave = () => {
    updateLegalEntity(formData)
  }

  return (
    <>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{py: 1}}
      >
        <Toolbar>
          <Typography variant="h5">
            Изменить юридическое лицо
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack flexGrow={1} sx={{px: 3, py: 4}} spacing={3}>
        <FormControlLabel
          control={(
            <Switch
              onChange={onChangeActive}
              defaultChecked={legalEntity.active}
            />
          )}
          label={formData.active ? 'активен' : 'неактивен'}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="name"
          label="Название юр. лица"
          onChange={onChange}
          defaultValue={legalEntity.name}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="wb_standard_token"
          label="WB Токен типа Стандартный"
          onChange={onChange}
          defaultValue={legalEntity.wb_standard_token}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="wb_advertising_token"
          label="WB Токен типа Реклама"
          onChange={onChange}
          defaultValue={legalEntity.wb_advertising_token}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="wb_statistics_token"
          label="WB Токен типа Статистика"
          onChange={onChange}
          defaultValue={legalEntity.wb_statistics_token}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ozon_client_id"
          label="Ozon ID клиента"
          onChange={onChange}
          defaultValue={legalEntity.ozon_client_id}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ozon_api_key"
          label="Ozon API-ключ"
          onChange={onChange}
          defaultValue={legalEntity.ozon_api_key}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_business_id"
          label="Идентификатор бизнеса"
          onChange={onChange}
          defaultValue={legalEntity.ym_business_id}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_campaign_id"
          label="Идентификатор кампании"
          onChange={onChange}
          defaultValue={legalEntity.ym_campaign_id}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_oauth_client_id"
          label="YM OAuth ID клиента"
          onChange={onChange}
          defaultValue={legalEntity.ym_oauth_client_id}
          disabled={isLoading}
        />
        <TextField
          fullWidth
          size="small"
          variant="outlined"
          name="ym_oauth_token"
          label="YM OAuth Токен"
          onChange={onChange}
          defaultValue={legalEntity.ym_oauth_token}
          disabled={isLoading}
        />
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        spacing={3}
        padding={3}
        sx={(theme) => ({backgroundColor: theme.palette.grey.A100})}
      >
        <Button
          size="large"
          variant="outlined"
          onClick={onClose}
        >
          Отмена
        </Button>
        <LoadingButton
          size="large"
          variant="contained"
          type="submit"
          loading={isLoading}
          onClick={onSave}
        >
          Сохранять
        </LoadingButton>
      </Stack>
    </>
  )
}

export default UpdateLegalEntity