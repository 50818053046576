import type {PropsWithChildren} from 'react'
import React, {useRef}          from 'react'
import {SnackbarProvider}       from 'notistack'
import ShowNotification         from './ShowNotification'
import {SNACKBAR_DURATION}      from '../../../app/contants/configs'

const NotificationProvider = ({ children }: PropsWithChildren<{}>) => {

  const snackbarRef = useRef(null)

  return (
    <SnackbarProvider
      ref={snackbarRef}
      dense
      maxSnack={5}
      preventDuplicate
      variant="success" // Set default variant
      autoHideDuration={SNACKBAR_DURATION}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      <ShowNotification>
        {children}
      </ShowNotification>
    </SnackbarProvider>
  )
}

export default NotificationProvider