import {Outlet}  from 'react-router-dom'
import Container from '@mui/material/Container'
import {styled}  from '@mui/material/styles'
import AppHeader from './AppHeader'
import AuthGuard from '../../features/auth/guards/AuthGuard'

const RootStyled = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
}))

const ContainerStyled = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4)
}))

const AppLayout = () => {
  return (
    <AuthGuard>
      <RootStyled>
        <AppHeader/>
        <ContainerStyled maxWidth={false}>
          <Outlet/>
        </ContainerStyled>
      </RootStyled>
    </AuthGuard>
  )
}

export default AppLayout