import type {ChangeEvent}                     from 'react'
import Table                                  from '@mui/material/Table'
import TableBody                              from '@mui/material/TableBody'
import TableContainer                         from '@mui/material/TableContainer'
import TableRow                               from '@mui/material/TableRow'
import Paper                                  from '@mui/material/Paper'
import Typography                             from '@mui/material/Typography'
import TableHead                              from '@mui/material/TableHead'
import Stack                                  from '@mui/material/Stack'
import TablePagination                        from '@mui/material/TablePagination'
import useLocalStorage                        from '../../../../app/hooks/useLocalStorage'
import {useGetManufactureOrdersListQuery}     from '../../services/manufacturesApi'
import type {GetManufactureOrdersListRequest} from '../../types'
import ManufactureOrdersTableSkeleton         from './ManufactureOrdersTableSkeleton'
import ManufactureOrdersTableRow              from './ManufactureOrdersTableRow'
import ManufactureOrdersTableNotFound         from './ManufactureOrdersTableNotFound'
import StyledTableCell                        from '../StyledTableCell'

const ManufactureOrdersTable = () => {

  const [params, setParams] = useLocalStorage<GetManufactureOrdersListRequest>(
    'MANUFACTURE_ORDERS_TABLE_PARAMS',
    {
      order: 'desc',
      limit: 5,
      offset: 0
    },
    true
  )

  const onPageChange = (event: unknown, newPage: number) => {
    setParams({ ...params, offset: newPage * params.limit })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setParams({ ...params, offset: 0, limit: Number(event.target.value) })
  }

  const { data, isLoading, isFetching } = useGetManufactureOrdersListQuery(params)

  return (
    <Paper sx={{ width: '100%', mt: 2, mb: 4, p: 2, pb: 0 }}>
      <TableContainer>
        <Table aria-labelledby="product-categories-list-table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Заказ
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Количество
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Статус
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Дата
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="left">
                <Typography color="text.secondary" fontSize={14} noWrap>
                  Комментарий
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading || isFetching ? (
              <ManufactureOrdersTableSkeleton/>
            ) : data && data.count > 0 ? data.items.map((item) => (
              <ManufactureOrdersTableRow key={`order-${item.id}`} order={item}/>
            )) : (
              <ManufactureOrdersTableNotFound/>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" paddingTop={1}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={data ? data.count : 0}
          rowsPerPage={params.limit}
          page={params.offset > 0 ? params.offset / params.limit : 0}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Stack>
    </Paper>
  )
}

export default ManufactureOrdersTable
