import api from '../../../app/store/api'

type Contact = {
  id: string;
  fullName: string;
  email: string;
  phone: string;
}

export type Supplier = {
  id: string
  oldID: number;
  name: string;
  fullName: string;
  legalFormID: number;
  general: string;
  contactFullName: string;
  contacts: Contact[];
  passportDocID: string
}

type SuppliersResponse = {
  suppliers: Supplier[]
}

export const suppliersApi = api.injectEndpoints({
  endpoints: builder => ({
    getSuppliers: builder.query<SuppliersResponse, void>({
      query: () => '/api/wildberries/get_suppliers',
      keepUnusedDataFor: 5,
    })
  })
})

export const { useGetSuppliersQuery } = suppliersApi