import type {ChangeEvent}                 from 'react'
import {useState}                         from 'react'
import Drawer                             from '@mui/material/Drawer'
import AppBar                             from '@mui/material/AppBar'
import Toolbar                            from '@mui/material/Toolbar'
import Typography                         from '@mui/material/Typography'
import Stack                              from '@mui/material/Stack'
import FormControl                        from '@mui/material/FormControl'
import InputLabel                         from '@mui/material/InputLabel'
import Select, {SelectChangeEvent}        from '@mui/material/Select'
import OutlinedInput                      from '@mui/material/OutlinedInput'
import Box                                from '@mui/material/Box'
import Chip                               from '@mui/material/Chip'
import MenuItem                           from '@mui/material/MenuItem'
import Button                             from '@mui/material/Button'
import FormControlLabel                   from '@mui/material/FormControlLabel'
import Checkbox                           from '@mui/material/Checkbox'
import IconFilters                        from '../../../../app/components/icons/IconFilters'
import SelectLegalEntities                from '../../../legalEntities/components/SelectLegalEntities'
import {useGetProductCategoriesListQuery} from '../../../products/services/productsApi'
import type {AbcAnalysisTableFiltersType} from '../../types'

const AbcAnalysisFiltersDrawer = ({
  filters,
  setFilters,
  disabled
}: {
  filters: AbcAnalysisTableFiltersType;
  setFilters: (newFilters: AbcAnalysisTableFiltersType) => void;
  disabled: boolean;
}) => {

  const [open, setOpen] = useState<boolean>(false)

  const onOpen = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const { data: categories } = useGetProductCategoriesListQuery()

  const onChangeLegalEntityId = (event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, legal_entity_id: Number(event.target.value) })
  }

  const onChangeCategories = (event: SelectChangeEvent<number[]>) => {
    const { target: { value } } = event
    if (value.slice(-1)[0] === 0) {
      setFilters({ ...filters, categories: [0] })
    } else {
      setFilters({ ...filters, categories: value as number[] })
    }
  }

  const onChangeArchive = (e: ChangeEvent<HTMLInputElement>) => {
    setFilters({ ...filters, archive: e.target.checked })
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        sx={{ minWidth: 120, height: 40 }}
        onClick={onOpen}
        startIcon={<IconFilters/>}
        disabled={disabled}
      >
        Фильтры
      </Button>
      <Drawer
        anchor="right"
        open={open}
        onClose={onClose}
        PaperProps={{ sx: { width: 400, maxWidth: '100%' } }}
      >
        <AppBar
          position="sticky"
          elevation={0}
          sx={{ py: 1 }}
        >
          <Toolbar>
            <Typography variant="h5">
              Фильтры
            </Typography>
          </Toolbar>
        </AppBar>
        <Stack flexGrow={1} sx={{ px: 3, py: 4 }} spacing={3}>
          <FormControl sx={{ minWidth: 240, width: 1 }} size="small">
            <InputLabel>Категории</InputLabel>
            <Select
              multiple
              size="small"
              value={filters.categories ?? []}
              onChange={onChangeCategories}
              input={<OutlinedInput size="small" label="Категории"/>}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {categories && [...[{
                    id: 0,
                    name: 'Без категории'
                  }], ...categories].filter(({ id }) => selected.includes(id)).map(({ id, name }) => (
                    <Chip key={id} label={name} size="small"/>
                  ))}
                </Box>
              )}
            >
              <MenuItem value={0}>
                Без категории
              </MenuItem>
              {categories && categories.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <SelectLegalEntities
            fullWidth
            size="small"
            defaultValue={filters.legal_entity_id}
            onChange={onChangeLegalEntityId}
          >
            <MenuItem value={0}>
              Все юр. лица
            </MenuItem>
          </SelectLegalEntities>
          <FormControlLabel
            control={<Checkbox checked={filters.archive} onChange={onChangeArchive}/>}
            label={<Typography noWrap>Включая архивные</Typography>}
          />
        </Stack>
      </Drawer>
    </>
  )
}

export default AbcAnalysisFiltersDrawer