import {Outlet} from 'react-router-dom'

const Manufacture = () => {

  return (
    <>
      <Outlet/>
    </>
  )
}

export default Manufacture