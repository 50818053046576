import api from '../../../app/store/api'
import {
  CreateMarketRequest,
  CreateMarketResponse,
  MarketsListResponse,
  UpdateMarketRequest,
  UpdateMarketResponse
}          from '../types'

export const marketsApi = api.injectEndpoints({
  endpoints: builder => ({
    getMarketsList: builder.query<MarketsListResponse, void>({
      query: () => `/management/markets`,
    }),
    createMarket: builder.mutation<CreateMarketResponse, CreateMarketRequest>({
      query: (market) => ({
        url: `/management/markets`,
        method: 'POST',
        body: market
      })
    }),
    updateMarket: builder.mutation<UpdateMarketResponse, UpdateMarketRequest>({
      query: (market) => ({
        url: `/management/markets/${market.id}`,
        method: 'PUT',
        body: market
      })
    }),
  })
})

export const {
  useGetMarketsListQuery,
  useCreateMarketMutation,
  useUpdateMarketMutation
} = marketsApi