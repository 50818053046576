import Box                                from '@mui/material/Box'
import OutlinedInput                      from '@mui/material/OutlinedInput'
import InputLabel                         from '@mui/material/InputLabel'
import MenuItem                           from '@mui/material/MenuItem'
import FormControl                        from '@mui/material/FormControl'
import Select, {SelectChangeEvent}        from '@mui/material/Select'
import Chip                               from '@mui/material/Chip'
import {
  useAppDispatch,
  useAppSelector
}                                         from '../../../../app/store/hooks'
import {useGetProductCategoriesListQuery} from '../../services/productsApi'
import {
  selectCategories,
  selectNoCategory,
  setCategories,
  setNoCategory
}                                         from '../../store/productsSlice'
import {useEffect}                        from 'react'

const ProductCategoryMultipleSelect = () => {

  const { data } = useGetProductCategoriesListQuery()
  const categories = useAppSelector(selectCategories)
  const noCategory = useAppSelector(selectNoCategory)
  const dispatch = useAppDispatch()

  const onChange = (event: SelectChangeEvent<typeof categories>) => {
    const { target: { value } } = event
    if (value.slice(-1)[0] === 0) {
      dispatch(setNoCategory())
    } else {
      dispatch(setCategories(value as number[]))
    }
  }

  useEffect(() => {
    dispatch(setCategories([]))
  }, [])

  return (
    <FormControl sx={{ flexGrow: 1, minWidth: 240, width: 1 }} size="small">
      <InputLabel>Категории</InputLabel>
      <Select
        multiple
        size="small"
        value={categories}
        onChange={onChange}
        input={<OutlinedInput size="small" label="Категории"/>}
        renderValue={(selected) => noCategory ? 'Без категории' : (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {data && data.filter(({ id }) => selected.includes(id)).map(({ id, name }) => (
              <Chip key={id} label={name} size="small"/>
            ))}
          </Box>
        )}
      >
        <MenuItem value={0}>
          Без категории
        </MenuItem>
        {data && data.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ProductCategoryMultipleSelect